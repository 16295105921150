import * as c from 'common/c'

export const centerMap = (center) => {
  return {
    type: c.MAP_CENTER,
    payload: center,
  }
}

export const zoomMap = (zoom) => {
  return {
    type: c.MAP_ZOOM,
    payload: zoom,
  }
}

export const startDrawingBoundary = () => {
  return {
    type: c.DRAW_BOUNDARY,
    payload: true,
  }
}

export const stopDrawingBoundary = () => {
  return {
    type: c.DRAW_BOUNDARY,
    payload: false,
  }
}

export const startDrawingHole = () => {
  return {
    type: c.DRAW_HOLE,
    payload: true,
  }
}

export const stopDrawingHole = () => {
  return {
    type: c.DRAW_HOLE,
    payload: false,
  }
}

export const boundaryChanged = () => {
  return {
    type: c.BOUNDARY_CHANGES_MADE,
    payload: true,
  }
}

export const boundarySaved = () => {
  return {
    type: c.BOUNDARY_CHANGES_MADE,
    payload: false,
  }
}

export const setNewFeature = (feature) => {
  return {
    type: c.SET_NEW_FEATURE,
    payload: feature,
  }
}

export const clearNewFeature = () => {
  return {
    type: c.SET_NEW_FEATURE,
    payload: undefined,
  }
}

export const setSelectedFeatures = (features) => {
  return {
    type: c.SELECTED_FEATURES,
    payload: features,
  }
}

export const clearSelectedFeatures = () => {
  return {
    type: c.SELECTED_FEATURES,
    payload: undefined,
  }
}

export const switchDrawType = (type) => {
  return {
    type: c.SET_DRAW_TYPE,
    payload: type,
  }
}

export const startEditField = () => {
  return {
    type: c.SET_EDIT_FIELD,
    payload: true,
  }
}

export const stopEditField = () => {
  return {
    type: c.SET_EDIT_FIELD,
    payload: false,
  }
}

export const startEditBoundary = () => {
  return {
    type: c.EDIT_BOUNDARY,
    payload: true,
  }
}

export const stopEditBoundary = () => {
  return {
    type: c.EDIT_BOUNDARY,
    payload: false,
  }
}

export const pointInfoUpdate = () => {
  return {
    type: c.POINT_INFO_ENABLED,
    payload: undefined,
  }
}

export const pointInfoReq = (coords) => {
  return {
    type: c.POINT_INFO_REQ,
    payload: coords,
  }
}

export const pointInfoRes = (info) => {
  return {
    type: c.POINT_INFO_RES,
    payload: info,
  }
}

export const pointInfoClr = () => {
  return { type: c.POINT_INFO_CLR }
}

export const areaInfoReq = (coords) => {
  return {
    type: c.AREA_INFO_REQ,
    payload: coords,
  }
}

export const areaInfoRes = (info) => {
  return {
    type: c.AREA_INFO_RES,
    payload: info,
  }
}

export const areaInfoClr = () => {
  return { type: c.AREA_INFO_CLR }
}

export const showLayerLegend = (url) => {
  return {
    type: c.SET_LAYER_LEGEND,
    payload: url,
  }
}

export const showLayerLegendA = (url) => {
  return {
    type: c.SET_LAYER_LEGEND_A,
    payload: url,
  }
}

export const showLayerLegendB = (url) => {
  return {
    type: c.SET_LAYER_LEGEND_B,
    payload: url,
  }
}

export const hideLayerLegend = (layer) => {
  return {
    type: c.REMOVE_LAYER_LEGEND,
    payload: layer,
  }
}

export const hideLayerLegendA = (layer) => {
  return {
    type: c.REMOVE_LAYER_LEGEND_A,
    payload: layer,
  }
}

export const hideLayerLegendB = (layer) => {
  return {
    type: c.REMOVE_LAYER_LEGEND_B,
    payload: layer,
  }
}

export const removeLayerLegends = (layer) => {
  return {
    type: c.REMOVE_LAYER_LEGENDS,
    payload: layer,
  }
}

export const removeLayerLegendsA = (layer) => {
  return {
    type: c.REMOVE_LAYER_LEGENDS_A,
    payload: layer,
  }
}

export const removeLayerLegendsB = (layer) => {
  return {
    type: c.REMOVE_LAYER_LEGENDS_B,
    payload: layer,
  }
}

export const showLayer = (layer) => {
  return {
    type: c.SET_LAYER,
    payload: layer,
  }
}

export const showLayerA = (layer) => {
  return {
    type: c.SET_LAYER_A,
    payload: layer,
  }
}

export const showLayerB = (layer) => {
  return {
    type: c.SET_LAYER_B,
    payload: layer,
  }
}

export const setSwathParameter = (swathParameter) => {
  return {
    type: c.SET_SWATH_PARAMETER,
    payload: swathParameter,
  }
}

export const setSwathParameterA = (swathParameter) => {
  return {
    type: c.SET_SWATH_PARAMETER_A,
    payload: swathParameter,
  }
}

export const setSwathParameterB = (swathParameter) => {
  return {
    type: c.SET_SWATH_PARAMETER_B,
    payload: swathParameter,
  }
}

export const removeLayer = (layer) => {
  return {
    type: c.REMOVE_LAYER,
    payload: layer,
  }
}

export const removeLayerA = (layer) => {
  return {
    type: c.REMOVE_LAYER_A,
    payload: layer,
  }
}

export const removeLayerB = (layer) => {
  return {
    type: c.REMOVE_LAYER_B,
    payload: layer,
  }
}

export const setExtent = (extent) => {
  return {
    type: c.SET_EXTENT,
    payload: extent,
  }
}
