import { styled } from '@mui/system'
import { Button, Typography } from '@mui/material'
//@ts-expect-error
import { closeModal } from 'actions/app'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
//@ts-expect-error
import ModalActions from 'components/modal/modal-actions'
//@ts-expect-error
import ModalHeader from 'components/modal/modal-header'
//@ts-expect-error
import ModalContainer from 'components/modal/modal-container'

export default function RequestReceived() {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const closeDialog = useCallback(() => {
    dispatch(closeModal())
  }, [])

  return (
    <StyledContainer>
      <ModalHeader>{t('bulk_export')}</ModalHeader>
      <StyledContent>
        <Typography>{t('bulk_export_message')}</Typography>
      </StyledContent>
      <ModalActions>
        <Button
          variant="contained"
          onClick={closeDialog}
          sx={{ width: '160px' }}
        >
          {t('ok')}
        </Button>
      </ModalActions>
    </StyledContainer>
  )
}

const StyledContainer = styled(ModalContainer)({
  display: 'flex',
  flexDirection: 'column',
})

const StyledContent = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  '& > *:not(:last-child)': {
    marginBottom: '1em',
  },
})
