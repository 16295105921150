import { styled } from '@mui/system'
import { H } from 'components/layout'
import ContextMenu from 'components/context-menu'
import { colors, defaultShadow } from 'components/foundation'
import { isEmpty, isUndefined } from 'lodash-es'

const Panel = (props) => {
  const {
    title = undefined,
    shadow = true,
    align = 'left',
    actions,
    children,
  } = props

  let headerEl
  if (!isUndefined(title) && !isUndefined(actions) && !isEmpty(actions)) {
    headerEl = (
      <StyledHeader key="header">
        <H>
          <H.Item>{title}</H.Item>
          <H.Item>
            <ContextMenu entries={actions} pos="bl" color={colors.brand} />
          </H.Item>
        </H>
      </StyledHeader>
    )
  } else if (title) {
    headerEl = <StyledHeader key="header">{title}</StyledHeader>
  }

  return (
    <StyledPanel shadow={shadow} align={align}>
      {headerEl}
      {children}
    </StyledPanel>
  )
}

Panel.propTypes = {
  title: PropTypes.string,
  shadow: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      action: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
    }),
  ),
  children: PropTypes.node.isRequired,
}

const StyledHeader = styled('div')({
  margin: '0 4px 8px 0',
  paddingBottom: '4px',
  borderBottom: '1px solid #ddd',
})

const StyledPanel = styled('div')((props) => ({
  borderRadius: '4px',
  padding: '8px',
  background: 'white',
  textAlign: props.align,
  ...(props.shadow ? defaultShadow : { boxShadow: 'none' }),
}))

export default Panel
