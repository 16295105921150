import { styled } from '@mui/system'
import { DefaultShadow } from 'components/container'

export const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  height: '93vh',
  bottom: 0,
  left: 0,
  right: 0,
})

export const GridWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '250px 1fr',
  gridTemplateRows: '1fr',
  height: '100%',
  margin: 0,
})

export const SplitGridWrapper = styled(GridWrapper)({
  gridTemplateColumns: '1fr 1fr',
})

const StyledSidePanel = styled(DefaultShadow)({
  position: 'absolute',
  width: '250px',
  height: '93vh',
  margin: 0,
  left: '0px',
  right: '250px',
})

const StyledContent = styled('div')({
  flexGrow: 1,
  margin: 0,
  left: '250px',
  position: 'absolute',
  width: 'calc(100% - 250px)',
  height: '93vh',
})

export const SidePanel = ({ children }) => {
  return <StyledSidePanel id="sidePanel">{children}</StyledSidePanel>
}

export const PageContent = ({ children }) => {
  return <StyledContent id="pageContent">{children}</StyledContent>
}
