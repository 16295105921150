import LayerGroup from './layer-group.tsx'
import ItemSwitch from './item-switch.tsx'
import { vectorLayers, rasterLayers } from 'common/layer-data'
import { cloneDeep } from 'lodash-es'
import { Box, Stack, TextField } from '@mui/material'
import {
  SubscriptionsOutlined as SubscriptionsIcon,
  RadioButtonChecked,
  RadioButtonUnchecked,
  CheckBoxOutlineBlank,
  CheckBoxOutlined,
} from '@mui/icons-material'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { isSubscriptionValid } from 'common/misc'
import { styled } from '@mui/system'
import { useSelector } from 'react-redux'
import { harvestFetched } from 'actions/harvest'
import { pointInfoUpdate } from 'actions/map.js'

const swathParameters = ['yield', 'headerheight', 'moisture', 'speed']
const defaultSwathParameterStatus = [false, false, false, false]

export default function LayersGroup(props) {
  const {
    layerInfoMap,
    harvestLayers,
    hideLayerLegend,
    removeLayer,
    showLayer,
    harvest,
    setLayers,
    showVectorLayers = true,
    showPremiumMsg,
    setSwathParameter,
  } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const selectedHarvest = useSelector((state) => state.harvest.current)
  const wmsLayers = useSelector((state) => state.map.wmsLayers)
  const currentView = useSelector((state) => state.app.view)
  const subscriptions = useSelector((state) => state.account.subscriptions)
  const stateSwathParameters = useSelector((state) => state.map.swathParameters)

  // If Harvest is not selected then set the first array from the harvests
  // state object as the default selection in the split screen window
  const harvests = useSelector((state) => state.harvest.collection)

  const [activeStatus, setActiveStatus] = useState(false)
  const [swathParameter2, setSwathParameter2] = useState(swathParameters[0])
  const [swathParameterStatus, setSwathParameterStatus] = useState(
    defaultSwathParameterStatus,
  )

  const handleSwathParameterChange = (event) => {
    const childIndex = event.target.options.selectedIndex

    const swathParameterUpdate = {
      swathParameter: event.target.value,
      swathParameterSldExt: `_${swathParameters[childIndex]}.sld`,
      harvest: selectedHarvest.id,
    }

    dispatch(setSwathParameter(swathParameterUpdate))

    let o = {}
    if (harvestLayers['swath'] != null) {
      o['swath'] = cloneDeep(harvestLayers['swath'])
      if (harvestLayers['swath'].selected) {
        dispatch(hideLayerLegend({ name: 'swath', harvest: harvest?.id }))
        dispatch(removeLayer({ name: 'swath', harvest: harvest?.id }))
      }
    } else if (harvestLayers == null) {
      o = Object.assign({}, baseRasterLayers, baseVectorLayers)
      o['swath'].selected = !o['swath'].selected
    }
    const layer = layerInfoMap['swath']
    let legendUrl = layer.legendUrl
    if (o['swath'].selected && layer) {
      legendUrl = layer.legendUrl.replace(
        /\.sld$/,
        swathParameterUpdate.swathParameterSldExt,
      )

      dispatch(
        showLayer({
          name: 'swath',
          legendUrl: legendUrl,
          harvest: layer.harvestId,
          queryable: layer.queryable,
          bbox: [
            layer.bboxMiny,
            layer.bboxMinx,
            layer.bboxMaxy,
            layer.bboxMaxx,
          ].join(','),
          zindex: harvestLayers['swath'].zindex,
        }),
      )

      o = Object.assign({}, harvestLayers, o)
      const newLayers = {}
      newLayers[harvest?.id] = o
      dispatch(setLayers(newLayers))
      dispatch(harvestFetched(cloneDeep(harvest)))
    }
  }

  const getDefaultSwathParameterStatus = (metadata) => {
    if (!metadata?.swath_columns || metadata?.swath_columns.length == 0)
      return [false, false, false, false]

    // Map swathParameters to boolean based on availability
    return swathParameters.map((param) => {
      switch (param) {
        case 'headerheight':
          return metadata?.swath_columns.includes('header_height')
        case 'yield':
          return metadata?.swath_columns.includes('yield')
        case 'moisture':
          return metadata?.swath_columns.includes('moisture')
        case 'speed':
          return metadata?.swath_columns.includes('speed')
        default:
          return false
      }
    })
  }

  useEffect(() => {
    setSwathParameter2(
      stateSwathParameters.find((s) => s.harvestId === harvest.id)
        ?.swathParameter ?? swathParameters[0],
    )

    if (layerInfoMap['swath']?.metadata !== undefined) {
      setSwathParameterStatus(
        getDefaultSwathParameterStatus(layerInfoMap['swath'].metadata),
      )
    }
  }, [wmsLayers, layerInfoMap])

  // Check if the user has active subscription
  useEffect(() => {
    // Fix the Renew FarmTRX Premium message
    let defaultHarvest = {}
    if (Object.keys(selectedHarvest).length) {
      defaultHarvest = Object.assign(defaultHarvest, selectedHarvest)
    } else {
      defaultHarvest = Object.assign(defaultHarvest, harvests[0])
    }

    setActiveStatus(
      isSubscriptionValid(
        subscriptions,
        defaultHarvest?.startDate,
        defaultHarvest?.endDate,
      ),
    )
  }, [])

  useEffect(() => {
    if (currentView === 'split') {
      if (showPremiumMsg) {
        setActiveStatus(false)
      } else {
        setActiveStatus(true)
      }
    }
  }, [showPremiumMsg])

  const baseVectorLayers = useMemo(() => {
    const _baseVectorLayers = {}
    for (let i = 0; i < vectorLayers.length; i++) {
      _baseVectorLayers[vectorLayers[i].name] = vectorLayers[i]
    }
    return _baseVectorLayers
  }, [vectorLayers])
  const baseRasterLayers = useMemo(() => {
    const _baseRasterLayers = {}
    for (let i = 0; i < rasterLayers.length; i++) {
      _baseRasterLayers[rasterLayers[i].name] = rasterLayers[i]
    }
    return _baseRasterLayers
  }, [rasterLayers])

  const toggle = useCallback(
    (v) => {
      if (harvest?.id) {
        let o = {}
        if (harvestLayers[v] != null) {
          o[v] = cloneDeep(harvestLayers[v])
          o[v].selected = !o[v].selected
          if (harvestLayers[v].selected && v !== 'boundary') {
            dispatch(hideLayerLegend({ name: v, harvest: harvest?.id }))
            dispatch(removeLayer({ name: v, harvest: harvest?.id }))
            dispatch(pointInfoUpdate())
          }
        } else if (harvestLayers == null) {
          o = Object.assign({}, baseRasterLayers, baseVectorLayers)
          o[v].selected = !o[v].selected
        }
        const layer = layerInfoMap[v]
        if (o[v].selected && layer) {
          let legendUrl = layer.legendUrl
          if (
            v === 'swath' &&
            swathParameter2 &&
            swathParameterStatus.some((s) => s === true)
          ) {
            legendUrl = layer.legendUrl.replace(
              /\.sld$/,
              `_${swathParameter2}.sld`,
            )
          }

          dispatch(
            showLayer({
              name: v,
              legendUrl: legendUrl,
              harvest: layer.harvestId,
              queryable: layer.queryable,
              bbox: [
                layer.bboxMiny,
                layer.bboxMinx,
                layer.bboxMaxy,
                layer.bboxMaxx,
              ].join(','),
              zindex: harvestLayers[v].zindex,
            }),
          )
        }

        o = Object.assign({}, harvestLayers, o)
        if (baseRasterLayers[v] != null) {
          for (let i in rasterLayers) {
            let name = rasterLayers[i].name
            if (name !== v && harvestLayers[name].selected) {
              o[name] = harvestLayers[name]
              if (harvestLayers[name].selected) {
                o[name].selected = !harvestLayers[name].selected
                dispatch(hideLayerLegend({ name: name, harvest: harvest?.id }))
                dispatch(removeLayer({ name: name, harvest: harvest?.id }))
                dispatch(pointInfoUpdate())
              }
            }
          }
        }
        const newLayers = {}
        newLayers[harvest?.id] = o
        dispatch(setLayers(newLayers))
      }
    },
    [
      harvestLayers,
      harvest,
      baseVectorLayers,
      rasterLayers,
      baseRasterLayers,
      layerInfoMap,
      swathParameter2,
      swathParameterStatus,
    ],
  )

  return harvest?.id != null ? (
    <StyledRoot>
      {showVectorLayers ? (
        <LayerGroup>
          <LayerGroup.Header>{t('boundary_and_points')}</LayerGroup.Header>
          <LayerGroup.Items>
            <LayerGroup.Item onClick={() => toggle('boundary')}>
              {harvestLayers?.boundary?.selected ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <ItemSwitch
                on={harvestLayers?.boundary?.selected ? t('boundary') : ''}
              >
                {t('boundary')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['raw_points'] !== undefined}
              onClick={() => toggle('raw_points')}
            >
              {harvestLayers?.raw_points?.selected ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <ItemSwitch
                on={
                  harvestLayers?.raw_points?.selected
                    ? t('raw_yield_points')
                    : ''
                }
              >
                {t('raw_yield_points')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['corrected_points'] !== undefined}
              onClick={() => toggle('corrected_points')}
            >
              {harvestLayers?.corrected_points?.selected ? (
                <CheckBoxOutlined />
              ) : (
                <CheckBoxOutlineBlank />
              )}
              <ItemSwitch
                on={
                  harvestLayers?.corrected_points?.selected
                    ? t('corrected_yield_points')
                    : ''
                }
              >
                {t('corrected_yield_points')}
              </ItemSwitch>
            </LayerGroup.Item>
          </LayerGroup.Items>
        </LayerGroup>
      ) : null}
      <LayerGroup>
        <LayerGroup.Header>{t('maps')}</LayerGroup.Header>
        {!activeStatus ? (
          <LayerGroup.Items>
            <LayerGroup.Item>
              <Box
                sx={{
                  borderLeft: '8px solid #ff00006b',
                  background: '#ff00001a',
                  color: 'red',
                  fontWeight: 'bold',
                  paddingLeft: '4px',
                }}
                title={t('no_subscriptions_raw_points')}
                onClick={() => navigate('/admin/subscription')}
              >
                <span> {t('renew_subscription')}</span>
                <SubscriptionsIcon
                  size="small"
                  style={{
                    color: '#B2023B',
                    height: '0.7em',
                    verticalAlign: 'sub',
                  }}
                  titleAccess={t('no_subscriptions_raw_points')}
                />
              </Box>
            </LayerGroup.Item>
          </LayerGroup.Items>
        ) : (
          <LayerGroup.Items>
            <LayerGroup.Item
              show={layerInfoMap['corrected_yield'] !== undefined}
              onClick={() => toggle('corrected_yield')}
            >
              {harvestLayers?.corrected_yield?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.corrected_yield.selected}>
                {t('corrected_yield_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['zones_yield'] !== undefined}
              onClick={() => toggle('zones_yield')}
            >
              {harvestLayers?.zones_yield?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.zones_yield.selected}>
                {t('classified_yield_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['trend_yield'] !== undefined}
              onClick={() => toggle('trend_yield')}
            >
              {harvestLayers?.trend_yield?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.trend_yield.selected}>
                {t('yield_map_trend')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['raw_raster'] !== undefined}
              onClick={() => toggle('raw_raster')}
            >
              {harvestLayers?.raw_raster?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.raw_raster.selected}>
                {t('raw_yield_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['raw_moisture'] !== undefined}
              onClick={() => toggle('raw_moisture')}
            >
              {harvestLayers?.raw_moisture?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.raw_moisture.selected}>
                {t('raw_moisture_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['trend_moisture'] !== undefined}
              onClick={() => toggle('trend_moisture')}
            >
              {harvestLayers?.trend_moisture?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.trend_moisture.selected}>
                {t('trend_moisture_map')}
              </ItemSwitch>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['swath'] !== undefined}
              onClick={() => ''}
            >
              <Stack direction="column" spacing={1}>
                <Box onClick={() => toggle('swath')}>
                  <Stack direction="row">
                    {harvestLayers?.swath?.selected ? (
                      <RadioButtonChecked />
                    ) : (
                      <RadioButtonUnchecked />
                    )}
                    <ItemSwitch on={harvestLayers.swath.selected}>
                      {t('swath_map')}
                    </ItemSwitch>
                  </Stack>
                </Box>
                {harvestLayers?.swath?.selected &&
                swathParameterStatus.some((s) => s === true) ? (
                  <Stack direction="row">
                    <TextField
                      select
                      label={t('swath_parameter')}
                      defaultValue={swathParameter2}
                      size="small"
                      margin="dense"
                      fullWidth
                      slotProps={{
                        select: {
                          native: true,
                        },
                      }}
                      sx={{
                        margin: '7px',
                        fontSize: '0.75em',
                        height: '28px',
                      }}
                      onChange={handleSwathParameterChange}
                    >
                      <option
                        key={swathParameters[0]}
                        value={swathParameters[0]}
                        disabled={!swathParameterStatus[0]}
                      >
                        {t('yield')}
                      </option>
                      <option
                        key={swathParameters[1]}
                        value={swathParameters[1]}
                        disabled={!swathParameterStatus[1]}
                      >
                        {t('header_height')}
                      </option>
                      <option
                        key={swathParameters[2]}
                        value={swathParameters[2]}
                        disabled={!swathParameterStatus[2]}
                      >
                        {t('moisture')}
                      </option>
                      <option
                        key={swathParameters[3]}
                        value={swathParameters[3]}
                        disabled={!swathParameterStatus[3]}
                      >
                        {t('speed')}
                      </option>
                    </TextField>
                  </Stack>
                ) : (
                  ''
                )}
              </Stack>
            </LayerGroup.Item>
            <LayerGroup.Item
              show={layerInfoMap['dem'] !== undefined}
              onClick={() => toggle('dem')}
            >
              {harvestLayers?.dem?.selected ? (
                <RadioButtonChecked />
              ) : (
                <RadioButtonUnchecked />
              )}
              <ItemSwitch on={harvestLayers.dem.selected}>
                {t('dem')}
              </ItemSwitch>
            </LayerGroup.Item>
          </LayerGroup.Items>
        )}
      </LayerGroup>
    </StyledRoot>
  ) : null
}

const StyledRoot = styled('div')({
  margin: '0 0 0 12px',
})
