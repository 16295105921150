import { styled } from '@mui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'

export default function Busy() {
  const app = useSelector((state) => state.app)

  return (
    <StyledSwitchDisplay $hide={!app.busy}>
      <FontAwesomeIcon icon="spinner" spin={true} color="black" />
    </StyledSwitchDisplay>
  )
}

const StyledSwitchDisplay = styled('div')((props) => ({
  display: props.$hide ? 'none' : 'block',
}))
