import { styled } from '@mui/system'

export default function ModalHeader(props) {
  return <StyledHeader>{props.children}</StyledHeader>
}

const StyledHeader = styled('h1')({
  color: '#212529',
  fontWeight: 700,
  fontSize: '24px',
  margin: '0 0 1em 0',
  textAlign: 'center',
})
