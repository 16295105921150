import { styled } from '@mui/system'

export default function ModalContainer(props) {
  return <StyledContainer>{props.children}</StyledContainer>
}

const StyledContainer = styled('div')({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '40em',
})
