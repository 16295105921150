import { styled } from '@mui/system'
import {
  Box,
  Grid2,
  Stack,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isNil, get } from 'lodash-es'
import React, {
  useEffect,
  useState,
  ReactElement,
  useCallback,
  useMemo,
} from 'react'

//@ts-expect-error
import type { AppState } from 'state/index'
//@ts-expect-error
import { fetchFilteredFields } from 'api/field'
import { Typography } from '@mui/material'
/*import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material'*/
import {
  defaultFormatOptions,
  vectorLayers,
  rasterLayers,
  freeLayers,
  DefaultLayerFormats,
  LayerType,
  //@ts-expect-error
} from 'common/layer-data'

//@ts-expect-error
import ClientSelector from 'components/client-selector/index'
//@ts-expect-error
import FarmSelector from 'components/farm-selector/index'

//@ts-expect-error
import { isSubscriptionValid } from 'common/misc'

//types
//@ts-expect-error
import { HarvestYearModel } from 'state/harvest'
//@ts-expect-error
import { FarmOptionModel } from 'state/farm'
//@ts-expect-error
import { ClientModel } from 'state/client'
//@ts-expect-error
import { CropConstant } from 'state/crop'
//@ts-expect-error
import { SubscriptionPeriod } from 'state/account'
//@ts-expect-error
import { requestBulkExport } from 'api/harvest'
//@ts-expect-error
import { FieldModel } from 'state/field'

declare module 'components/bulk-export/index.js' {}

export default function BulkExport(): ReactElement<any, any> {
  const filter = createFilterOptions({
    stringify: (option: FarmOptionModel) => option.name,
  })
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const harvestYears: HarvestYearModel[] = useSelector((state: AppState) =>
    get(state, 'harvest.years', []),
  )

  const crops: CropConstant[] = useSelector((state: AppState) =>
    get(state, 'crop.cropsWithCropVarieties', []),
  )
  const [selectedCrop, setSelectedCrop] = useState<CropConstant>({
    buPerTonne: 0,
    createdAt: null,
    cropClassification: 'CEREAL',
    defaultTestWeightKgHl: 0,
    defaultYmCalibration: null,
    dielectricClassId: 0,
    id: 0,
    minMoisture: 0,
    marketMoisture: 0,
    maxMoisture: 0,
    name: 'all',
    updatedAt: null,
    color: '',
  })

  const allCropList: CropConstant[] = useMemo(() => {
    return [
      {
        buPerTonne: 0,
        createdAt: null,
        cropClassification: 'CEREAL',
        defaultTestWeightKgHl: 0,
        defaultYmCalibration: null,
        dielectricClassId: 0,
        id: 0,
        minMoisture: 0,
        marketMoisture: 0,
        maxMoisture: 0,
        name: 'all',
        updatedAt: null,
        color: '',
      },
      ...crops,
    ]
  }, [crops])

  const clients: ClientModel[] = useSelector((state) =>
    get(state, 'client.collection', []),
  )
  const harvestedFields: FieldModel[] = useSelector((state) =>
    get(state, 'field.fieldsHarvested', []),
  )
  const defaultClient: ClientModel | undefined = useSelector(
    (state: AppState) => get(state, 'client.singleton'),
  )
  const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(
    defaultClient,
  )
  const defaultFarms: FarmOptionModel[] = useSelector((state: AppState) =>
    get(state, 'farm.selectedFarms', []),
  )

  const [selectedFarms, setSelectedFarms] =
    useState<FarmOptionModel[]>(defaultFarms)

  const [filteredFarms, setFilteredFarms] = useState<FarmOptionModel[]>(
    selectedClient?.farms ?? [],
  )
  const initialYear: number | undefined = useSelector((state: AppState) =>
    get(state, 'field.year'),
  )
  const [canExport, setCanExport] = useState(false)

  const [selectedYear, setSelectedYear] = useState<HarvestYearModel>({
    harvestYr: new Date().getFullYear(),
    hasHarvest: false,
  })
  const [availableLayers, setAvailableLayers] = useState<LayerType[]>([])
  const [activeStatus, setActiveStatus] = useState(false)
  const subscriptions = useSelector(
    (state: AppState) => state.account.subscriptions,
  )

  const harvestLayers: LayerType[] = useMemo(() => {
    return rasterLayers.concat(vectorLayers).sort((a, b) => {
      if (a.translation > b.translation) return 1
      else if (a.translation < b.translation) return -1
      return 0
    })
  }, [])
  //set currentLayer to boundary
  const boundary: LayerType = vectorLayers[0] as LayerType
  const [selectedLayer, setSelectedLayer] = useState<LayerType>(boundary)

  const [selectedFormat, setSelectedFormat] = useState<DefaultLayerFormats>(
    defaultFormatOptions[0],
  )

  const [currentFormats, setCurrentFormats] = useState<DefaultLayerFormats[]>([
    defaultFormatOptions[0],
    defaultFormatOptions[1],
  ])

  //Check if the user has active subscription
  useEffect(() => {
    //Check to see if the selected year (using start of and end of year) falls in a valid subscription
    //Just using year is treated as January 1st of the year so a subscription that is valid for example
    //February 1, 2024- February 1, 2025 will show as not having a valid subscription for 2024 if the year
    //is all that is used.
    const endDate = `${selectedYear?.harvestYr}-12-31`
    //see if we have a valid subscription at the start of year or now
    const startDate = `${selectedYear?.harvestYr}-01-01`
    let _validSubscription = isSubscriptionValid(
      subscriptions,
      startDate,
      startDate,
    )
    _validSubscription =
      _validSubscription || isSubscriptionValid(subscriptions, endDate, endDate)
    setActiveStatus(_validSubscription)
  }, [selectedYear, subscriptions])
  const allSelected = filteredFarms.length === selectedFarms.length

  useEffect(() => {
    for (let i = 0; i < harvestYears.length; i++) {
      if (
        harvestYears[i].harvestYr === initialYear ||
        (initialYear === undefined && harvestYears[i].hasHarvest)
      ) {
        setCanExport(harvestYears[i].hasHarvest)
        setSelectedYear(harvestYears[i])
        break
      }
    }
  }, [harvestYears, initialYear])

  const onYearChange = useCallback((e, newValue, reason) => {
    setSelectedYear(newValue)
  }, [])

  useEffect(() => {
    const crop = selectedCrop?.id === 0 ? 'all' : selectedCrop?.id
    const _year = selectedYear?.harvestYr
    if (_year && crop) {
      if (selectedFarms?.length) {
        let farmIds = selectedFarms?.reduce<string[]>((result, a) => {
          if (!isNil(a?.id)) {
            result.push(a.id.toString())
          }
          return result
        }, new Array())
        dispatch(fetchFilteredFields(_year, crop, selectedClient?.id, farmIds))
      } else if (selectedClient) {
        dispatch(fetchFilteredFields(_year, crop, selectedClient?.id))
      } else {
        dispatch(fetchFilteredFields(_year, crop))
      }
    }
  }, [selectedClient, selectedFarms, selectedCrop, selectedYear])

  //Select All
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedFarms(filteredFarms)
    } else {
      handleClearOptions()
    }
  }

  const handleClearOptions = () => {
    setSelectedFarms([])
  }

  const handleToggleSelectAll = () => {
    handleSelectAll && handleSelectAll(!allSelected)
  }
  //End
  const onClientChange = useCallback((e, newValue, reason) => {
    let client, farms
    if (reason === 'clear') {
      client = null
      farms = []
    } else {
      client = newValue
      farms = newValue?.['farms'] || []
    }
    setSelectedClient(client)
    setSelectedFarms(farms)
    setFilteredFarms(farms)
  }, [])

  const onFarmChange = useCallback(
    (e, newValue, reason) => {
      let _farms: FarmOptionModel[] = []
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (newValue.find((option) => option.id === 0)) {
          handleToggleSelectAll()
          _farms = !allSelected ? filteredFarms : []
        } else {
          _farms = newValue?.length ? newValue : []
          setSelectedFarms(newValue)
        }
      } else if (reason === 'clear') {
        handleClearOptions()
        _farms = []
      }
      setSelectedFarms(_farms)
    },
    [selectedFarms],
  )

  const onCropChange = useCallback((e, newValue, reason) => {
    setSelectedCrop(newValue)
  }, [])

  const onLayerChange = useCallback(
    (e, newValue, reason) => {
      setSelectedLayer(newValue)

      const updatedFormats = defaultFormatOptions.filter(
        (option) =>
          newValue?.formats?.includes(option.name) &&
          (!option.isPremium || (option.isPremium && activeStatus)),
      )
      setCurrentFormats(updatedFormats)
      const newSelectedFormat = updatedFormats.find(
        (element) => element.name === newValue.formats[0],
      )
      if (newSelectedFormat) {
        setSelectedFormat(newSelectedFormat)
      }
    },
    [activeStatus],
  )

  useEffect(() => {
    if (activeStatus) {
      setAvailableLayers(harvestLayers)
    } else {
      setAvailableLayers(freeLayers)
    }
  }, [activeStatus])

  const onFormatChange = useCallback((e, newValue, reason) => {
    setSelectedFormat(newValue)
  }, [])

  const onRequestExport = useCallback(() => {
    const data = {
      export_type: selectedLayer.exportName[selectedFormat.value],
      year: selectedYear.harvestYr,
    }
    if (selectedCrop.id > 0) {
      data['crop_id'] = selectedCrop.id
    }
    if (selectedClient?.id) {
      data['client_id'] = selectedClient.id
    }
    if (selectedFarms?.length > 0) {
      const farmIds: number[] = []
      for (let i = 0; i < selectedFarms.length; i++) {
        farmIds.push(selectedFarms[i].id)
      }
      data['farm_ids'] = farmIds
    }
    dispatch(requestBulkExport(data))
  }, [
    selectedClient,
    selectedCrop,
    selectedFarms,
    selectedLayer,
    selectedFormat,
    selectedYear,
  ])

  return (
    <>
      <Box
        px={3}
        py={4}
        width={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1rem',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h2>{t('bulk_export')} </h2>
        </Box>
        <Grid2 container spacing={2} my={1}>
          <Grid2 size={{ xs: 3 }}>
            <Stack spacing={4}>
              <Stack spacing={1}>
                <Typography>{t('year') + ':'}</Typography>
                <Autocomplete
                  fullWidth
                  style={{ maxHeight: '40px' }}
                  sx={{
                    '& input': {
                      height: '1em',
                    },
                    '& label': {
                      lineHeight: '1.5em',
                      fontSize: '1em',
                    },
                  }}
                  disableClearable
                  id="year"
                  autoHighlight
                  isOptionEqualToValue={(option, value) =>
                    option.harvestYr === value.harvestYr
                  }
                  size="small"
                  options={harvestYears}
                  selectOnFocus
                  getOptionLabel={(option) =>
                    option?.harvestYr ? JSON.stringify(option.harvestYr) : ''
                  }
                  value={selectedYear}
                  onChange={(event, newValue, reason) => {
                    onYearChange(event, newValue, reason)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('year')}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Stack>
              <ClientSelector
                selectedClient={selectedClient}
                onChange={onClientChange}
              />
              <FarmSelector
                selectedFarms={selectedFarms}
                client={selectedClient}
                onChange={onFarmChange}
              />
              <Stack spacing={1}>
                <Typography>{t('crop') + ':'}</Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  style={{ maxHeight: '40px' }}
                  sx={{
                    '& input': {
                      height: '1em',
                    },
                    '& label': {
                      lineHeight: '1.5em',
                      fontSize: '1em',
                    },
                  }}
                  id="crop"
                  autoHighlight
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  size="small"
                  options={allCropList}
                  selectOnFocus
                  getOptionLabel={(option) => option?.name || ''}
                  value={selectedCrop ?? null}
                  onChange={(event, newValue, reason) => {
                    onCropChange(event, newValue, reason)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('crop')}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography>{t('layer_type') + ':'}</Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  style={{ maxHeight: '40px' }}
                  sx={{
                    '& input': {
                      height: '1em',
                    },
                    '& label': {
                      lineHeight: '1.5em',
                      fontSize: '1em',
                    },
                  }}
                  id="layer-type"
                  autoHighlight
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  size="small"
                  options={availableLayers}
                  selectOnFocus
                  getOptionLabel={(option) => t(option.translation) || ''}
                  value={selectedLayer}
                  onChange={(event, newValue, reason) => {
                    onLayerChange(event, newValue, reason)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('layer')}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography>{t('export_format') + ':'}</Typography>
                <Autocomplete
                  fullWidth
                  disableClearable
                  style={{ maxHeight: '40px' }}
                  sx={{
                    '& input': {
                      height: '1em',
                    },
                    '& label': {
                      lineHeight: '1.5em',
                      fontSize: '1em',
                    },
                  }}
                  id="export-format"
                  autoHighlight
                  isOptionEqualToValue={(option, value) =>
                    option.name === value.name
                  }
                  size="small"
                  options={currentFormats}
                  selectOnFocus
                  getOptionLabel={(option) => t(option.label) || ''}
                  value={selectedFormat}
                  onChange={(event, newValue, reason) => {
                    onFormatChange(event, newValue, reason)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('export_format')}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Stack>
              <Button
                variant="contained"
                onClick={onRequestExport}
                sx={{ width: '160px' }}
                disabled={!canExport || harvestedFields.length === 0}
              >
                {t('generate')}
              </Button>
            </Stack>
          </Grid2>
        </Grid2>
      </Box>
    </>
  )
}
