import { styled } from '@mui/system'
import { V, H, VItem } from 'components/layout'
import { toDateString } from 'common/misc'
import utils from '@farmtrx/utils'
import { isNil } from 'lodash-es'
import { t } from 'i18next'

const HECTARES_TO_ACRES = 2.471054
const TONNES_TO_POUNDS = 2204.622622

function getYieldToPref(data, prefsUnit) {
  const yieldKeys = {
    tonnes_ha: ['yldt_ha', 'YLDt_ha'],
    bu_ac: ['yldbu_ac', 'YLDbu_ac'],
    kg_ha: ['yldkg_ha', 'YLDkg_ha'],
  }
  let keys = yieldKeys[prefsUnit]
  let value
  if (isNil(keys)) {
    keys = yieldKeys['tonnes_ha']
    for (let i = 0; i < keys.length; i++) {
      if (!isNil(data[keys[i]])) {
        value = data[keys[i]]
      }
    }

    switch (prefsUnit) {
      case 'tonnes_ac':
        value = value * HECTARES_TO_ACRES
        break
      case 'lbs_acre':
        value = value * HECTARES_TO_ACRES * TONNES_TO_POUNDS
        break
    }
  } else {
    for (let i = 0; i < keys.length; i++) {
      if (!isNil(data[keys[i]])) {
        value = data[keys[i]]
      }
    }
  }
  return `${value} ${t(prefsUnit)}`
}

export default function InfoList({ data }) {
  const harvestTimestamp = useMemo(
    () => new Date(data.timestamp * 1000),
    [data],
  )
  const prefs = useSelector((state) => state.account.singleton.prefs)
  const { units } = prefs

  const { t } = useTranslation()

  const yieldValue = getYieldToPref(data, units.yieldunit_in)

  // Keys with capital letters are legacy data which may or may not exist
  const possibleEntries = [
    {
      label: t('crop'),
      keys: ['crop', 'Crop'],
      convert: (crop) => {
        return t(crop.toLowerCase())
      },
    },
    {
      label: t('avg_yield'),
      value: yieldValue,
    },
    {
      label: t('moisture'),
      keys: ['moisture', 'Moisture'],
      convert: (moisture) => {
        return `${moisture.toFixed(1)} %`
      },
    },
    {
      label: t('speed'),
      keys: ['speedkmh', 'SpeedKmh'],
      convert: (speed) => {
        let value = utils.unitConvert
          .convertSpeed(speed, units.speed_in)
          .toFixed(2)
        return `${value} ${t(units.speed_in)} `
      },
    },
    {
      label: t('harvest_date'),
      value: toDateString(harvestTimestamp),
    },
    {
      label: t('harvest_time'),
      value: `${harvestTimestamp.toLocaleTimeString([], { hour12: false })} `,
    },
    {
      label: t('Latitude'),
      keys: ['latitude', 'Latitude'],
    },
    {
      label: t('longitude'),
      keys: ['longitude', 'Longitude'],
    },
    {
      label: t('combine'),
      keys: ['combine', 'Combine'],
    },
    {
      label: t('header_width'),
      keys: ['headerw_m', 'HeaderW_M'],
      convert: (headerWidthMeters) => {
        if (units.length_in == 'feet') {
          const { feet, inches } =
            utils.unitConvert.convertToFeetInches(headerWidthMeters)
          return `${feet}' ${inches}"`
        } else if (units.length_in == 'inches') {
          return `${Math.round(headerWidthMeters * utils.unitConvert.METRES_TO_INCHES)} ${t(
            units.length_in,
          )}`
        }
        return `${headerWidthMeters} ${t(units.length_in)}`
      },
    },
    {
      label: t('header_height'),
      keys: ['header_hi'],
      convert: (headerHeightmm) => {
        const headerHeightm = headerHeightmm / utils.unitConvert.METERS_TO_MM
        if (units.length_in == 'feet') {
          const { feet, inches } =
            utils.unitConvert.convertToFeetInches(headerHeightm)
          return `${feet}' ${inches}"`
        } else if (units.length_in == 'inches') {
          return `${Math.round(headerHeightm * utils.unitConvert.METRES_TO_INCHES)} ${t(
            units.length_in,
          )}`
        }
        return `${headerHeightm} ${t(units.length_in)}`
      },
    },
    {
      label: t('temperature'),
      keys: ['temperatur', 'Temperatur'],
      convert: (temp) => {
        if (units.temperature_in === 'fahrenheit') {
          temp = (temp * 9) / 5 + 32
        }
        return `${temp} ${t(units.temperature_in)}`
      },
    },
    {
      label: t('time_delay'),
      keys: ['timedel', 'TimeDel'],
    },
    {
      label: t('Calibration'),
      keys: ['calibr', 'Calibr'],
    },
    {
      label: t('ym_id'),
      keys: ['ym_id', 'YM_id'],
    },
  ]

  const labelsValues = useMemo(() => {
    let entries = []
    for (let i = 0; i < possibleEntries.length; i++) {
      const keys = possibleEntries[i].keys
      if (!isNil(possibleEntries[i].value)) {
        let entry = {
          label: possibleEntries[i].label,
          value: possibleEntries[i].value,
        }
        entries.push(entry)
      } else if (!isNil(keys)) {
        for (let j = 0; j < keys.length; j++) {
          if (!isNil(data[keys[j]])) {
            const convert = possibleEntries[i]?.convert
            let entry = {
              label: possibleEntries[i].label,
              value: !isNil(convert) ? convert(data[keys[j]]) : data[keys[j]],
            }
            entries.push(entry)
          }
        }
      }
    }
    return entries
  }, [data, harvestTimestamp])

  return (
    <PointDataList>
      {labelsValues.map((entry, idx) => {
        return (
          <Line key={idx}>
            <H $justifyContent="space-between">
              <H.Item>
                <label>{entry.label}</label>
              </H.Item>
              <H.Item>
                <InfoData title={entry.value}>{entry.value}</InfoData>
              </H.Item>
            </H>
          </Line>
        )
      })}
    </PointDataList>
  )
}

const PointDataList = styled(V)({
  maxHeight: '220px',
  overflowY: 'scroll',
  paddingRight: '10px',
})

const InfoData = styled('span')({
  float: 'right',
  width: '100px',
  fontWeight: 600,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textAlign: 'right',
  textOverflow: 'ellipsis',
})

const Line = styled(VItem)({
  width: '200px',
})
