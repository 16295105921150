/**
 * UI Kit Foundation
 *
 * 1. Colors
 * 1. Parameters
 * 1. Position
 * 1. Links
 * 1. Lists
 * 1. Controls
 */

import { styled } from '@mui/system'
import { Link as ReactRouterLink } from 'react-router'
import colors from 'common/colors'

// 1. Colors
export { default as colors } from 'common/colors'

// 1. Parameters
export const pointer = {
  cursor: 'pointer',
}

export const center = {
  display: 'block',
  margin: '0 auto',
}

export const defaultShadow = {
  boxShadow:
    '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 5px 0 rgba(19, 72, 137, 0.1), 0 0 0 0 rgba(48, 48, 48, 0.03)',
}

export const defaultBackground = {
  background: 'white',
}

// 1. Position
export const deadCenter = {
  display: 'block',
  margin: '8px auto',
}

// 1. Links
export const Link = styled(ReactRouterLink)({
  backgroundColor: 'transparent',
  border: 'none',
  textDecoration: 'none',
  display: 'inline',
  color: colors.brand,
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  '&:hover': {
    color: colors.brandLight,
  },
})

export const BlankLink = styled('span')({
  backgroundColor: 'transparent',
  border: 'none',
  textDecoration: 'none',
  display: 'inline',
  color: colors.brand,
  margin: 0,
  padding: 0,
  cursor: 'pointer',
  '&:focus': {
    outline: 'none',
  },
  '&:hover': {
    color: colors.brandLight,
  },
})

export const CallToAction = styled(Link)((props) => ({
  color: colors.brandSecondary,
  margin: props.center ? '8px auto' : '8px 0',
  '&:hover': {
    color: colors.brandSecondaryLight,
  },
}))

// 1. Lists
export const List = styled('ul')({
  listStyleType: 'none',
  paddingLeft: 0,
  margin: 0,
})

List.Item = styled('li')({
  paddingLeft: '8px',
  marginBottom: '8px',
  '& + &': {
    marginTop: '6px',
  },
})

export const Muted = styled('span')({
  color: colors.textMuted,
  fontSize: '12px',
})

// 1. Controls
const formControl = {
  display: 'block',
  width: '100%',
  padding: '0.375rem 0.75rem',
  fontSize: '1rem',
  lineHeight: 1.5,
  color: '#495057',
  backgroundColor: '#fff',
  backgroundClip: 'padding-box',
  border: '1px solid #ced4da',
  borderRadius: '0.25rem',
  transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
}

const formControlFocus = {
  color: '#495057',
  backgroundColor: '#fff',
  borderColor: '#80bdff',
  outline: 0,
  boxShadow: '0 0 0 1px rgb(0 123 255 / 25%)',
}

export const Select = styled('select')({
  ...formControl,
  '&:not([size]):not([multiple])': {
    height: 'calc(2.25rem + 2px)',
  },
  '&:focus': {
    ...formControlFocus,
  },
})

Select.Option = styled('option')({})

export const InputText = styled('input')({
  ...formControl,
  '&:focus': {
    ...formControlFocus,
  },
})
