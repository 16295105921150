//@ts-expect-error
import * as c from 'common/c'
import { get, findIndex, cloneDeep } from 'lodash-es'

declare module 'state/map.js' {
  export type LegendURL = {
    harvest: number
    name: string
    url: string
  }

  export type WMSLayer = {
    name: string
    bbox: number[]
    legendUrl: string
    harvest: number
    tenantId: string
    layerZIndex: number
    swathParameter: string
    queryable: boolean
  }

  export type PointInfoResult = {
    crop?: string
    Crop?: string
    yldt_ha?: number
    YLDt_ha?: number
    yldbu_ac?: number
    YLDbu_ac?: number
    yldkg_ha?: number
    YLDkg_ha?: number
    moisture?: number
    Moisture?: number
    speedkmh?: number
    SpeedKmh?: number
    harvestTimestamp?: string
    latitude?: number
    Latitude?: number
    longitude?: number
    Longitude?: number
    combine?: string
    Combine?: string
    headerw_m?: number
    HeaderW_M?: number
    temperatur?: number
    Temperatur?: number
    timedel?: number
    TimeDel?: number
    calibr?: number
    Calibr?: number
    ym_id?: string
    YM_id?: string
  }

  export type HarvestSwathParameter = {
    harvestId: number
    swathParameter: string
  }

  export interface Map {
    editField: boolean
    drawBoundary: boolean
    editBoundary: boolean
    drawHole: boolean
    unsavedChanges: boolean
    drawType: string
    newFeature: undefined
    center: number[]
    zoom: number
    pointInfoEnabled: boolean
    pointInfoReq: undefined | number[]
    pointInfoRes: Symbol | PointInfoResult
    areaInfoReq: undefined
    areaInfoRes: Symbol
    addNote: undefined
    noteText: undefined
    noteFeatures: object
    legendUrl: LegendURL[]
    legendUrl_a: LegendURL[]
    legendUrl_b: LegendURL[]
    wmsLayers: WMSLayer[]
    wmsLayersA: WMSLayer[]
    wmsLayersB: WMSLayer[]
    swathParameters: HarvestSwathParameter[]
    swathParameterA: HarvestSwathParameter[]
    swathParameterB: HarvestSwathParameter[]
  }
}
const DEFAULT_CENTER = [22.27825982386202, 37.343854737018034]
const DEFAULT_ZOOM = 4

const initState = {
  editField: false,
  drawBoundary: false,
  editBoundary: false,
  drawHole: false,
  unsavedChanges: false,
  drawType: 'MultiPolygon',
  newFeature: undefined,
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
  pointInfoEnabled: true,
  pointInfoReq: undefined,
  pointInfoRes: c.UNDEFINED,
  areaInfoReq: undefined,
  areaInfoRes: c.UNDEFINED,
  addNote: undefined,
  noteText: undefined,
  noteFeatures: new Map(),
  legendUrl: [],
  legendUrl_a: [],
  legendUrl_b: [],
  extent: undefined,
  wmsLayers: [],
  wmsLayersA: [],
  wmsLayersB: [],
  swathParameters: [],
  swathParameterA: [],
  swathParameterB: [],
}

export default (state = initState, action) => {
  switch (action.type) {
    case c.MAP_CENTER: {
      //@ts-expect-error
      return update(state, { center: { $set: action.payload } })
    }

    case c.MAP_ZOOM: {
      //@ts-expect-error
      return update(state, { zoom: { $set: action.payload } })
    }

    case c.DRAW_BOUNDARY: {
      //@ts-expect-error
      return update(state, {
        drawBoundary: { $set: action.payload },
        editBoundary: { $set: false },
        drawHole: { $set: false },
      })
    }

    case c.DRAW_HOLE: {
      //@ts-expect-error
      return update(state, {
        drawHole: { $set: action.payload },
        editBoundary: { $set: false },
        drawBoundary: { $set: false },
      })
    }

    case c.SET_NEW_FEATURE: {
      //@ts-expect-error
      return update(state, { newFeature: { $set: action.payload } })
    }

    case c.SET_DRAW_TYPE: {
      //@ts-expect-error
      return update(state, { drawType: { $set: action.payload } })
    }

    case c.SET_EDIT_FIELD: {
      //@ts-expect-error
      return update(state, { editField: { $set: action.payload } })
    }

    case c.EDIT_BOUNDARY: {
      //@ts-expect-error
      return update(state, {
        editBoundary: { $set: action.payload },
        drawBoundary: { $set: false },
        drawHole: { $set: false },
      })
    }

    case c.BOUNDARY_CHANGES_MADE: {
      //@ts-expect-error
      return update(state, {
        unsavedBoundary: { $set: action.payload },
      })
    }

    case c.POINT_INFO_ENABLED: {
      const layers: WMSLayer[] = get(state, 'wmsLayers', [])
      //@ts-expect-error
      return update(state, {
        pointInfoEnabled: { $set: layers.some((layer) => layer.queryable) },
      })
    }

    case c.POINT_INFO_REQ: {
      //@ts-expect-error
      return update(state, { pointInfoReq: { $set: action.payload } })
    }

    case c.POINT_INFO_RES: {
      //@ts-expect-error
      return update(state, { pointInfoRes: { $set: action.payload } })
    }

    case c.POINT_INFO_CLR: {
      //@ts-expect-error
      return update(state, {
        pointInfoReq: { $set: undefined },
        pointInfoRes: { $set: c.UNDEFINED },
      })
    }

    case c.AREA_INFO_REQ: {
      //@ts-expect-error
      return update(state, { areaInfoReq: { $set: action.payload } })
    }

    case c.AREA_INFO_RES: {
      //@ts-expect-error
      return update(state, { areaInfoRes: { $set: action.payload } })
    }

    case c.AREA_INFO_CLR: {
      //@ts-expect-error
      return update(state, {
        areaInfoReq: { $set: undefined },
        areaInfoRes: { $set: c.UNDEFINED },
      })
    }

    case c.SET_LAYER_LEGEND: {
      const layerLegends: LegendURL[] = get(state, 'legendUrl', [])
      const index = findIndex(
        layerLegends,
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          legendUrl: { $splice: [[index, 1, action.payload]] },
        })
      } //@ts-expect-error
      else return update(state, { legendUrl: { $unshift: [action.payload] } })
    }

    case c.REMOVE_LAYER_LEGEND: {
      const layerLegends: LegendURL[] = get(state, 'legendUrl', [])
      const idx = findIndex(
        layerLegends,
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (idx > -1) {
        //@ts-expect-error
        return update(state, { legendUrl: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        //@ts-expect-error
        return update(state, { legendUrl: { $set: [] } })
      } else {
        //@ts-expect-error

        return update(state, {
          legendUrl: { $set: get(state, 'legendUrl', []) },
        })
      }
    }

    case c.REMOVE_LAYER_LEGEND_A: {
      const layerLegends: LegendURL[] = get(state, 'legendUrl_a', [])
      const idx = findIndex(
        layerLegends,
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (idx > -1) {
        //@ts-expect-error
        return update(state, { legendUrl_a: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        //@ts-expect-error
        return update(state, { legendUrl_a: { $set: [] } })
      } else {
        //@ts-expect-error
        return update(state, {
          legendUrl_a: { $set: get(state, 'legendUrl', []) },
        })
      }
    }

    case c.REMOVE_LAYER_LEGEND_B: {
      const layerLegends: LegendURL[] = get(state, 'legendUrl_b', [])
      const idx = findIndex(
        layerLegends,
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (idx > -1) {
        //@ts-expect-error
        return update(state, { legendUrl_b: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        //@ts-expect-error
        return update(state, { legendUrl_b: { $set: [] } })
      } else {
        //@ts-expect-error
        return update(state, {
          legendUrl_b: { $set: get(state, 'legendUrl_b', []) },
        })
      }
    }

    case c.REMOVE_LAYER_LEGENDS: {
      //@ts-expect-error
      return update(state, {
        legendUrl: { $set: [] },
        legendUrl_a: { $set: [] },
        legendUrl_b: { $set: [] },
      })
    }

    case c.REMOVE_LAYER_LEGENDS_A: {
      //@ts-expect-error
      return update(state, {
        legendUrl_a: { $set: [] },
      })
    }

    case c.REMOVE_LAYER_LEGENDS_B: {
      //@ts-expect-error
      return update(state, {
        legendUrl_b: { $set: [] },
      })
    }

    case c.SET_LAYER_A: {
      const layers: WMSLayer[] = get(state, 'wmsLayersA', [])
      const index = findIndex(
        layers,
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          wmsLayersA: { $splice: [[index, 1, action.payload]] },
        })
      }
      //@ts-expect-error
      else return update(state, { wmsLayersA: { $unshift: [action.payload] } })
    }

    case c.SET_SWATH_PARAMETER_A: {
      const layers: WMSLayer[] = get(state, 'wmsLayersA', [])
      const index = findIndex(
        layers,
        (layer) =>
          // layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (index > -1) {
        const layer = cloneDeep(layers[index])
        const newSld = layer.legendUrl.replace(
          /\.sld$/,
          action.payload.swathParameterSldExt,
        )

        layer.legendUrl = newSld

        const swathParameters: string[] = get(state, 'swathParameterA', [])
        const swathIndex = findIndex(
          swathParameters,
          (sp) => sp === action.payload.swathParameter,
        )
        const stateUpdate = {}
        if (swathIndex >= 0) {
          stateUpdate['swathParameterA'] = {
            $splice: [
              [
                swathIndex,
                1,
                {
                  swathParameterA: action.payload.swathParameter,
                  harvestId: action.payload.harvest,
                },
              ],
            ],
          }
        } else {
          stateUpdate['swathParameterA'] = {
            $push: [
              {
                swathParameterA: action.payload.swathParameter,
                harvestId: action.payload.harvest,
              },
            ],
          }
        }
        stateUpdate['wmsLayersA'] = {
          $splice: [[index, 1, layer]],
        }
        //@ts-expect-error
        return update(state, stateUpdate)
      } else {
        console.log('set swath parameter A layer not found')
      }
    }

    case c.SET_LAYER_B: {
      const layers: WMSLayer[] = get(state, 'wmsLayersB', [])
      const index = findIndex(
        layers,
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          wmsLayersB: { $splice: [[index, 1, action.payload]] },
        })
      }
      //@ts-expect-error
      else return update(state, { wmsLayersB: { $unshift: [action.payload] } })
    }

    case c.SET_SWATH_PARAMETER_B: {
      const layers: WMSLayer[] = get(state, 'wmsLayersB', [])
      const index = findIndex(
        layers,
        (layer) =>
          // layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (index > -1) {
        const layer = cloneDeep(layers[index])
        const newSld = layer.legendUrl.replace(
          /\.sld$/,
          action.payload.swathParameterSldExt,
        )

        layer.legendUrl = newSld

        const swathParameters: string[] = get(state, 'swathParameterB', [])
        const swathIndex = findIndex(
          swathParameters,
          (sp) => sp === action.payload.swathParameter,
        )
        const stateUpdate = {}
        if (swathIndex >= 0) {
          stateUpdate['swathParameterB'] = {
            $splice: [
              [
                swathIndex,
                1,
                {
                  swathParameterB: action.payload.swathParameter,
                  harvestId: action.payload.harvest,
                },
              ],
            ],
          }
        } else {
          stateUpdate['swathParameterB'] = {
            $push: [
              {
                swathParameterB: action.payload.swathParameter,
                harvestId: action.payload.harvest,
              },
            ],
          }
        }
        stateUpdate['wmsLayersB'] = {
          $splice: [[index, 1, layer]],
        }
        //@ts-expect-error
        return update(state, stateUpdate)
      } else {
        console.log('set swath parameter B layer not found')
      }
    }

    case c.SET_LAYER: {
      const layers: WMSLayer[] = get(state, 'wmsLayers', [])
      const index = findIndex(
        layers,
        (layer) =>
          layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          wmsLayers: { $splice: [[index, 1, action.payload]] },
        })
      } //@ts-expect-error
      else return update(state, { wmsLayers: { $unshift: [action.payload] } })
    }

    case c.SET_SWATH_PARAMETER: {
      const layers: WMSLayer[] = get(state, 'wmsLayers', [])
      const index = findIndex(
        layers,
        (layer) =>
          // layer.name === action.payload.name &&
          layer.harvest === action?.payload?.harvest,
      )

      if (index > -1) {
        const layer = cloneDeep(layers[index])
        const newSld = layer.legendUrl.replace(
          /\.sld$/,
          action.payload.swathParameterSldExt,
        )

        layer.legendUrl = newSld

        const swathParameters: string[] = get(state, 'swathParameters', [])
        const swathIndex = findIndex(
          swathParameters,
          (sp) => sp === action.payload.swathParameter,
        )
        const stateUpdate = {}
        if (swathIndex >= 0) {
          stateUpdate['swathParameters'] = {
            $splice: [
              [
                swathIndex,
                1,
                {
                  swathParameter: action.payload.swathParameter,
                  harvestId: action.payload.harvest,
                },
              ],
            ],
          }
        } else {
          stateUpdate['swathParameters'] = {
            $push: [
              {
                swathParameter: action.payload.swathParameter,
                harvestId: action.payload.harvest,
              },
            ],
          }
        }
        stateUpdate['wmsLayers'] = {
          $splice: [[index, 1, layer]],
        }
        //@ts-expect-error
        return update(state, stateUpdate)
      } else {
        console.log('set swath parameter layer not found')
      }
    }

    case c.REMOVE_LAYER: {
      const layers: WMSLayer[] = get(state, 'wmsLayers', [])
      const idx = findIndex(
        layers,
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (idx > -1) {
        //@ts-expect-error
        return update(state, { wmsLayers: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        //@ts-expect-error
        return update(state, { wmsLayers: { $set: [] } })
      } else {
        //@ts-expect-error
        return update(state, {
          wmsLayers: { $set: get(state, 'wmsLayers', []) },
        })
      }
    }

    case c.REMOVE_LAYER_A: {
      const layers: WMSLayer[] = get(state, 'wmsLayersA', [])
      const idx = findIndex(
        layers,
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (idx > -1) {
        //@ts-expect-error
        return update(state, { wmsLayersA: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        //@ts-expect-error
        return update(state, { wmsLayersA: { $set: [] } })
      } else {
        //@ts-expect-error
        return update(state, {
          wmsLayersA: { $set: get(state, 'wmsLayersA', []) },
        })
      }
    }

    case c.REMOVE_LAYER_B: {
      const layers: WMSLayer[] = get(state, 'wmsLayersB', [])
      const idx = findIndex(
        layers,
        (layer) =>
          layer.name === action?.payload?.name &&
          layer.harvest === action?.payload?.harvest,
      )
      if (idx > -1) {
        //@ts-expect-error
        return update(state, { wmsLayersB: { $splice: [[idx, 1]] } })
      } else if (action.payload === undefined) {
        //@ts-expect-error
        return update(state, { wmsLayersB: { $set: [] } })
      } else {
        //@ts-expect-error
        return update(state, {
          wmsLayersB: { $set: get(state, 'wmsLayersB', []) },
        })
      }
    }

    case c.RESET_LAYERS: {
      //@ts-expect-error
      return update(state, { wmsLayers: { $set: [] }, legendUrl: { $set: [] } })
    }

    case c.RESET_LAYERS_A: {
      //@ts-expect-error
      return update(state, {
        wmsLayersA: { $set: [] },
        legendUrl_a: { $set: [] },
      })
    }

    case c.RESET_LAYERS_B: {
      //@ts-expect-error
      return update(state, {
        wmsLayersB: { $set: [] },
        legendUrl_b: { $set: [] },
      })
    }

    case c.SET_LAYER_LEGEND_A: {
      const layerLegends: LegendURL[] = get(state, 'legendUrl_a', [])
      const index = findIndex(
        layerLegends,
        (layer) =>
          layer.name === action.payload.name &&
          layer?.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          legendUrl_a: { $splice: [[index, 1, action.payload]] },
        })
      }
      //@ts-expect-error
      else return update(state, { legendUrl_a: { $unshift: [action.payload] } })
    }

    case c.SET_LAYER_LEGEND_B: {
      const layerLegends: LegendURL[] = get(state, 'legendUrl_b', [])
      const index = findIndex(
        layerLegends,
        (layer) =>
          layer.name === action.payload.name &&
          layer?.harvest === action?.payload?.harvest,
      )
      if (index > -1) {
        //@ts-expect-error
        return update(state, {
          legendUrl_b: { $splice: [[index, 1, action.payload]] },
        })
      }
      //@ts-expect-error
      else return update(state, { legendUrl_b: { $unshift: [action.payload] } })
    }

    case c.SET_EXTENT: {
      //@ts-expect-error
      return update(state, { extent: { $set: action.payload } })
    }

    default:
      return state
  }
}
