import { Alert, Box, Divider } from '@mui/material'
import i18next from 'i18next'
import { Navigate, Route, Routes } from 'react-router'
import { CommonSidebar, CommonSidebarLink } from 'components/common-sidebar'
import OCFFClientsComponent from 'components/ocff/clients'
import OCFFFarmsComponent from 'components/ocff/farms'
import OCFFieldsComponent from 'components/ocff/fields'
import UploadBoundariesComponent from 'components/lands/upload-boundaries'
import { fetchFieldImport } from 'api/field'
import { showModal } from 'actions/app'
import {
  FarmtrxIconUploadNew,
  FarmtrxIconClients,
  FarmtrxIconFarms,
  FarmtrxIconFields,
} from '@troo/farmtrx-icons'

export default function LandsComponent() {
  const { t } = useTranslation()
  const [reader, setReader] = useState()
  const [isTransferringData, setTransferringData] = useState(false)
  const dispatch = useDispatch()
  const enabledCFF = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )

  useEffect(() => {
    setReader(new FileReader())
  }, [])

  const onImportField = useCallback((fileList) => {
    let files = fileList
    if (files.length > 0) {
      let name = files[0].name
      if (name.indexOf('.zip') != -1) {
        convertFilePayload(files[0])
      } else {
        dispatch(
          showModal(
            <Alert severity="error">
              {i18next.t('import_file_must_be_zip')}
            </Alert>,
            { title: i18next.t('field_boundary_import_error') },
          ),
        )
        setTransferringData(false)
      }
    }
  })

  const convertFilePayload = useCallback((files) => {
    reader.readAsDataURL(files)
    reader.onload = async function () {
      let fieldImportPayload = reader.result.split(',')[1]
      let fieldImportObj = {
        fileName: files.name,
        payload: fieldImportPayload,
      }
      await dispatch(fetchFieldImport(fieldImportObj)).catch((res) => {
        dispatch(
          showModal(
            <Alert severity="error">{i18next.t(res.response.data)}</Alert>,
            { title: i18next.t('field_boundary_import_error') },
          ),
        )
        setTransferringData(false)
      })
    }
    reader.onerror = function (error) {
      console.log('Error: ', error) // eslint-disable-line no-console
    }
  })

  return (
    <Box sx={{ display: 'flex', overflowY: 'auto' }} width={1}>
      <CommonSidebar>
        {/* OCFF tabs */}
        {enabledCFF ? (
          <>
            <CommonSidebarLink
              to="../clients"
              icon={<FarmtrxIconClients />}
              label={t('clients')}
            />
            <Divider />
            <CommonSidebarLink
              to="../farms"
              icon={<FarmtrxIconFarms />}
              label={t('farms')}
            />
            <Divider />
          </>
        ) : (
          <></>
        )}
        <CommonSidebarLink
          to="../fields"
          icon={<FarmtrxIconFields />}
          label={t('fields')}
        />
        <Divider />
        <CommonSidebarLink
          to="../upload-boundaries"
          icon={<FarmtrxIconUploadNew />}
          label={t('upload_boundaries')}
        />
        <Divider />
      </CommonSidebar>
      {/* End */}

      <Routes>
        <Route
          exact
          path="/"
          element={
            enabledCFF ? (
              <Navigate to="/fields/clients" replace={true} />
            ) : (
              <Navigate to="/fields/fields" replace={true} />
            )
          }
        />

        {enabledCFF ? (
          <Route path="/clients" element={<OCFFClientsComponent />} />
        ) : (
          <Route
            path="/clients"
            element={
              <Box m={6}>
                <div>{t('no_permissions', { item: t('clients') })}</div>
              </Box>
            }
          />
        )}

        {enabledCFF ? (
          <Route path="/farms" element={<OCFFFarmsComponent />} />
        ) : (
          <Route
            path="/farms"
            element={
              <Box m={6}>
                <div>{t('no_permissions', { item: t('farms') })}</div>
              </Box>
            }
          />
        )}

        <Route path="/farms" element={<OCFFFarmsComponent />} />
        <Route path="/fields" element={<OCFFieldsComponent />} />
        <Route
          path="/upload-boundaries"
          element={
            <UploadBoundariesComponent
              onImportField={onImportField}
              isTransferringData={isTransferringData}
              setTransferringData={setTransferringData}
            />
          }
        />
      </Routes>
    </Box>
  )
}
