import { GeoJSON } from 'ol/format'
import Collection from 'ol/Collection'
import Feature from 'ol/Feature'
import { transform } from 'ol/proj'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { colorList } from 'common/ol/styles'
import { get, isNil, cloneDeep } from 'lodash-es'
import kinks from '@turf/kinks'
import unkinkPolygon from '@turf/unkink-polygon'

const GEO_JSON_WRITER = new GeoJSON()

export const toGeoJson = (features) => {
  const geom = []
  let _fCollection
  if (!(features instanceof Collection)) {
    _fCollection = new Collection([features])
  } else {
    _fCollection = features
  }

  _fCollection.forEach(function (feature) {
    geom.push(transformFeature(feature, false))
  })

  const geoJsonStr = GEO_JSON_WRITER.writeFeatures(geom)
  return JSON.parse(geoJsonStr)
}

export const toGeoJsonSingle = (feature) => {
  const geoJsonStr = GEO_JSON_WRITER.writeFeature(
    transformFeature(feature, false),
  )

  return JSON.parse(geoJsonStr)
}

//Converts a geoJSON feature collection to a collection of OpenLayers features.
export const fromGeoJson = (json) => {
  const transformedFeatures = []
  if (json?.features?.geometry?.coordinates?.length > 1) {
    const featuresArr = GEO_JSON_WRITER.readFeatures(json)
    featuresArr.forEach((f) => {
      transformedFeatures.push(transformFeature(f))
    })
  } else {
    const featuresArr = GEO_JSON_WRITER.readFeatures(json)
    featuresArr.forEach((f) => {
      transformedFeatures.push(transformFeature(f))
    })
  }

  return new Collection(transformedFeatures)
}

const transformFeature = (feature, fromGeoJson = true) => {
  const geometry = feature?.getGeometry()
  if (geometry) {
    const newFeature = new Feature(
      Object.assign({}, feature.getProperties(), {
        geometry: geometry
          .clone()
          .transform(
            fromGeoJson ? 'EPSG:4326' : 'EPSG:3857',
            fromGeoJson ? 'EPSG:3857' : 'EPSG:4326',
          ),
      }),
    )

    newFeature.setId(feature.getId())

    // TODO: this does not work so no style gets set here
    const styleName = feature.get('style')

    // set style if exists
    if (!isNil(styleName)) {
      newFeature.set('style', styleName)
      newFeature.setStyle(get(colorList, `${styleName}.normal`))
    }

    return newFeature
  }
  return feature
}

export const displayArea = (geometry) => {
  // TODO: this should display in the user's preferred units for area
  const area = geometry.getArea()
  let hectares
  hectares = area / 10000.0
  return hectares.toFixed(2) + ' ha'
}

export const transformCoords = (coords) => {
  return transform(coords, 'EPSG:3857', 'EPSG:4326')
}

export const to180Degrees = (feature) => {
  feature.geometry.coordinates = fixValues(feature.geometry.coordinates)
  return feature
}

export const fixValues = (item) => {
  if (Array.isArray(item)) {
    for (let i = 0; i < item.length; i++) {
      if (Array.isArray(item[i])) {
        item[i] = fixValues(item[i])
      } else {
        item[i] = item[i] % 360
        if (item[i] > 180) {
          item[i] = (item[i] % 180) - 180
        } else if (item[i] < -180) {
          item[i] = (item[i] % 180) + 180
        }
      }
    }
  }
  return item
}

export const unKink = (feature, drawHole, convertToGeoJSON = true) => {
  const geometry = convertToGeoJSON ? toGeoJson(feature) : feature

  console.log(geometry)
  let featureArray = []
  let foundKinks = false
  for (let i = 0; i < geometry.features.length; i++) {
    const curFeature = to180Degrees(cloneDeep(geometry.features[i]))
    geometry.features[i] = curFeature
    const numKinks = kinks(curFeature)
    if (numKinks.features.length > 0) {
      foundKinks = true
      const unkinked = unkinkPolygon(curFeature)
      if (drawHole) {
        for (let i = 0; i < unkinked.features.length; i++) {
          const _f = unkinked.features[i]
          _f.geometry.coordinates = [_f.geometry.coordinates]
          unkinked.features[i] = _f
        }
      }
      featureArray = featureArray.concat(unkinked.features)
    } else {
      featureArray = featureArray.concat(curFeature)
    }
  }
  if (foundKinks) {
    const coords = []
    for (let i = 0; i < featureArray.length; i++) {
      const workingCoords = featureArray[i].geometry.coordinates
      coords.push(workingCoords)
    }

    geometry.features = featureArray
    return geometry
  }
  return geometry
}
//Converts a geoJSON feature into an OpenLayers feature
export const convertGeoJsonToOpenLayersFeature = (geoJsonFeature) => {
  let coords = []
  if (geoJsonFeature.features.length > 1) {
    for (let i = 0; i < geoJsonFeature.features.length; i++) {
      const workingCoords = geoJsonFeature.features[i].geometry.coordinates
      coords.push(workingCoords)
    }
  } else {
    coords = geoJsonFeature.features[0].geometry.coordinates
  }
  const geom = new MultiPolygon(coords).transform('EPSG:4326', 'EPSG:3857')
  const newFeature = new Feature({
    geometry: geom,
  })
  return newFeature
}
