import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Alert,
  SxProps,
  Theme,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  Add as AddIcon,
  Clear as ClearIcon,
  SaveOutlined as SaveOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
  ModeEditOutlineOutlined as ModeEditOutlineOutlinedIcon,
} from '@mui/icons-material'

import { createFilterOptions } from '@mui/material/Autocomplete'
//@ts-expect-error
import colors from 'common/colors'

//@ts-expect-error
import { createEquipment, deleteEquipment, editEquipment } from 'api/equipment'
//@ts-expect-error
import { createBrand } from 'api/farm'
import { difference, isEmpty } from 'lodash-es'
import { useCallback, useEffect, useState, ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import {
  EquipmentFetchModel,
  EquipmentModelAPIRes,
  fetchEquipmentModels,
  //@ts-expect-error
} from 'api/equipment-model'
//@ts-expect-error
import MakeSelectorComponent from 'components/farm-settings/equipment/make-selector'
//@ts-expect-error
import ModelSelectorComponent from './model-selector'
//@ts-expect-error
import type { AppState } from 'state/index'
//@ts-expect-error
import type { EquipmentModel } from 'state/equipment'
//@ts-expect-error
import type { HardwareModel } from 'state/hardware'

const textFieldStyle = {
  root: {
    width: '18ch',
  },
}

const buttonStyle: SxProps<Theme> = (theme: Theme) => ({
  margin: 1,
  '&:disabled svg path': {
    color: '#ECE9F1',
  },
})

declare module 'components/farm-settings/equipment/index.js' {
  export type EquipmentErrors = {
    nickname: string
    model: string
    make: string
  }
  export type BrandOption = {
    id: number
    name: string
    inputValue: string | undefined
  }
  export type ModelOption = {
    id: number | null
    inputValue: string | undefined
    name: string
    makeBrandId: number | null
    equipmentType: string
  }
  export type EquipmentRowData = {
    id: string
    brandOption: BrandOption | null
    modelOption: Partial<ModelOption> | null
    nickname: string
    equipmentType: string | null
    timeDelaySec: number
    year: number | null
    ymId: string
    msId: string
    headerHeightId: string
  }

  export type EquipmentComponentProps = {
    hideTimeDelay?: boolean
    enableEqNextButton?: Function
  }
  export function EquipmentComponent(
    props: EquipmentComponentProps,
  ): ReactElement<any, any>
}

export default function EquipmentsComponent(
  props: EquipmentComponentProps,
): ReactElement<any, any> {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const hideTimeDelay = props?.hideTimeDelay

  const permissions = useSelector(
    (state) => (state as AppState).account.permissions,
  )
  const equipment = useSelector(
    (state) => (state as AppState).equipment.collection,
  )
  const brands = useSelector((state) => (state as AppState).brand.brands)
  const hardware = useSelector((state: AppState) => state.hardware.collection)

  const [canEdit, setCanEdit] = useState(false)
  const [equipmentModels, setEquipmentModels] = useState({})
  const [rows, setRows] = useState<EquipmentRowData[]>([])
  const [brandOptions, setBrandOptions] = useState<BrandOption[]>([])
  const [rowSelected, setSelectedRow] = useState<string | number>(-1)
  const [addButtonClicked, toggleAddRow] = useState(false)
  const [brandAdded, setBrandAdded] = useState(false)
  const [showHardware, setShowHardware] = useState(false)
  const [errors, setErrors] = useState<EquipmentErrors>({
    nickname: '',
    model: '',
    make: '',
  })
  const [editObj, setEditObj] = useState({})

  const filter = createFilterOptions()
  const { pathname } = useLocation()

  const [disableDeleteItem, setDisableDeleteItem] = useState(false)

  useEffect(() => {
    setCanEdit(permissions.includes('farm:write'))
  }, [permissions])

  useEffect(() => {
    if (
      pathname !== '/farmSetup/equipment' &&
      props?.enableEqNextButton != undefined
    ) {
      setShowHardware(false)
      if (equipment && equipment.length) {
        props?.enableEqNextButton(true)
      } else {
        props?.enableEqNextButton(false)
      }
    }
  }, [equipment, pathname])

  useEffect(() => {
    const missingModelIds: (string | undefined)[] = difference(
      equipment
        .filter((e) => e.equipmentModelId)
        .map((e) => (e as EquipmentModel)?.equipmentModelId?.toString()),
      Object.keys(equipmentModels),
    )

    if (missingModelIds.length > 0) {
      const reqIds: EquipmentFetchModel = { ids: missingModelIds }
      fetchEquipmentModels(reqIds).then(
        (missingModels: EquipmentModelAPIRes[]) => {
          for (const missingModel of missingModels) {
            equipmentModels[missingModel.id] = missingModel
          }

          setEquipmentModels({ ...equipmentModels })
        },
      )
    }
    let _rowData: EquipmentRowData[] = []
    equipment.forEach((item) => {
      const brandOption = brandOptions.find((brand) => {
        return brand.id === item.makeBrandId
      })
      const modelOption: Partial<ModelOption> = {
        id: item.equipmentModelId,
      }

      const ym: HardwareModel | undefined = hardware.find((ym) => {
        return (
          ym.equipmentId === item.id &&
          ym.hardwareType.toLowerCase() === 'yieldmonitor'
        )
      })

      const ms: HardwareModel | undefined = hardware.find((ym) => {
        return (
          ym.equipmentId === item.id &&
          ym.hardwareType.toLowerCase() === 'moisturesensor'
        )
      })

      const headerHeight: HardwareModel | undefined = hardware.find((ym) => {
        return (
          ym.equipmentId === item.id &&
          ym.hardwareType.toLowerCase() === 'headerheightsensor'
        )
      })

      _rowData.push({
        id: item.id,
        brandOption: brandOption || null,
        modelOption: modelOption || null,
        nickname: item.nickname,
        equipmentType: item.equipmentType,
        timeDelaySec: item.timeDelaySec,
        year: item.year,
        msId: ms?.farmtrxHardwareId || '',
        ymId: ym?.farmtrxHardwareId || '',
        headerHeightId: headerHeight?.farmtrxHardwareId || '',
      })
    })

    setRows(_rowData)
  }, [equipment, brandOptions, hardware])

  useEffect(() => {
    if (brandAdded) {
      let addedBrand, rowIndex
      rowIndex = rows.findIndex((row) => row.id === rowSelected)
      addedBrand = brands.filter(
        (brand) =>
          brand.name.trim() === rows[rowIndex]?.brandOption?.name?.trim(),
      )
      let item = {
        ...rows[rowIndex],
        brandOption: addedBrand[0].id,
      }
      rows[rowIndex] = item
      setRows(rows)
    } else {
      let _brandOptions: BrandOption[] = []
      brands.forEach((item) => {
        _brandOptions.push({
          id: item.id,
          name: item.name,
          inputValue: '',
        })
      })
      setBrandOptions(_brandOptions)
    }
  }, [brands])

  const equipmentTypes = {
    1: 'Combine',
  }

  const handleInputChange = useCallback(
    (e: React.ChangeEvent, updatedRow: EquipmentRowData, newValue?: any) => {
      //@ts-ignore
      let { name, value } = e.target
      const { id } = updatedRow
      const newRows = rows.map((row) => {
        if (row.id === id) {
          if (!name) {
            name = 'brandOption'
            if (newValue && newValue.inputValue != '') {
              //@ts-ignore
              dispatch(createBrand({ name: newValue.inputValue })).then(
                (res: EquipmentModelAPIRes) => {
                  value = {
                    id: res.id,
                    name: res.name,
                    inputValue: '',
                  }
                },
              )
            } else {
              value = newValue
            }

            // Reset equipmentModelId when the brand changes.
            row.modelOption = null
          }
          if (name === 'year' || name === 'timeDelaySec') {
            if (value === '') {
              return { ...row, [name]: null }
            }
          }
          return { ...row, [name]: value }
        }
        return row
      })
      setRows(newRows)
      validate({ [name]: value })
    },
    [rows],
  )

  const onMakeChange = useCallback(
    (updatedRow, newSelectedBrandOption) => {
      const newRows = rows.map((row) => {
        if (row.id === updatedRow.id) {
          row.brandOption = newSelectedBrandOption || null
          if (row.brandOption?.id !== newSelectedBrandOption?.id) {
            row.modelOption = null
          } else {
            row.modelOption = row.brandOption ? row.modelOption : null
          }
        }
        validate({
          brandOption: newSelectedBrandOption,
          modelOption: row.modelOption,
        })
        return row
      })
      setRows(newRows)
    },
    [rows],
  )

  const onModelChange = useCallback(
    (updatedRow, newModelOption) => {
      const newRows = rows.map((row) => {
        if (row.id === updatedRow.id) {
          row.modelOption = newModelOption
        }
        return row
      })

      validate({ modelOption: newModelOption })
      setRows(newRows)
    },
    [rows],
  )

  const validate = (data) => {
    let temp: EquipmentErrors = { ...errors }

    if ('nickname' in data) {
      temp.nickname = data.nickname ? '' : t('required_field')
    }
    if ('modelOption' in data) {
      temp.model = data.modelOption ? '' : t('required_field')
    }
    if ('brandOption' in data) {
      temp.make = data.brandOption !== undefined ? '' : t('required_field')
    }
    setErrors({
      ...temp,
    })
  }

  const enableSelectedRow = useCallback(
    (row) => {
      if (disableDeleteItem) {
        setDisableDeleteItem(false)
      }
      setEditObj({ ...row })
      toggleAddRow(false)
      setSelectedRow(row.id)
    },
    [rows, disableDeleteItem],
  )

  const clearData = useCallback(
    (obj) => {
      let newRows = rows.map((row) => {
        if (row.id === obj.id) {
          return { ...row, ...editObj }
        }
        return row
      })
      if (!addButtonClicked) {
        setSelectedRow(-1)
      } else {
        toggleAddRow(false)
        newRows = newRows.filter((item) => item.id !== obj.id)
      }

      setRows(newRows)
      setEditObj({})
    },
    [rows, editObj],
  )

  const addEmptyRow = useCallback(
    (e) => {
      if (disableDeleteItem) {
        setDisableDeleteItem(false)
      }
      if (addButtonClicked) {
        e.preventDefault()
      } else {
        toggleAddRow(true)
        let id = uuidv4()
        setSelectedRow(id)
        const newRow = {
          id,
          brandOption: null,
          modelOption: null,
          nickname: '',
          equipmentType: 'Combine',
          timeDelaySec: 10,
          year: null,
          ymId: '',
          msId: '',
          headerHeightId: '',
        }
        setRows([...rows, newRow])
        setEditObj(newRow)
      }
    },
    [rows, brands, disableDeleteItem],
  )

  const processData = useCallback(
    (id) => {
      let dataToSave: EquipmentRowData | undefined = rows.find(
        (s) => s.id === id,
      )

      let unique = rows.filter(
        (item) => item.nickname.trim() === dataToSave?.nickname.trim(),
      )
      if (unique.length > 1) {
        alert(
          'Please provide proper inputs. Consider checking if two equipment have the same nickname',
        )
        setRows(rows)
      } else {
        saveData(dataToSave)
      }
    },
    [rows],
  )

  //Function to store the Equipment data into the DB
  const saveData = useCallback(
    (dataToSave) => {
      let obj = {
        id: dataToSave.id,
        makeBrandId: dataToSave.brandOption.id,
        equipmentModelId: dataToSave.modelOption.id,
        nickname: dataToSave.nickname,
        equipmentType: dataToSave.equipmentType,
        timeDelaySec: dataToSave.timeDelaySec,
        year: dataToSave.year,
      }
      if (addButtonClicked) {
        dispatch(createEquipment(obj))
      } else {
        dispatch(editEquipment(dataToSave.id, obj))
      }
      setSelectedRow(-1)
      toggleAddRow(false)
      setEditObj({})
    },
    [brands, rows],
  )

  const deleteItem = useCallback(
    (id) => {
      if (rows?.length > 1) {
        dispatch(deleteEquipment(id))
        toggleAddRow(false)
        if (rowSelected == id) setEditObj({})
      } else {
        setDisableDeleteItem(true)
      }
    },
    [addButtonClicked, rowSelected, rows],
  )

  const disableButton = useCallback(
    (data) => {
      if (
        data?.nickname == '' ||
        data?.modelOption?.id == null ||
        data?.brandOption == null
      ) {
        return true
      } else {
        return false
      }
    },
    [rows, errors],
  )

  const renderName = useCallback((row) => {
    return row?.brandOption?.name || ''
  }, [])
  return (
    <>
      <Box px={3} py={4} width={1} style={{ overflowX: 'auto' }}>
        {pathname !== '/farmSetup/equipment' ? (
          <h2> {t('select_equipment')}</h2>
        ) : null}
        {disableDeleteItem ? (
          <Alert severity="error">
            {t('delete_item_disable_message', { item: t('equipment') })}
          </Alert>
        ) : null}
        <Box
          my={1}
          sx={{
            border: '1px solid #003057',
            borderRadius: '2px',
            overflowY: 'auto',
          }}
        >
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t('combine_name')}</TableCell>
                  <TableCell align="left">{t('make')}</TableCell>
                  <TableCell align="left">{t('model')}</TableCell>
                  <TableCell align="left">{t('production_year')}</TableCell>
                  {hideTimeDelay ? null : (
                    <TableCell align="left">{t('time_delay')}</TableCell>
                  )}
                  {showHardware ? (
                    <TableCell align="left">{t('ym_id')}</TableCell>
                  ) : null}
                  {showHardware ? (
                    <TableCell align="left">{t('ms_id')}</TableCell>
                  ) : null}
                  {showHardware ? (
                    <TableCell align="left">
                      {t('header_height_sensor_id')}
                    </TableCell>
                  ) : null}
                  <TableCell align="left"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.length
                  ? rows?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell align="left">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <TextField
                              sx={textFieldStyle}
                              type="text"
                              name="nickname"
                              value={row.nickname.trim()}
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row)}
                              {...(row.id == rowSelected && {
                                ...(errors['nickname'] && {
                                  error: true,
                                  helperText: errors['nickname'],
                                }),
                              })}
                            />
                          ) : (
                            row.nickname.trim()
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <MakeSelectorComponent
                              onValueChange={(newBrandOption) => {
                                onMakeChange(row, newBrandOption)
                              }}
                              value={row.brandOption}
                              row={row}
                            />
                          ) : (
                            renderName(row)
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <ModelSelectorComponent
                              makeBrandId={row?.brandOption?.id || null}
                              equipmentType={row.equipmentType}
                              value={row.modelOption}
                              onValueChange={(newModelOption) => {
                                onModelChange(row, newModelOption)
                              }}
                              disabled={row?.brandOption ? false : true}
                            />
                          ) : row.modelOption?.id ? (
                            equipmentModels[row.modelOption.id]?.name
                          ) : (
                            ''
                          )}
                        </TableCell>

                        <TableCell align="left">
                          {Object.keys(editObj).length &&
                          rowSelected === row.id ? (
                            <TextField
                              sx={{ width: '9ch' }}
                              type="number"
                              name="year"
                              value={row.year ? row.year : ''}
                              disabled={row.id != rowSelected}
                              onChange={(e) => handleInputChange(e, row, '')}
                            />
                          ) : row.year ? (
                            row.year
                          ) : (
                            ''
                          )}
                        </TableCell>

                        {hideTimeDelay ? null : (
                          <TableCell align="left">
                            {Object.keys(editObj).length &&
                            rowSelected === row.id ? (
                              <TextField
                                sx={{ width: '9ch' }}
                                type="number"
                                name="timeDelaySec"
                                value={row.timeDelaySec ? row.timeDelaySec : ''}
                                disabled={row.id != rowSelected}
                                onChange={(e) => handleInputChange(e, row, '')}
                              />
                            ) : row.timeDelaySec ? (
                              row.timeDelaySec
                            ) : (
                              ''
                            )}
                          </TableCell>
                        )}

                        {showHardware ? (
                          <TableCell component="th" scope="row">
                            {row.ymId}
                          </TableCell>
                        ) : null}

                        {showHardware ? (
                          <TableCell component="th" scope="row">
                            {row.msId}
                          </TableCell>
                        ) : null}

                        {showHardware ? (
                          <TableCell component="th" scope="row">
                            {row.headerHeightId}
                          </TableCell>
                        ) : null}

                        {canEdit && (
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ borderBottom: 'none' }}
                          >
                            {!rowSelected || row.id != rowSelected ? (
                              <Box display="flex">
                                <IconButton
                                  color="primary"
                                  aria-label={t('edit')}
                                  sx={buttonStyle}
                                  onClick={() => enableSelectedRow(row)}
                                  disabled={!isEmpty(editObj)}
                                  size="large"
                                >
                                  <ModeEditOutlineOutlinedIcon
                                    fontSize="small"
                                    style={{ color: colors.brandLight }}
                                  />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  aria-label={t('delete')}
                                  sx={buttonStyle}
                                  onClick={() => deleteItem(row.id)}
                                  size="large"
                                >
                                  <DeleteOutlineOutlinedIcon
                                    fontSize="small"
                                    style={{ color: '#B2023B' }}
                                  />
                                </IconButton>
                              </Box>
                            ) : (
                              <div hidden={row.id != rowSelected}>
                                <IconButton
                                  color="primary"
                                  aria-label={t('save')}
                                  sx={buttonStyle}
                                  disabled={disableButton(row)}
                                  onClick={() => processData(row.id)}
                                  size="large"
                                >
                                  <SaveOutlinedIcon
                                    fontSize="small"
                                    style={{ color: colors.brandLight }}
                                  />
                                </IconButton>
                                <IconButton
                                  color="secondary"
                                  aria-label={t('cancel')}
                                  sx={buttonStyle}
                                  onClick={() => clearData(row)}
                                  size="large"
                                >
                                  <ClearIcon
                                    fontSize="small"
                                    style={{ color: '#B2023B' }}
                                  />
                                </IconButton>
                              </div>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {canEdit && (
          <Box display="flex">
            <Button
              variant="outlined"
              onClick={(e) => addEmptyRow(e)}
              sx={{ borderRadius: '25px', marginTop: '20px' }}
              endIcon={<AddIcon />}
            >
              {t('add_equipment')}
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}
