import { Vector as VectorLayer } from 'ol/layer'
import useMap from 'hooks/use-map'
import { getCropStyle, defaultStyle } from 'common/ol/styles'
import { get, isEmpty } from 'lodash-es'

import useSource from 'hooks/use-source-boundary'
import { useEffect } from 'react'

export default function MapFieldsOverview(props) {
  const { features } = props
  const map = useMap()
  const source = useSource()

  const fieldsHarvested = useSelector((state) =>
    get(state, `field.fieldsHarvested`),
  )
  const allFields = useSelector((state) => get(state, `field.collection`))
  const allFarms = useSelector((state) => get(state, `farm.collection`, []))

  const cffEnabled = useSelector((state) =>
    get(state, 'account.singleton.prefs.cff.enabled'),
  )

  const crop = useSelector((state) => get(state, `field.crop`))
  const crops = useSelector((state) => state.crop['cropConstantsCollection'])
  const client = useSelector((state) => get(state, `client.singleton`))
  const farms = useSelector((state) => get(state, `farm.selectedFarms`))
  const featuresOverview = useSelector((state) =>
    get(state, `field.featuresOverview`),
  )

  const sideHeaderVisibility = useSelector((state) =>
    get(state, `app.sideHeaderVisible`),
  )
  const [featuresMap, setFeaturesMap] = useState({})
  const [hiddenFields, setHiddenFields] = useState([])
  const [harvestedFields, setHarvestFields] = useState([])
  const [filteredFields, setFilteredFields] = useState([])

  const [layer, setLayer] = useState()
  const [menuElement, setMenuElement] = useState(
    document.getElementById('menu-'),
  )

  useEffect(() => {
    const _featuresMap = {}
    const featureArray = features.getArray()
    for (let i = 0; i < featureArray.length; i++) {
      const id = featureArray[i].getId()
      _featuresMap[id] = featureArray[i]
    }
    setFeaturesMap(_featuresMap)
  }, [layer, features])

  useEffect(() => {
    map.updateSize()
  }, [sideHeaderVisibility, menuElement])

  useEffect(() => {
    /*This is used to check if either of the Select dropdowns from the sidepanel is open. 
    The reason is that, when they are open, the layers get shifted. 
    So, when the dropdown is open, we update the map so that the overlapping of layers happen properly.*/
    const observer = new MutationObserver(() => {
      const element = document.getElementById('menu-')
      setMenuElement(element)
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (map && !isEmpty(features)) {
      const _layer = new VectorLayer({
        className: 'bfield-overview-layer',
        updateWhileAnimating: false,
        renderBuffer: 0,
        renderOrder: null,
        zIndex: 15,
        style: cropStyleFunction,
        minZoom: 10.999,
      })
      setLayer(_layer)
    }
  }, [map, features])

  useEffect(() => {
    if (layer) {
      map.addLayer(layer)
    }

    return () => {
      map.removeLayer(layer)
    }
  }, [map, layer])

  useEffect(() => {
    if (layer && source) {
      source.addFeatures(features)
      layer.setSource(source)
    }
  }, [layer, source])

  useEffect(() => {
    if (fieldsHarvested) {
      let filteredFieldIds = []
      for (let i = 0; i < fieldsHarvested.length; i++) {
        filteredFieldIds.push(fieldsHarvested[i].id)
      }
      setHarvestFields(filteredFieldIds)
    }
  }, [fieldsHarvested])

  useEffect(() => {
    let visible = []
    const currentFeatures = source.getFeaturesCollection().getArray()
    if (hiddenFields.length > 0) {
      for (let i = currentFeatures.length - 1; i >= 0; i--) {
        const feature = currentFeatures[i]
        const id = feature.getId()
        if (hiddenFields.includes(id)) {
          try {
            source.removeFeature(feature)
          } catch (e) {
            console.log('failed to remove: ', id)
          }
        } else {
          visible.push(id)
        }
      }
    } else {
      for (let i = currentFeatures.length - 1; i >= 0; i--) {
        const feature = currentFeatures[i]
        const id = feature.getId()
        visible.push(id)
      }
    }

    if (crop !== 'all') {
      if (harvestedFields.length) {
        for (let i = 0; i < harvestedFields.length; i++) {
          const id = harvestedFields[i]
          const feature = featuresMap[id]
          if (!visible.includes(id) && feature) {
            source.addFeature(feature)
          }
        }
      }
    } else {
      for (let i = 0; i < filteredFields.length; i++) {
        const id = filteredFields[i]
        const feature = featuresMap[id]
        if (!visible.includes(id) && feature) {
          source.addFeature(feature)
        }
      }
    }
  }, [hiddenFields, harvestedFields, featuresMap, filteredFields, crop])

  useEffect(() => {
    let _filteredFields = []
    let farmIds = []
    if (cffEnabled) {
      if (farms?.length) {
        farmIds = farms.map((f) => f.id)
      }
      if (farmIds.length) {
        _filteredFields = allFields.filter((f) => farmIds.includes(f.farmId))
      }
    } else {
      _filteredFields = allFields
    }
    setFilteredFields(_filteredFields.map((f) => f.id))
  }, [client, farms, allFields, allFarms])

  useEffect(() => {
    if (!isEmpty(featuresMap)) {
      const featuresArray = features.getArray()
      if (crop == 'all') {
        let removedFeatures = []
        for (let i = 0; i < featuresArray.length; i++) {
          const id = featuresArray[i].getId()
          const found = filteredFields.includes(id)
          if (!found) {
            removedFeatures.push(id)
          }
        }
        setHiddenFields(removedFeatures)
      } else {
        let removedFeatures = []
        for (let i = 0; i < featuresArray.length; i++) {
          const id = featuresArray[i].getId()
          const found = filteredFields.includes(id)
          if (!harvestedFields.includes(id) || !found) {
            removedFeatures.push(id)
          }
        }
        setHiddenFields(removedFeatures)
      }
    }
  }, [harvestedFields, features, source, crop, featuresMap, filteredFields])

  // eslint-disable-next-line no-unused-vars
  function cropStyleFunction(feature, resolution) {
    if (feature) {
      const cropId = featuresOverview.find((f) => f.id === feature.id_)?.cropId
      if (cropId) {
        const cropColor = crops.find((c) => c.id === cropId).color
        return getCropStyle(cropId, cropColor)['normal']
      }
    }
    return defaultStyle
  }

  return null
}
