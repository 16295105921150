//@ts-expect-error
import { LayerDownloadUrl } from 'state/harvest'
//@ts-expect-error
import { AppState } from 'state/index'
import { useSelector, useDispatch } from 'react-redux'
//@ts-expect-error
import { IN_PROGRESS, DONE } from 'common/c'
//@ts-expect-error
import SyncStatus from 'components/manage-integrations/sync-status'
import { useCallback, useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import { closeModal } from 'actions/app'

export default function AwaitingLayerDownload() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const layerDownload: LayerDownloadUrl | null = useSelector(
    (state: AppState) => state.harvest.layerDownloadUrl,
  )
  const [downloadReady, setDownloadReady] = useState<boolean>(false)

  useEffect(() => {
    if (layerDownload?.url && layerDownload?.url !== '') {
      setDownloadReady(true)
    }
  }, [layerDownload])

  const onDownload = useCallback(() => {
    if (layerDownload?.url) {
      window.location.href = layerDownload?.url
      setDownloadReady(false)
      dispatch(closeModal())
    }
  }, [layerDownload])

  return (
    <Stack spacing={3} alignItems={'center'}>
      {downloadReady ? (
        <>
          <SyncStatus status={DONE} />
        </>
      ) : (
        <SyncStatus status={IN_PROGRESS} />
      )}
      <Button
        variant="contained"
        onClick={() => onDownload()}
        disabled={!downloadReady}
      >
        {t('download')}
      </Button>
    </Stack>
  )
}
