//@ts-expect-error
import * as c from 'common/c'

export const harvestsByYearFetched = (data) => {
  return {
    type: c.HARVESTS_BY_YEAR_FETCHED,
    payload: {
      data,
    },
  }
}

export const addSingleFeatureToHarvest = (feature) => {
  return {
    type: c.ADD_HARVEST_FEATURE,
    payload: feature,
  }
}

export const setHarvestFeatures = (data) => {
  return {
    type: c.SET_HARVEST_FEATURES,
    payload: data,
  }
}

export const harvestFetched = (data) => {
  return {
    type: c.SINGLE_HARVEST_FETCHED,
    payload: data,
  }
}

export const updateHarvestById = (response, request) => {
  return {
    type: c.UPDATE_HARVEST_BY_ID,
    payload: {
      response,
      request,
    },
  }
}

export const overrideHarvestHeader = (id, data) => {
  return {
    type: c.UPDATE_HARVEST_HEADER,
    payload: {
      id,
      data,
    },
  }
}

export const harvestHeadersFetched = (id, data) => {
  return {
    type: c.HARVEST_HEADERS_FETCHED,
    payload: {
      id,
      data,
    },
  }
}

export const harvestYearsFetched = (data) => {
  return {
    type: c.HARVEST_YEARS_FETCHED,
    payload: {
      data,
    },
  }
}

export const setYear = (data) => {
  return {
    type: c.SET_YEAR,
    payload: {
      data,
    },
  }
}

export const harvestAFetched = (data) => {
  return {
    type: c.SINGLE_HARVEST_A_FETCHED,
    payload: data,
  }
}

export const harvestBFetched = (data) => {
  return {
    type: c.SINGLE_HARVEST_B_FETCHED,
    payload: data,
  }
}

export const harvestsFetched = (data) => {
  return {
    type: c.HARVESTS_FETCHED,
    payload: data,
  }
}

export const harvestsAFetched = (data) => {
  return {
    type: c.HARVESTS_A_FETCHED,
    payload: data,
  }
}

export const harvestsBFetched = (data) => {
  return {
    type: c.HARVESTS_B_FETCHED,
    payload: data,
  }
}

export const clearHarvests = () => {
  return {
    type: c.CLEAR_HARVESTS,
  }
}
