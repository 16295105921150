import { styled } from '@mui/system'
import useMap from 'hooks/use-map'
import { get } from 'lodash-es'
import { useEffect, useState } from 'react'
import useDebounce from 'hooks/util/debounce'

import Overlay from 'ol/Overlay'
import { useSelector } from 'react-redux'
const EMPTY_EL = '&nbsp;'

export default function OnMapHover(props) {
  const fields = useSelector((state) => get(state, 'field.collection', []))
  const { mapRef = 'map' } = props
  let map = useMap(mapRef)
  const selectedField = useSelector((state) =>
    get(state, 'field.singleton', undefined),
  )

  const [fieldMap, setFieldMap] = useState({})
  const [fieldName, setFieldName] = useState(EMPTY_EL)
  const [coordinate, setCoordinate] = useState(null)
  const [pixel, setPixel] = useState([])

  const [overlay, setOverlay] = useState(null)
  useEffect(() => {
    let _fieldMap = {}
    for (let i = 0; i < fields.length; i++) {
      const id = fields[i].id
      _fieldMap[id] = fields[i].name
    }
    setFieldMap(_fieldMap)
  }, [fields])

  // init pointermove
  useEffect(() => {
    if (!map) return

    function onHover(e) {
      if (fieldName != EMPTY_EL) {
        setFieldName(EMPTY_EL)
      }
      setPixel(e.pixel)
      setCoordinate(e.coordinate)
    }

    map.on('pointermove', onHover)

    return () => {
      map.un('pointermove', onHover)
    }
  }, [map, fieldMap, fieldName])

  useEffect(() => {
    if (map && overlay) {
      map.addOverlay(overlay)
    }
  }, [map, overlay])

  const invokeHover = useDebounce(() => {
    if (map) {
      let name = EMPTY_EL
      map.forEachFeatureAtPixel(pixel, function (feature) {
        const id = feature.getId()

        if (fieldMap[id] != undefined && selectedField?.id != id) {
          if (name == EMPTY_EL) {
            name = fieldMap[id]
          } else {
            name += `\n${fieldMap[id]}`
          }
        }
      })
      const currentOverlays = map.getOverlays().getArray()
      for (let o in currentOverlays) {
        if (
          currentOverlays[o] != overlay &&
          currentOverlays[o].id == 'field-tooltip'
        )
          map.removeOverlay(currentOverlays[o])
      }

      if (name != EMPTY_EL) {
        setFieldName(name)
        const el = document.getElementById('field-name-hover')
        if (overlay == null && el) {
          const _overlay = new Overlay({
            element: el,
            position: coordinate,
            offset: [-254, -72],
            id: 'field-tooltip',
          })
          setOverlay(_overlay)
        } else if (overlay != null) {
          overlay.setPosition(coordinate)
        }
      }
    }
  }, 1000)

  useEffect(invokeHover, [map, coordinate, overlay, fieldName])

  return (
    <div>
      <div id="field-name-hover">
        {overlay != null && fieldName != EMPTY_EL ? (
          <StyledWrapper $pixel={{ x: 5, y: 20 }}>
            <div style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
              {fieldName}
            </div>
            <Arrow $pixel={{ x: 5, y: 20 }} />
          </StyledWrapper>
        ) : null}
      </div>
    </div>
  )
}

const StyledWrapper = styled('div')(({ $pixel }) => ({
  backgroundColor: 'white',
  padding: '8px',
  position: 'fixed',
  top: `${$pixel.y + 30}px`,
  left: `${$pixel.x - 5}px`,
  width: '238px',
  minHeight: '20px',
  zIndex: 1000,
  overflowY: 'auto',
  borderRadius: '4px',
}))

const Arrow = styled('div')(({ $pixel }) => ({
  position: 'fixed',
  top: `${$pixel.y + 38}px`,
  left: `${$pixel.x + 229}px`,
  borderBottom: '10px solid transparent',
  borderTop: '10px solid transparent',
  borderLeft: '10px solid white',
  height: 0,
  width: 0,
}))
