import { styled } from '@mui/system'
import { Button, FormControlLabel, MenuItem, Select, Box } from '@mui/material'
//@ts-expect-error
import { closeModal } from 'actions/app'
import { useCallback, useEffect } from 'react'
//@ts-expect-error
import ModalActions from 'components/modal/modal-actions'
//@ts-expect-error
import ModalHeader from 'components/modal/modal-header'
//@ts-expect-error
import ModalContainer from 'components/modal/modal-container'
//@ts-expect-error
import LayerGroup from 'components/layers-group/layer-group'
//@ts-expect-error
import ItemSwitch from 'components/layers-group/item-switch'
import { SubscriptionsOutlined as SubscriptionsIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router'
import {
  defaultFormatOptions,
  DefaultLayerFormats,
  LayerType,
  //@ts-expect-error
} from 'common/layer-data'
//@ts-expect-error
import { isSubscriptionValid } from 'common/misc'
//@ts-expect-error
import { AppState } from 'state/index'
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import AwaitingLayerDownload from './awaiting-download'
//@ts-expect-error
import { exportLayer } from 'api/field'
//@ts-expect-error
import { LayerInfoModel } from 'state/field'
import { get } from 'lodash-es'

export default function LayerDownload({ data, harvestLayers }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let navigate = useNavigate()

  const [layerInfoMap, setLayerInfoMap] = useState<{
    [key: string]: LayerInfoModel
  }>({})
  const layerInfo: LayerInfoModel[] = useSelector((state) =>
    get(state, 'field.layerInfo', []),
  )
  const [awaitingDownload, setAwaitingDownload] = useState(false)

  const [layerOptions, setLayerOptions] = useState<LayerType[]>([])
  const [selectedLayer, setSelectedLayer] = useState<LayerType>()
  const [selectedFormat, setSelectedFormat] = useState<string>()
  const [currentFormats, setCurrentFormats] = useState<DefaultLayerFormats[]>(
    defaultFormatOptions.filter((option) =>
      layerOptions[0]?.formats?.includes(option.name),
    ),
  )
  const [activeStatus, setActiveStatus] = useState(false)
  const subscriptions = useSelector(
    (state: AppState) => state.account.subscriptions,
  )

  useEffect(() => {
    const _layerInfoMap = {}
    layerInfo.map((l) => {
      _layerInfoMap[l.layerName] = l
    })
    setLayerInfoMap(_layerInfoMap)
  }, [layerInfo])

  useEffect(() => {
    setActiveStatus(
      isSubscriptionValid(subscriptions, data.startDate, data.endDate),
    )
  }, [])

  useEffect(() => {
    let options: LayerType[] = []

    for (let key in harvestLayers) {
      const layer = harvestLayers[key]
      const optionalKey = layer?.base_layer
      if (
        layerInfoMap[key] ||
        (optionalKey && layerInfoMap[optionalKey]) ||
        key === 'boundary'
      ) {
        let translation = layer.translation
        if (translation === 'classified_yield_map') {
          translation = 'classified_yield_map_raster'
        } else if (translation === 'yield_map_trend') {
          translation = 'yield_map_trend_raster'
        }
        if (layer?.exportName && layer?.formats && layer?.formats.length > 0) {
          options.push({
            name: layer.name,
            translation: t(translation),
            selected: layer.selected,
            zindex: layer.zindex,
            formats: layer.formats,
            base_layer: layer?.base_layer,
            exportName: layer.exportName,
          })
        }
      }
    }
    if (options && options.length > 0) {
      options.sort((a, b) => {
        if (a.name > b.name) return 1
        else if (a.name < b.name) return -1
        return 0
      })
      setLayerOptions(options)
      setSelectedLayer(options[0])
      setCurrentFormats(
        defaultFormatOptions.filter((option) =>
          options[0]?.formats?.includes(option.name),
        ),
      )
      setSelectedFormat(options[0].formats[0])
    }
  }, [harvestLayers, layerInfoMap])

  const onClose = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const onExportLayer = useCallback(() => {
    if (selectedLayer && selectedFormat) {
      const exportType =
        harvestLayers[selectedLayer.name].exportName[selectedFormat]
      dispatch(
        exportLayer(data.id, {
          tenant_id: data.tenant_id,
          export_type: exportType,
        }),
      )
      setAwaitingDownload(true)
    }
  }, [selectedLayer, selectedFormat])

  const onLayerOptionChange = useCallback(
    (e) => {
      const value = e.target.value
      const layerFormats = layerOptions.find((layer) => layer.name === value)
      if (layerFormats) {
        setSelectedLayer(layerFormats)

        setCurrentFormats(
          defaultFormatOptions.filter((option) =>
            layerFormats?.formats?.includes(option.name),
          ),
        )
        setSelectedFormat(layerFormats.formats[0])
      }
    },
    [layerOptions],
  )

  const onLayerFormatChange = useCallback((e) => {
    setSelectedFormat(e.target.value)
  }, [])

  return (
    <ModalContainer style={{ display: 'flex', flexDirection: 'column' }}>
      <ModalHeader>
        {t('export_harvest_data', { harvest_name: data.title })}
      </ModalHeader>
      <StyledContent>
        {!activeStatus ? (
          <LayerGroup.Items>
            {
              //@ts-ignore
              <LayerGroup.Item>
                <Box
                  sx={{
                    borderLeft: '8px solid #ff00006b !important',
                    background: '#ff00001a !important',
                    color: 'red !important',
                    fontWeight: 'bold !important',
                  }}
                  title={t('no_subscriptions_formats_not_available')}
                  onClick={() => {
                    navigate('/admin/subscription')
                    dispatch(closeModal())
                  }}
                >
                  <span> {t('renew_subscription_formats')}</span>
                  {
                    //@ts-ignore
                    <SubscriptionsIcon
                      size="small"
                      style={{
                        color: '#B2023B',
                        height: '0.7em',
                        verticalAlign: 'sub',
                      }}
                      title={t('no_subscriptions_formats_not_available')}
                    />
                  }
                </Box>
              </LayerGroup.Item>
            }
          </LayerGroup.Items>
        ) : null}
        {awaitingDownload ? (
          <AwaitingLayerDownload />
        ) : (
          <>
            {layerOptions.length === 0 ? null : (
              <FormControlLabel
                label={t('select_output_type')}
                labelPlacement="start"
                control={
                  <Select
                    value={selectedLayer?.name}
                    onChange={onLayerOptionChange}
                  >
                    {layerOptions.map((o) => (
                      <MenuItem key={o.name} value={o.name}>
                        {o.translation}
                      </MenuItem>
                    ))}
                  </Select>
                }
              />
            )}
            {currentFormats.length === 0 ? null : (
              <FormControlLabel
                label={t('select_format')}
                labelPlacement="start"
                control={
                  <Select value={selectedFormat} onChange={onLayerFormatChange}>
                    {currentFormats.map((o) => {
                      const isDisabled = o.isPremium && !activeStatus

                      return (
                        <MenuItem
                          key={o.name}
                          value={o.value}
                          disabled={isDisabled}
                        >
                          {t(o.label)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                }
              />
            )}
          </>
        )}
      </StyledContent>
      <ModalActions>
        {awaitingDownload ? null : (
          <>
            <Button variant="contained" color="inherit" onClick={onClose}>
              {t('close')}
            </Button>

            <Button variant="contained" onClick={onExportLayer}>
              {t('export')}
            </Button>
          </>
        )}
      </ModalActions>
    </ModalContainer>
  )
}

const StyledContent = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  '& > *:not(:last-child)': {
    marginBottom: '1em',
  },
})
