import { styled } from '@mui/system'
import useCenterCoordinate from 'hooks/use-center-coordinate'
import useZoom from 'hooks/use-zoom'
import { toLonLat } from 'ol/proj'
import Panel from 'components/panel'
import { useTranslation } from 'react-i18next'

export default function ViewInfo() {
  const coordinate = useCenterCoordinate()
  const zoom = useZoom()
  const { t } = useTranslation()

  const c = coordinate ? toLonLat(coordinate).join(', ') : null

  return (
    <Panel title="View Info">
      <StyledInfoLine>
        <em>{t('center')}: </em>
        {c}
      </StyledInfoLine>
      <StyledInfoLine>
        <em>{t('zoom')}: </em>
        {zoom}
      </StyledInfoLine>
    </Panel>
  )
}

const StyledInfoLine = styled('div')({
  fontSize: '10px',
  '& > em': {
    fontWeight: 'bold',
  },
})
