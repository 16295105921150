import Backdrop from '@mui/material/Backdrop'
import ModalInternal from './modal-internal'

const MODAL_ROOT = document.getElementById('modal-el')

export default function Modal(props) {
  const { component, onClose, title } = props

  const modalEl = (
    <Backdrop open={true} sx={{ color: '#000D18' }}>
      <ModalInternal onClose={onClose} title={title}>
        {component}
      </ModalInternal>
    </Backdrop>
  )

  return ReactDOM.createPortal(modalEl, MODAL_ROOT)
}

Modal.propTypes = {
  title: PropTypes.string,
  component: PropTypes.node,
  onClose: PropTypes.func,
}
