import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { styled } from '@mui/system'
import { NavLink } from 'react-router'

export default function CommonSidebarLink(props) {
  return (
    <StyledListItem disablePadding component={NavLink} {...props}>
      {props.label && (
        <ListItemButton>
          {props.icon && (
            <ListItemIcon sx={{ minWidth: '24px', marginRight: '12px' }}>
              {props.icon}
            </ListItemIcon>
          )}
          <ListItemText primary={props.label} sx={{ fontWeight: 'bolder' }} />
        </ListItemButton>
      )}
      {props.children}
    </StyledListItem>
  )
}

const StyledListItem = styled(ListItem)({
  '&.active': {
    backgroundColor: '#c6def8',
    '& .MuiTypography-root': {
      color: '#000',
      fontWeight: 500,
    },
    '& .MuiListItemIcon-root svg path': {
      fill: '#3475e0',
    },
  },
  '& .MuiTypography-root': {
    color: '#000',
    fontWeight: 500,
  },
})
