import { V, H, VItem } from 'components/layout'
import { colors } from 'components/foundation'
import React from 'react'

export default function InfoGroup({ children }) {
  return (
    <H>
      {React.Children.toArray(children).map((child, idx) => (
        <H.Item key={idx}>{child}</H.Item>
      ))}
    </H>
  )
}

InfoGroup.Section = function Section({ children }) {
  return (
    <V
      style={{
        paddingRight: '8px',
        marginRight: '4px',
        borderRight: `2px solid ${colors.brand}`,
      }}
    >
      {React.Children.toArray(children).map((child, idx) => (
        <VItem key={idx}>{child}</VItem>
      ))}
    </V>
  )
}
