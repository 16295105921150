//@ts-expect-error
import ModalContainer from 'components/modal/modal-container'
//@ts-expect-error
import ModalHeader from 'components/modal/modal-header'
//@ts-expect-error
import ModalActions from 'components/modal/modal-actions'
import { Button, Typography } from '@mui/material'
//@ts-expect-error
import { closeModal } from 'actions/app'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCallback, useState, ReactElement, useEffect } from 'react'
//@ts-expect-error
import { requestIntegrationCode } from 'api/integration'
//@ts-expect-error
import { AppState } from 'state/index'
//@ts-expect-error
import { IntegrationData } from 'state/integrations'

export type AuthDialogProps = {
  name: string
}

export default function ReAuthenticate(props: AuthDialogProps) {
  const dispatch = useDispatch()
  const { name } = props
  const baseURL: string = window.location.origin
  const availableIntegrations = useSelector(
    (state: AppState) => state.integrations.availableIntegrations,
  )

  const { t } = useTranslation()

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const handleLogin = useCallback(() => {
    const integration: IntegrationData | undefined = availableIntegrations.find(
      (i) => i.name === name,
    )
    if (integration) {
      const data: any = { ...integration }
      data.attempt = 1
      data.doOnboarding = true
      localStorage.setItem('integrationLoginAttempt', JSON.stringify(data))
      requestIntegrationCode(
        data.authorizationUrl,
        data.scopes,
        data.clientId,
        baseURL,
        data.tokenUrl,
      )
    }
  }, [availableIntegrations])

  return (
    <ModalContainer>
      <ModalHeader>{t('authentication_status')}</ModalHeader>

      <Typography>
        {t('authentication_expired', { integration: t(name) })}
      </Typography>
      <ModalActions>
        <Button variant="contained" color="secondary" onClick={closePopup}>
          {t('cancel')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleLogin}>
          {t('login')}
        </Button>
      </ModalActions>
    </ModalContainer>
  )
}
