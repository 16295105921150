import axios from 'axios'

const WAIT_LIKE_FOREVER = 10000000
const PROD_WAIT = 90000
import store from 'store'

import { showModal } from 'actions/app'

import Alert from '@mui/material/Alert'
import i18next from 'i18next'
import { clearIntegrationData } from 'common/integrationUtils'

const AlertComponent = function (errorResponse) {
  if (errorResponse)
    store.dispatch(
      showModal(
        <Alert severity="error">
          {errorResponse?.reason ? errorResponse.reason : errorResponse}
        </Alert>,
        {
          title: i18next.t('alert'),
        },
      ),
    )
}

class Http {
  constructor() {
    this._defaultProps = {}
    this._client = undefined
  }

  init(props) {
    const { baseURL = 'http://localhost' } = props
    const { logout } = props.auth0

    this._defaultProps = {
      baseURL,
      timeout: IS_PRODUCTION ? PROD_WAIT : WAIT_LIKE_FOREVER,
    }

    this._client = axios.create(this._defaultProps)

    this._client.interceptors.response.use(
      function (response) {
        return response
      },
      function (error) {
        const status = error?.response?.status
        const redirectUrl = error?.response?.data?.redirectUrl
        if (status === 421 && redirectUrl) {
          //load provided app, auth seems to persist
          window.location.href = redirectUrl
        } else if (error?.response?.data === 'jwt expired') {
          clearIntegrationData()
          //Remove the CFF localStorage items
          localStorage.removeItem('selectedClient')
          localStorage.removeItem('selectedFarms')
          //End
          logout({ returnTo: window.location.origin })
        } else {
          return Promise.reject(error)
        }
      },
    )
  }

  withTokens(accessToken) {
    if (!accessToken) throw new Error('access token required!')

    this._client.defaults.headers.common['Authorization'] =
      'Bearer ' + accessToken
    this._client.defaults.headers.common['Accept-Language'] = navigator.language
  }

  get(url, config) {
    return this._client.get(url, config).catch((err) => {
      AlertComponent(
        err.response?.data ? err.response.data : i18next.t('generic_error'),
      )
      return Promise.reject(err)
    })
  }

  post(url, payload, config) {
    return this._client.post(url, payload, config).catch((err) => {
      AlertComponent(
        err.response?.data ? err.response.data : i18next.t('generic_error'),
      )
      return Promise.reject(err)
    })
  }

  put(url, payload, config) {
    return this._client.put(url, payload, config).catch((err) => {
      AlertComponent(
        err.response?.data ? err.response.data : i18next.t('generic_error'),
      )
      return Promise.reject(err)
    })
  }

  delete(url, config) {
    return this._client.delete(url, config).catch((err) => {
      AlertComponent(
        err.response?.data ? err.response.data : i18next.t('generic_error'),
      )
      return Promise.reject(err)
    })
  }
}

export default new Http()
