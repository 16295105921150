import { styled } from '@mui/system'
import { Box, Grid2, TextField, FormControlLabel, Button } from '@mui/material'
import logo from 'images/farmtrx-logo.png'
import welcomeScreenImg from 'images/welcome-screen-harvester.png'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { closeModal, shouldShowDashboard } from 'actions/app'

export default function WelcomeScreen() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const [farmName, setFarmName] = useState('')
  const permissions = useSelector((state) => state.account.permissions)
  const [showPermissions, setShowPermissions] = useState({})

  useEffect(() => {
    setShowPermissions({
      skipWizard: permissions.includes('tenant:list'),
    })
  }, [permissions])

  const handleSkipWizard = () => {
    dispatch(closeModal())

    dispatch(shouldShowDashboard())
    localStorage.setItem('wizardActiveStep', null)
  }

  const navigateToRegWizardStepper = useCallback(() => {
    navigate('/wizardStepper', { state: { farmName } })
  })

  const handleInputChange = useCallback((e) => {
    setFarmName(e.target.value)
  })

  const farmId = useSelector((state) => state.farm.id)

  useEffect(() => {
    if (farmId) {
      navigate('/wizardStepper')
    }
  }, [farmId])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ sm: 7, md: 6, lg: 6 }}>
          <Box mx={3} my={4}>
            <Box mx={10} my={12}>
              <img width="180" height="30" src={logo} />
            </Box>
            <Box mx={10} my={2}>
              <Grid2 container spacing={2}>
                <Grid2 size={{ md: 12, lg: 12 }}>
                  <h1>
                    <b>{t('welcome')}</b>
                  </h1>
                </Grid2>
                <Grid2 size={{ md: 12, lg: 12 }}>
                  <FormControlLabel
                    sx={{ alignItems: 'flex-start', width: '100%' }}
                    control={
                      <TextField
                        style={{ width: '100%', marginTop: '16px' }}
                        name={t('farm_name')}
                        variant="outlined"
                        size="small"
                        placeholder={t('enter_farm_name_ph')}
                        value={farmName}
                        onChange={(e) => handleInputChange(e)}
                      ></TextField>
                    }
                    label={t('enter_farm_name_label')}
                    labelPlacement="top"
                  />
                </Grid2>
                <Grid2 size={{ md: 12, lg: 12 }}>
                  <Box my={18}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          !farmName || farmName == '' || farmName == null
                        }
                        sx={{ borderRadius: '25px' }}
                        fullWidth
                        onClick={navigateToRegWizardStepper}
                      >
                        {t('continue_setup')}
                      </Button>
                      {showPermissions.skipWizard ? (
                        <Button color="primary" onClick={handleSkipWizard}>
                          {t('skip')}
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Grid2>
              </Grid2>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ sm: 5, md: 6, lg: 6 }}>
          <Box>
            <StyledImg src={welcomeScreenImg} />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  )
}

const StyledImg = styled('img')({
  width: '49vw',
  height: '99vh',
})
