import { styled } from '@mui/system'
import { defaultShadow, colors } from 'components/foundation'

export const FormGroup = styled('div')((props) => ({
  '& + &': {
    marginTop: '8px',
  },
  '& label': {
    display: 'block',
    marginBottom: '5px',
  },
  '& label > span.required': {
    paddingLeft: '5px',
    fontSize: '60%',
    color: props.error ? colors.danger : colors.greyLight,
  },
  '& > input, & > select, & > input:focus': {
    borderColor: props.error ? colors.danger : undefined,
  },
}))

export const FormControl = styled('input')({
  display: 'block',
  background: 'white',
  width: '400px',
  paddingTop: '4px',
  paddingBottom: '4px',
  paddingLeft: '8px',
  lineHeight: '1.42857143',
  color: colors.text,
  borderRadius: '4px',
  outline: 0,
  border: `1px solid ${colors.greyLight}`,
  '&.disabled': {
    backgroundColor: colors.greyLighter,
  },
  '&:focus': {
    border: `1px solid ${colors.brandTint}`,
    ...defaultShadow,
  },
})

export const FormTextArea = styled('textarea')(FormControl)

export const FormText = styled('input')({
  ...FormControl,
  width: 'auto',
})
