import { Menu, MenuItem, MenuList, Typography } from '@mui/material'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-expect-error
import { exportLayer } from 'api/field'
import { useDispatch, useSelector } from 'react-redux'
//@ts-expect-error
import { downloadingLayer } from 'actions/field'
//@ts-expect-error
import AwaitingLayerDownload from 'components/layer-download/awaiting-download'
//@ts-expect-error
import { getTranslatedName } from 'common/misc'

//Icons
import {
  FarmtrxIconEditCropCalibration,
  FarmtrxIconUpdateTotalYield as EditTotalYield,
  FarmtrxIconEditTimeDelay as EditTimeDelay,
  FarmtrxIconUpdateTestWeight as UpdateTestWeight,
  FarmtrxIconEditSensorCalibration as EditSensorCalibration,
  FarmtrxIconHeader,
  FarmtrxIconRefresh,
  FarmtrxIconExportHarvest as CloudDownloadIcon,
  FarmtrxIconCrop,
  FarmtrxIconMinus,
} from '@troo/farmtrx-icons'

//Types
//@ts-expect-error
import { HarvestModel } from 'state/harvest'
//@ts-expect-error
import { HarvestPermissions } from './harvest-panel'
//@ts-expect-error
import { AppState } from 'state/index'
//@ts-expect-error
import { FieldModel } from 'state/field'
//@ts-expect-error
import { showModal } from 'actions/app'

export type HarvestMenuProps = {
  anchorEl: null | HTMLElement
  permissions: HarvestPermissions
  onClickHandler: Function
  open: boolean
  harvest: HarvestModel
  showExportModal: Function
  onClose: Function
}
const iconSize = 16

const iconStyle = { margin: 4, height: iconSize, width: iconSize }
const textStyle = { fontSize: '.75rem' }

export default function HarvestSettingsPanel(props: HarvestMenuProps) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const field = useSelector((state: AppState) => state.field.singleton)
  const fields: FieldModel[] = useSelector(
    (state: AppState) => state.field.collection,
  )
  //change harvest year was chosen as it's one of the permissions only a FarmTRX Admin will have
  //other one is download ubj. This combined with individual permissions will determine what is shown,
  //If this is true, and the user has the relevant permission the item is shown. This is not checked for
  //export harvest, as it's always shown, and changeHarvestYear or downloadUbj as they are shown based on
  //the user being a FarmTRX Admin. The permissions are checked before each menu items is added to the menu.
  const enableControls =
    props.harvest.cropYear >= new Date().getFullYear() - 1 ||
    props.permissions.changeHarvestYear
  const handleOnClick = useCallback(
    async (name) => {
      if (name !== 'export_harvest') {
        props.onClickHandler(name, props.harvest)
      } else {
        await dispatch(
          downloadingLayer({
            url: null,
            fileName: '',
          }),
        )
        props.showExportModal(props.harvest)
      }
      props.onClose()
    },
    [props.harvest],
  )

  const onDownloadUbj = useCallback(async () => {
    let fieldForHarvest: FieldModel | null | undefined = null
    if (field?.id === props.harvest.fieldId) {
      fieldForHarvest = field
    } else {
      fieldForHarvest = fields.find((f) => {
        return f.id === props.harvest.fieldId
      })
    }
    if (fieldForHarvest) {
      await dispatch(
        downloadingLayer({
          url: null,
          fileName: '',
        }),
      )
      const data = {
        tenant_id: props.harvest.tenantId,
        export_type: 'ubj',
        field_name: fieldForHarvest.name,
        year: props.harvest.cropYear,
      }
      dispatch(exportLayer(props.harvest.id, data))
      props.onClose()
      dispatch(
        showModal(<AwaitingLayerDownload />, {
          title: `Downloading UBJ: ${
            props.harvest.cropYear
          }-${getTranslatedName(props.harvest.crop.name)}`,
        }),
      )
    }
  }, [field])

  return (
    <Menu
      anchorEl={props.anchorEl}
      open={props.open}
      anchorOrigin={{ vertical: -12, horizontal: 50 }}
      id="harvest-settings"
      MenuListProps={{ 'aria-labelledby': 'harvest-settings-button' }}
      onClose={() => props.onClose(null)}
      disableEnforceFocus={true}
      closeAfterTransition={false}
    >
      <MenuList style={{ outline: 0 }}>
        {props.permissions.setHeader && enableControls && (
          <MenuItem
            id="set_header"
            onClick={() => {
              handleOnClick('set_header')
            }}
          >
            <FarmtrxIconHeader style={iconStyle} />
            <Typography sx={textStyle}>
              {t('set_header').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editCropType && enableControls && (
          <MenuItem
            id="edit_crop_type"
            onClick={(e) => handleOnClick('edit_crop_type')}
          >
            <FarmtrxIconCrop style={iconStyle} />
            <Typography sx={textStyle}>
              {t('edit_crop_type').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.updateTotalYield && enableControls && (
          <MenuItem id="change_ty" onClick={(e) => handleOnClick('change_ty')}>
            <EditTotalYield style={iconStyle} />
            <Typography sx={textStyle}>
              {t('change_ty').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.updateTestWeight && enableControls && (
          <MenuItem id="change_tw" onClick={() => handleOnClick('change_tw')}>
            <UpdateTestWeight style={iconStyle} />
            <Typography sx={textStyle}>
              {t('change_tw').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editSensorCalibration && enableControls && (
          <MenuItem
            id="edit_sensor_calibration"
            onClick={(e) => handleOnClick('edit_sensor_calibration')}
          >
            <EditSensorCalibration style={iconStyle} />
            <Typography style={{ fontSize: '.75rem' }}>
              {t('edit_sensor_calibration').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editCropCalibration && enableControls && (
          <MenuItem
            id="edit_crop_calibration"
            onClick={() => handleOnClick('edit_crop_calibration')}
          >
            <FarmtrxIconEditCropCalibration style={iconStyle} />
            <Typography sx={textStyle}>
              {t('edit_crop_calibration').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.exportHarvest && (
          <MenuItem
            id="export_harvest"
            onClick={() => handleOnClick('export_harvest')}
          >
            <CloudDownloadIcon color="primary" style={iconStyle} />
            <Typography sx={textStyle}>
              {t('export_harvest').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.editTimeDelay && enableControls && (
          <MenuItem
            id="edit_time_delay"
            onClick={(e) => handleOnClick('edit_time_delay')}
          >
            <EditTimeDelay style={iconStyle} />
            <Typography sx={textStyle}>
              {t('edit_time_delay').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
        {props.permissions.refreshHarvest && enableControls && (
          <MenuItem id="refresh" onClick={() => handleOnClick('refresh')}>
            <FarmtrxIconRefresh style={iconStyle} />
            <Typography sx={textStyle}>{t('refresh').toUpperCase()}</Typography>
          </MenuItem>
        )}
        {props.permissions.downloadUbj && (
          <MenuItem id="download_ubj" onClick={() => onDownloadUbj()}>
            <CloudDownloadIcon color="primary" style={iconStyle} />
            <Typography sx={textStyle}>{'DOWNLOAD UBJS'}</Typography>
          </MenuItem>
        )}
        {props.permissions.changeHarvestYear && (
          <MenuItem
            id="change_harvest_year"
            onClick={(e) => handleOnClick('change_harvest_year')}
          >
            <FarmtrxIconMinus style={iconStyle} />
            <Typography sx={textStyle}>
              {t('change_harvest_year').toUpperCase()}
            </Typography>
          </MenuItem>
        )}
      </MenuList>
    </Menu>
  )
}
