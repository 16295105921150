import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS'
import {
  pointInfoUpdate,
  showLayerLegend,
  showLayerLegendA,
  showLayerLegendB,
} from 'actions/map'
import useMap from 'hooks/use-map'
import { get } from 'lodash-es'
import { fetchAreaInfo, fetchPointInfo } from 'api/field'
import { requestYieldUnit, useLegacySLD } from 'common/misc'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'

export default function MapWMSLayer(props) {
  const {
    layersName = '',
    bbox,
    legendUrl = '',
    harvestId = '',
    mapRef = 'map',
    layerZIndex,
    wmsLayers,
  } = props
  const dispatch = useDispatch()

  const coords = useSelector((state) => get(state, 'map.pointInfoReq'))
  const extent = useSelector((state) => get(state, 'map.areaInfoReq'))
  const prefs = useSelector((state) => state.account.singleton.prefs)
  const requestYield = requestYieldUnit(prefs?.units?.yieldunit_in)
  const harvest = useSelector((state) => get(state, 'harvest.current'))
  const [legendName, setLegendName] = useState(legendUrl)
  const [params, setParams] = useState({
    tiled: true,
    SLD: legendUrl,
    WIDTH: 600,
    HEIGHT: 600,
    BBOX: bbox,
    SRS: 'EPSG:4326',
    CQL_FILTER: encodeURI(`harvest_id='${harvestId}'`),
    queryable: false,
  })

  useEffect(() => {
    const lpVersion = harvest?.stats?.lp_version
    if (lpVersion) {
      const layer = wmsLayers.find((l) => l.name === layersName)
      const legendName = layer?.legendUrl
      const queryable = layer?.queryable || false

      if (layersName === 'swath') {
        if (legendName) {
          setLegendName(legendName)
          setParams({
            tiled: true,
            SLD: legendName,
            SRS: 'EPSG:4326',
            CQL_FILTER: encodeURI(`harvest_id='${harvestId}'`),
            queryable: queryable,
          })
          dispatch(pointInfoUpdate())
        }
      } else {
        setParams({
          tiled: true,
          SLD: legendUrl,
          SRS: 'EPSG:4326',
          CQL_FILTER: encodeURI(`harvest_id='${harvestId}'`),
          queryable: queryable,
        })
        dispatch(pointInfoUpdate())
      }

      if (isMetric()) {
        setLegendName(legendUrl.replace('.sld', '_hct.sld'))
        setParams({
          tiled: true,
          SLD: legendName,
          SRS: 'EPSG:4326',
          CQL_FILTER: encodeURI(`harvest_id='${harvestId}'`),
          queryable: queryable,
        })
        dispatch(pointInfoUpdate())
      }
    }

    function isMetric() {
      return (
        useLegacySLD(lpVersion) &&
        !(
          prefs?.units?.yieldunit_in == 'bu_ac' ||
          prefs?.units?.yieldunit_in == 'lbs_acre'
        ) &&
        layersName !== 'trend_moisture'
      )
    }
  }, [harvest, prefs])

  let map = useMap(mapRef)

  const [source, setSource] = useState()
  const [layer, setLayer] = useState()

  useEffect(() => {
    const _source = new TileWMS({
      url: `${GEOSERVER_URL}/ows`,
      serverType: 'geoserver',
      params: params,
      transition: 0,
    })

    const legend = {
      name: layersName,
      url: _source.getLegendUrl(null, { LAYER: layersName, SLD: legendName }),
      harvest: harvestId,
    }
    if (mapRef === 'map') {
      dispatch(showLayerLegend(legend))
    } else if (mapRef === 'map_a') {
      dispatch(showLayerLegendA(legend))
    } else {
      dispatch(showLayerLegendB(legend))
    }

    setSource(_source)
  }, [params])

  useEffect(() => {
    if (map && source) {
      const _layer = new TileLayer({ source, zIndex: layerZIndex })
      setLayer(_layer)
    }
  }, [map, source])

  useEffect(() => {
    if (layer) {
      let updatedLayers = []
      const layersArray = map.getLayers().getArray()
      for (let i = 0; i < layersArray.length; i++) {
        if (layerZIndex <= layersArray[i].getZIndex()) {
          updatedLayers.push(layersArray[i])
        }
      }
      map.addLayer(layer)
      //This is a workaround because z-index doesn't seem to work
      //reliably if the user adds a tile layer with lower z index after
      //one with a higher z index.
      if (updatedLayers.length > 0) {
        for (let i = 0; i < updatedLayers.length; i++) {
          map.removeLayer(updatedLayers[i])
          map.addLayer(updatedLayers[i])
        }
      }
    }

    return () => {
      map.removeLayer(layer)
    }
  }, [layer])

  useEffect(() => {
    if (
      source &&
      map &&
      source.params_?.queryable &&
      coords &&
      coords[0] !== 0
    ) {
      const viewResolution = map.getView().getResolution()
      const zoomResolution = map.getView().getResolutionForZoom(viewResolution)
      // tolerance is somewhat arbitrarily set at 3, further testing can improve it
      const tolerance = 3
      const minX = coords[0] - tolerance * 0.5 * zoomResolution
      const maxX = coords[0] + tolerance * 0.5 * zoomResolution
      const minY = coords[1] - tolerance * 0.5 * zoomResolution
      const maxY = coords[1] + tolerance * 0.5 * zoomResolution
      const url = source.getFeatureInfoUrl(
        coords,
        viewResolution,
        'EPSG:3857',
        {
          INFO_FORMAT: 'application/json',
          BBOX: `${minX}, ${minY}, ${maxX}, ${maxY}`,
          CQL_FILTER: encodeURI(`harvest_id='${harvestId}'`),
        },
      )
      dispatch(fetchPointInfo(url))
    }
  }, [map, source, coords])

  useEffect(() => {
    if (extent && extent[0] !== 0 && layersName.length !== 0) {
      const url = `${GEOSERVER_URL}/wfs?service=WFS&version=2.0.0&request=GetFeature&outputFormat=application/json&bbox=${extent.join(
        ',',
      )},EPSG:3857&typeName=${layersName}&count=100&propertyName=${requestYield}`
      dispatch(fetchAreaInfo(url))
    }
  }, [extent, layersName])

  return null
}
