import { createField, editField } from 'api/field'
import { FormControlLabel, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import { closeModal } from 'actions/app'
import { isEmpty } from 'lodash-es'
import Alert from '@mui/material/Alert'
import { get } from 'lodash-es'
import ModalTool from 'components/modal/modal-tool'

export default function FieldForm(props) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { defaultClient, defaultFarm, fieldData } = props

  const cffEnabled = useSelector((state) =>
    get(state, 'account.singleton.prefs.cff.enabled'),
  )

  const firstUpdate = useRef(true)

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  })
  const selectedFarms = useSelector((state) => state.farm.selectedFarms)
  const selectedClient = useSelector((state) => state?.client?.singleton)
  const farms = useSelector((state) => state.farm.collection || [])
  const clients = useSelector((state) => state?.client?.collection || [])

  const fields = useSelector((state) => state?.field?.collection || [])

  const [farm, setFarm] = useState(selectedFarms[0])
  const [client, setClient] = useState({})
  const [fieldName, setFieldName] = useState(fieldData?.name || '')
  const [filteredFarms, setFilteredFarms] = useState([])

  const [fieldExists, setFieldExists] = useState(false)

  useEffect(() => {
    const _defaultFarm = defaultFarm || selectedFarms[0]?.id
    const _defaultClient = defaultClient || selectedClient?.id || clients[0]?.id
    const _farm = farms.find((f) => f.id === _defaultFarm)
    const _client = clients.find((c) => c.id === _defaultClient)
    if (_client?.farms?.length) {
      const _filteredFarms = _client?.farms
      setFilteredFarms(_filteredFarms)
      setFarm(_filteredFarms[0].id)
    }
    if (_farm?.clientId === _defaultClient || _defaultClient == null) {
      setFarm(_farm?.['id'])
    }
    setClient(_client?.['id'])
  }, [farms, clients])

  const onSubmit = useCallback(() => {
    if (fieldData) {
      dispatch(editField(fieldData.id, { name: fieldName, farmId: farm }))
    } else {
      const data = {
        name: fieldName,
        farmId: farm,
      }
      dispatch(createField(data))
    }
  }, [fieldName, farm])

  const onChange = useCallback(
    (e) => {
      checkIfFieldExists(client, farm, e.target.value)
      setFieldName(e.target.value)
    },
    [fieldName, client, farm],
  )

  const onChangeFarm = useCallback(
    (e) => {
      setFarm(e.target.value)
      checkIfFieldExists(client, e.target.value, fieldName)
    },
    [client, farm],
  )

  const onChangeClient = useCallback((e) => {
    const _clientId = parseInt(e.target.value)
    let farm
    const _filteredFarms = farms.filter((f) => f.clientId === _clientId)
    if (_filteredFarms.length) {
      setFilteredFarms(_filteredFarms)
      farm = _filteredFarms[0].id
      setFarm(farm)
    } else {
      setFilteredFarms([])
    }
    checkIfFieldExists(e.target.value, farm, fieldName)
    setClient(_clientId)
  }, [])

  const checkIfFieldExists = (client, farm, fieldName) => {
    //To fix the error, seeing the item exists message on edit item
    firstUpdate.current = false
    const field = fields.filter((f) => f.farmId == farm && f.name === fieldName)
    if (field.length) {
      setFieldExists(true)
    } else {
      setFieldExists(false)
    }
  }

  return (
    <ModalTool
      header={isEmpty(fieldData) ? t('create_field') : t('edit_field')}
      content={
        <>
          {cffEnabled ? (
            <>
              {clients && clients.length ? (
                <FormControlLabel
                  control={
                    <TextField
                      sx={{ width: '25ch', padding: '10px' }}
                      select
                      name="client"
                      value={client}
                      onChange={onChangeClient}
                      slotProps={{ select: { native: true } }}
                      variant="outlined"
                      size="small"
                    >
                      {clients.map((innerItem) => (
                        <option value={innerItem.id} key={innerItem.id}>
                          {innerItem.name}
                        </option>
                      ))}
                    </TextField>
                  }
                  label={t('client_name')}
                  labelPlacement="start"
                />
              ) : null}

              {filteredFarms.length ? (
                <>
                  <FormControlLabel
                    control={
                      <TextField
                        sx={{ width: '25ch', padding: '10px' }}
                        select
                        name="farm"
                        value={farm}
                        onChange={onChangeFarm}
                        slotProps={{ select: { native: true } }}
                        variant="outlined"
                        size="small"
                      >
                        {filteredFarms.map((innerItem) => (
                          <option value={innerItem.id} key={innerItem.id}>
                            {innerItem.name}
                          </option>
                        ))}
                      </TextField>
                    }
                    label={t('farm')}
                    labelPlacement="start"
                  />
                  {fieldExists ? (
                    <Alert severity="warning">
                      {t('item_name_exists_for_selection', {
                        item: t('field'),
                        selectedItem: t('farm'),
                      })}
                    </Alert>
                  ) : null}
                  <FormControlLabel
                    control={
                      <TextField
                        id="fieldName"
                        sx={{ width: '25ch', padding: '10px' }}
                        value={fieldName}
                        name="fieldName"
                        variant="outlined"
                        size="small"
                        error={fieldName === '' || fieldName <= 0}
                        helperText={fieldName === '' ? t('required') : ''}
                        onChange={onChange}
                      />
                    }
                    label={t('name')}
                    labelPlacement="start"
                  />
                </>
              ) : (
                <Alert severity="error">{t('no_farms_to_client')} </Alert>
              )}
            </>
          ) : (
            <FormControlLabel
              control={
                <TextField
                  id="fieldName"
                  sx={{ width: '25ch', padding: '10px' }}
                  value={fieldName}
                  name="fieldName"
                  variant="outlined"
                  size="small"
                  error={fieldName === '' || fieldName <= 0}
                  helperText={fieldName === '' ? t('required') : ''}
                  onChange={onChange}
                />
              }
              label={t('name')}
              labelPlacement="start"
            />
          )}
        </>
      }
      onClose={closePopup}
      onApply={onSubmit}
      disabled={
        fieldName === '' ||
        fieldName <= 0 ||
        !filteredFarms.length ||
        fieldExists ||
        firstUpdate?.current
      }
    />
  )
}
