import {
  Button,
  FormControlLabel,
  TextField,
  FormControl,
  FormGroup,
  Box,
} from '@mui/material'
import {
  ArrowForward as ArrowForwardIcon,
  SubscriptionsOutlined as SubscriptionsIcon,
} from '@mui/icons-material'
import { Outlet } from 'react-router'
import { CommonSidebar } from 'components/common-sidebar'
import { fetchDeletedFields } from 'api/field'
import { useCallback, useEffect } from 'react'
import {
  getTranslatedName,
  getYearsWithHarvests,
  isSubscriptionValid,
} from 'common/misc'
import {
  getHarvestsByYear,
  getHarvestsByFarm,
  getHarvestsByClient,
} from 'api/harvest'
import HarvestReport from './harvest-report'

export default function Reports(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const layerOptions = [
    {
      key: 'corrected_yield',
      name: `${t('corrected_yield_map')}`,
    },
    {
      key: 'zones_yield',
      name: `${t('classified_yield_map')}`,
    },
    {
      key: 'trend_yield',
      name: `${t('yield_map_trend')}`,
    },
    {
      key: 'swath',
      name: `${t('swath_map')}`,
    },
  ]

  const harvestYears = useSelector((state) => state.harvest.years)

  const yearsWithHarvests = useMemo(
    () => getYearsWithHarvests(harvestYears),
    [harvestYears],
  )
  const harvestsWithYears = useSelector((state) => state.harvest.byYear)

  const [selectedYear, setSelectedYear] = useState(
    yearsWithHarvests[0] ?? props.defaultYear,
  )

  const fields = useSelector((state) => state.field['collection'])
  const crops = useSelector((state) => state.crop['cropConstantsCollection'])
  const fetchedClients = useSelector((state) => state.client.collection)
  const fetchedFarms = useSelector((state) => state.farm.collection)

  const clients = [{ id: -1, name: t('all') }, ...fetchedClients]
  const [farms, setFarms] = useState([{ id: -1, name: t('all') }])

  const [allFields, setAllFields] = useState(null)
  const [harvestsWithMaps, setHarvestsWithMaps] = useState([])

  const [selectedHarvest, setSelectedHarvest] = useState({})

  const [selectedLayer, setSelectedLayerType] = useState(layerOptions[0])

  const [harvestToReport, setHarvestToReport] = useState({})
  const [layerToReport, setLayerToReport] = useState()

  const cffPreferences = useSelector(
    (state) => state.account.singleton.prefs?.cff,
  )
  const [selectedClient, setSelectedClient] = useState(undefined)
  const [selectedFarm, setSelectedFarm] = useState(undefined)

  const [generateClicked, setGenerateClicked] = useState(false)

  const token = useSelector((state) => state.account['token'])

  useEffect(() => {
    if (yearsWithHarvests.length > 0) {
      onYearChange({ target: { value: yearsWithHarvests[0] } })
    }
  }, [yearsWithHarvests])

  useEffect(() => {
    if (token) {
      if (cffPreferences && cffPreferences.enabled && cffPreferences.client) {
        setSelectedClient(cffPreferences.client)
        dispatch(getHarvestsByClient(cffPreferences.client, selectedYear))
      } else if (
        yearsWithHarvests &&
        yearsWithHarvests.length &&
        !selectedClient &&
        !selectedFarm
      ) {
        setSelectedYear(yearsWithHarvests[0])
        dispatch(getHarvestsByYear(yearsWithHarvests[0]))
      } else {
        let currentYear = new Date().getFullYear()
        setSelectedYear(currentYear)
        if (!selectedClient) {
          dispatch(getHarvestsByYear(currentYear))
        }
      }
    }
  }, [token, yearsWithHarvests, cffPreferences])

  useEffect(() => {
    if (token && fields) {
      fetchDeletedFields().then((deletedFields) => {
        setAllFields([...fields, ...deletedFields])
      })
    }
  }, [token, fields])

  useEffect(() => {
    if (fetchedClients && fetchedClients.length) {
      if (selectedClient) {
        const foundClient = fetchedClients.find(
          (client) => client.id == selectedClient,
        )
        if (foundClient && foundClient.farms) {
          setFarms([{ id: -1, name: t('all') }, ...foundClient.farms])
        }
      } else {
        setFarms([{ id: -1, name: t('all') }, ...fetchedFarms])
      }
    }
  }, [selectedClient, fetchedClients])

  const subscriptions = useSelector((state) => state.account.subscriptions)

  const [activeStatus, setActiveStatus] = useState(false)

  //Check if the user has active subscription
  useEffect(() => {
    /*Fix the Renew FarmTRX Premium message*/
    //Use current month and day with selected year as default for checking subscription
    //status. Using only the year can fail if the only valid subscription starts after
    //January 1, which is what just the year is treated as. If there is a valid harvest, use
    //the start and end date of the harvest for the check.
    let defaultHarvest = {}
    const date = new Date()
    const period = `${selectedYear}-${date.getMonth() + 1}-${date.getDay()}
    `
    let harvestStart = period
    let harvestEnd = period
    if (Object.keys(selectedHarvest).length) {
      defaultHarvest = Object.assign(defaultHarvest, selectedHarvest)
      harvestStart = defaultHarvest.startDate
      harvestEnd = defaultHarvest.endDate
    }
    setActiveStatus(
      isSubscriptionValid(subscriptions, harvestStart, harvestEnd),
    )
  }, [selectedHarvest, selectedYear, subscriptions])

  const onYearChange = useCallback((e) => {
    let year = e.target.value
    setSelectedYear(year)
    setGenerateClicked(false)

    if (selectedFarm) {
      dispatch(getHarvestsByFarm(selectedFarm, year))
    } else if (selectedClient) {
      dispatch(getHarvestsByClient(selectedClient, year))
    } else {
      dispatch(getHarvestsByYear(year))
    }
  })

  const onClientChange = useCallback(
    (e) => {
      let client = parseInt(e.target.value)
      setGenerateClicked(false)
      if (client === -1) {
        client = undefined
      }
      setSelectedClient(client)

      if (client) {
        dispatch(getHarvestsByClient(client, selectedYear))
      } else {
        dispatch(getHarvestsByYear(selectedYear))
      }
    },
    [selectedYear],
  )

  const onFarmChange = useCallback(
    (e) => {
      let farm = parseInt(e.target.value)
      setGenerateClicked(false)
      if (farm === -1) {
        farm = undefined
      }

      setSelectedFarm(farm)
      if (farm) {
        dispatch(getHarvestsByFarm(farm, selectedYear))
      } else if (selectedClient) {
        dispatch(getHarvestsByClient(selectedClient, selectedYear))
      } else {
        dispatch(getHarvestsByYear(selectedYear))
      }
    },
    [selectedYear],
  )

  useEffect(() => {
    if (allFields && harvestsWithYears && harvestsWithYears.length && crops) {
      let final = []
      harvestsWithYears.map((item) => {
        if (
          allFields?.find((f) => f.id === item.fieldId) != undefined &&
          crops?.find((c) => c.id === item.cropId) != undefined
        ) {
          let { fieldId, cropId } = item
          item.fieldName = allFields?.find((f) => f.id === fieldId).name
          item.cropName = crops?.find((c) => c.id === cropId).name
          if (
            item.status === 'LAYER_PROCESSING_COMPLETE' &&
            item.stats !== null
          ) {
            final = [...final, item]
          }
        }
        const sortedHarvests = final.sort((a, b) => {
          return a.fieldName.localeCompare(b.fieldName, undefined, {
            numeric: true,
            sensitivity: 'base',
          })
        })

        setHarvestsWithMaps(sortedHarvests)
      })
    } else {
      setHarvestsWithMaps([])
    }
  }, [harvestsWithYears, allFields, crops])

  useEffect(() => {
    if (harvestsWithMaps && harvestsWithMaps?.length) {
      setSelectedHarvest(harvestsWithMaps[0])
    } else {
      setSelectedHarvest([])
    }
    if (generateClicked) {
      setHarvestToReport(harvestsWithMaps?.length ? harvestsWithMaps[0] : [])
    }
  }, [harvestsWithMaps])

  //dropdown change
  const onHarvestChange = useCallback((val) => {
    setSelectedHarvest(JSON.parse(val.target.value))
  })

  //dropdown change
  const layerTypeChange = useCallback((val) => {
    setSelectedLayerType(JSON.parse(val.target.value))
  })

  const handleHarvestChange = (harvest) => {
    setHarvestToReport(harvest)
  }

  const handleLayerChange = (layer) => {
    setLayerToReport(layer)
  }

  const sendSelectedHarvest = useCallback(() => {
    handleHarvestChange(selectedHarvest)
    handleLayerChange(selectedLayer)
  }, [selectedLayer, selectedHarvest])

  return (
    <Box sx={{ display: 'flex' }} width={1}>
      <CommonSidebar>
        <Box mx={1}>
          <Box>
            <FormControl component="fieldset" fullWidth>
              <FormGroup aria-label="position">
                <Box my={1}>
                  <Box mx={1}>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'flex-start' }}
                      control={
                        <TextField
                          id="outlined-select-language-native"
                          select
                          name={t('reports')}
                          slotProps={{ select: { native: true } }}
                          variant="outlined"
                          size="small"
                        >
                          <option>{t('field_harvest')}</option>
                        </TextField>
                      }
                      label={t('reports')}
                      labelPlacement="top"
                    />
                  </Box>
                  <Box mx={1} my={2}>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'flex-start' }}
                      control={
                        <TextField
                          id="outlined-select-language-native"
                          select
                          onChange={onYearChange}
                          name={t('year')}
                          slotProps={{ select: { native: true } }}
                          variant="outlined"
                          size="small"
                          value={selectedYear}
                        >
                          {harvestYears && Object.keys(harvestYears) ? (
                            harvestYears.map((item) => (
                              <option
                                value={item.harvestYr}
                                key={item.harvestYr}
                              >
                                {item.harvestYr}
                              </option>
                            ))
                          ) : (
                            <></>
                          )}
                        </TextField>
                      }
                      label={t('year')}
                      labelPlacement="top"
                    />
                  </Box>
                  {cffPreferences?.enabled ? (
                    <>
                      <Box mx={1} my={2}>
                        <FormControlLabel
                          sx={{ display: 'flex', alignItems: 'flex-start' }}
                          control={
                            <TextField
                              id="outlined-select-language-native"
                              select
                              name={t('client')}
                              slotProps={{ select: { native: true } }}
                              variant="outlined"
                              size="small"
                              value={selectedClient}
                              onChange={onClientChange}
                            >
                              {clients.map((client) => {
                                return (
                                  <option value={client.id} key={client.id}>
                                    {client.name}
                                  </option>
                                )
                              })}
                            </TextField>
                          }
                          label={t('client')}
                          labelPlacement="top"
                        />
                      </Box>
                      <Box mx={1} my={2}>
                        <FormControlLabel
                          sx={{ display: 'flex', alignItems: 'flex-start' }}
                          control={
                            <TextField
                              id="outlined-select-language-native"
                              select
                              name={t('farm')}
                              slotProps={{ select: { native: true } }}
                              variant="outlined"
                              size="small"
                              value={selectedFarm}
                              onChange={onFarmChange}
                            >
                              {farms.map((farm) => {
                                return (
                                  <option value={farm.id} key={farm.id}>
                                    {farm.name}
                                  </option>
                                )
                              })}
                            </TextField>
                          }
                          label={t('farm')}
                          labelPlacement="top"
                        />
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}

                  <Box mx={1} my={2}>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'flex-start' }}
                      control={
                        <TextField
                          id="outlined-select-language-native"
                          select
                          onChange={onHarvestChange}
                          name={t('harvest')}
                          slotProps={{ select: { native: true } }}
                          variant="outlined"
                          size="small"
                        >
                          {harvestsWithMaps && harvestsWithMaps.length ? (
                            harvestsWithMaps.map((item) => (
                              <option
                                value={JSON.stringify(item)}
                                key={item.id}
                              >{`${item['fieldName']} - ${getTranslatedName(
                                item['cropName'],
                              )}`}</option>
                            ))
                          ) : (
                            <option>{t('no_harvests')}</option>
                          )}
                        </TextField>
                      }
                      label={t('harvest')}
                      labelPlacement="top"
                    />
                  </Box>
                  <Box mx={1} my={2}>
                    <FormControlLabel
                      sx={{ display: 'flex', alignItems: 'flex-start' }}
                      control={
                        <TextField
                          select
                          name={`${t('layer_type')}`}
                          slotProps={{ select: { native: true } }}
                          variant="outlined"
                          size="small"
                          onChange={layerTypeChange}
                        >
                          {layerOptions &&
                            layerOptions
                              .sort((a, b) => {
                                if (a.name > b.name) return 1
                                else if (a.name < b.name) return -1
                                return 0
                              })
                              .map((item) => (
                                <option
                                  key={item.key}
                                  value={JSON.stringify(item)}
                                >
                                  {item.name}
                                </option>
                              ))}
                        </TextField>
                      }
                      label={`${t('layer_type')}`}
                      labelPlacement="top"
                    />
                  </Box>
                </Box>
              </FormGroup>
            </FormControl>
          </Box>
          <Box mx={1} sx={{ zIndex: 99 }}>
            <div>
              <Button
                variant="contained"
                onClick={sendSelectedHarvest}
                sx={{ width: '160px' }}
                endIcon={
                  <ArrowForwardIcon
                    sx={{
                      '& path': {
                        fill: '#FFF',
                      },
                    }}
                  />
                }
                disabled={
                  !(
                    selectedHarvest &&
                    Object.keys(selectedHarvest).length &&
                    activeStatus
                  )
                }
              >
                {t('generate')}
              </Button>
              {!activeStatus && (
                <>
                  <Box
                    sx={{
                      borderLeft: '8px solid #ff00006b !important',
                      background: '#ff00001a !important',
                      color: 'red !important',
                      marginTop: '16px',
                    }}
                  >
                    <SubscriptionsIcon
                      size="small"
                      style={{
                        color: '#B2023B',
                        height: '0.7em',
                        verticalAlign: 'sub',
                      }}
                      titleAccess={t('no_subscriptions_raw_points')}
                    />
                    <span> {t('renew_subscription')}</span>
                  </Box>
                  <Box sx={{ mt: 2 }}>{t('premium_required_report')}</Box>
                </>
              )}
            </div>
          </Box>
        </Box>
        <Outlet />
      </CommonSidebar>
      <HarvestReport harvestObj={harvestToReport} layerName={layerToReport} />
    </Box>
  )
}
