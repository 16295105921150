//API
import {
  uploadHarvests,
  manageIntegrationPermissions,
  integrationSyncAll,
  integrationSyncFields,
  //@ts-expect-error
} from 'api/integration'

//@ts-expect-error
import * as c from 'common/c'
//@ts-expect-error
import SyncStatus from './sync-status'
//@ts-expect-error
import ReAuthenticate from './re-authenticate'
//Actions
import {
  setFieldSyncStatus,
  setAllSyncStatus,
  setHarvestSyncStatus,
  //@ts-expect-error
} from 'actions/integrations'
//@ts-expect-error
import { closeModal, showModal } from 'actions/app'

//React/other utilities
import { get, keyBy } from 'lodash-es'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useCallback, useState, ReactElement, useEffect } from 'react'

//MUI
import {
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
  CheckBox as CheckBoxIcon,
} from '@mui/icons-material'
import {
  MenuItem,
  Select,
  Box,
  Button,
  FormControlLabel,
  TextField,
  Grid2,
  Typography,
  Stack,
  Checkbox,
  Autocomplete,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/Autocomplete'
//@ts-expect-error
import { isTokenValid } from 'common/misc'

//Types
//@ts-expect-error
import type { IntegrationUrls, IntegrationRef } from 'state/integrations'
//@ts-expect-error
import { AppState } from 'state/index'
//@ts-expect-error
import type { ClientModel } from 'state/client'
//@ts-expect-error
import { FarmModel } from 'state/farm'
//@ts-expect-error
import type { ClientItem, FarmItem } from 'api/integration'

const boxCustomStyle = {
  width: '45vw',
  padding: '10px 20px',
  borderRadius: '5px',
  border: '1px solid lightgray',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: '10px',
}

export type ManageIntegrationProps = {
  token: string
  integrationId: number
  enabledClients: EnabledClient[]
  name: string
}

export type EnabledClient = {
  name: string
  customerId: string
  id: number
}

export default function ManageIntegrationComponent(
  props: ManageIntegrationProps,
): ReactElement<any, any> {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { integrationId, token, name } = props
  //@ts-ignore
  const harvestYears = useSelector((state) => state.harvest.years)
  const clients = useSelector((state: AppState) => state.client.collection)
  const farms = useSelector((state: AppState) => state.farm.collection)
  const fields = useSelector((state: AppState) => state.field.collection)
  const jobs = useSelector((state: AppState) => state.job.collection)

  const cffEnabled = useSelector((state) =>
    get(state, 'account.singleton.prefs.cff.enabled'),
  )
  const client: ClientModel | undefined = useSelector(
    (state: AppState) => state.client.singleton,
  )
  const farm: FarmModel | undefined = useSelector(
    (state: AppState) => state.farm.singleton,
  )
  const harvestSync: String = useSelector(
    (state: AppState) => state.integrations.harvestSync,
  )
  const fieldSync: String = useSelector(
    (state: AppState) => state.integrations.fieldSync,
  )
  const syncAll: String = useSelector(
    (state: AppState) => state.integrations.syncAll,
  )
  const urls: { [key: string]: IntegrationRef } | undefined = useSelector(
    (state) => get(state, 'integrations.integrationUrls'),
  )
  const year = useSelector((state) => get(state, 'field.year'))

  const integrationSyncErrors = useSelector(
    (state: AppState) => state.integrations.integrationSyncErrors,
  )

  const [canSyncFields, setCanSyncFields] = useState(false)
  const [clientList, setClientList] = useState<ClientItem[]>([])
  const [farmList, setFarmList] = useState<FarmItem[]>([])
  const [defaultClient, setDefaultClient] = useState<ClientItem[]>([])
  const [defaultFarm, setDefaultFarm] = useState<FarmItem[]>([])
  const [canUploadHarvest, setCanUploadHarvest] = useState<boolean>(false)
  const [integrationSyncErrorsBool, setIntegrationSyncErrors] =
    useState<boolean>(false)

  useEffect(() => {
    const element = document.querySelector('.MuiDialog-paper') as HTMLDivElement
    if (element) {
      element.style.maxWidth = 'none'
    }
  }, [])

  useEffect(() => {
    let canUpload = true
    for (let i = 0; i < clients.length && canUpload; i++) {
      if (
        clients[i]?.integrationIds === null ||
        clients[i]?.integrationIds[name] === undefined
      ) {
        canUpload = false
      }
    }
    for (let i = 0; i < farms.length && canUpload; i++) {
      if (
        farms[i]?.integrationIds === null ||
        farms[i]?.integrationIds[name] === undefined
      ) {
        canUpload = false
      }
    }
    for (let i = 0; i < fields.length && canUpload; i++) {
      if (
        fields[i]?.integrationIds === null ||
        fields[i]?.integrationIds[name] === undefined
      ) {
        canUpload = false
      }
    }
    setCanUploadHarvest(canUpload)
  }, [farms, clients, fields])

  useEffect(() => {
    const orgId = selectedOrg?.customerId
    const clientIntegrationData: any = get(
      client,
      `integrationIds.${name}.${orgId}`,
      undefined,
    )
    if (clientIntegrationData && client) {
      const clientId = clientIntegrationData?.id || ''
      const status = clientIntegrationData?.archived || false
      setDefaultClient([
        {
          id: client.id,
          name: client.name,
          integrationId: clientId,
          archived: status,
        },
      ])
    }
    const farmIntegrationData: any = get(
      farm,
      `integrationIds.${name}.${orgId}`,
      undefined,
    )
    if (farmIntegrationData && farm) {
      const farmId = farmIntegrationData?.id || ''
      const status = farmIntegrationData?.archived || false
      setDefaultFarm([
        {
          id: farm.id,
          name: farm.name,
          integrationId: farmId,
          clientId: farm.clientId,
          archived: status,
        },
      ])
    }
  }, [client, clients, farm, clients])
  const [selectedYear, setSelectedYear] = useState(
    year ?? new Date().getFullYear(),
  )

  useEffect(() => {
    if (
      integrationSyncErrors?.clientErrors?.length ||
      integrationSyncErrors?.farmErrors?.length ||
      integrationSyncErrors?.fieldErrors?.length ||
      integrationSyncErrors?.boundaryErrors?.length
    ) {
      setIntegrationSyncErrors(true)
    }
  }, [integrationSyncErrors])

  const selectedOrg = props.enabledClients[0]
  const [selectedClients, setSelectedClients] =
    useState<ClientItem[]>(defaultClient)
  const [selectedFarms, setSelectedFarms] = useState<FarmItem[]>(defaultFarm)
  const allFarmsSelected = farmList.length === selectedFarms.length
  const allClientsSelected = clientList.length === selectedClients.length
  const clientFilter = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: ClientItem) => option.name,
  })
  const farmFilter = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: FarmItem) => option.name,
  })

  const onYearChange = useCallback((e) => {
    setSelectedYear(e.target.value)
  }, [])

  const onUploadHarvests = useCallback(() => {
    if (urls != undefined && urls[integrationId] != undefined) {
      const integrationUrls: IntegrationRef = urls[integrationId]

      const integration: IntegrationUrls | undefined =
        integrationUrls && integrationUrls[selectedOrg?.customerId]
      if (integration) {
        dispatch(setHarvestSyncStatus(c.IN_PROGRESS))
        dispatch(
          uploadHarvests({
            harvestId: 'all',
            token: token,
            integrationId: integrationId,
            year: selectedYear,
            name,
            organizationId: selectedOrg?.customerId,
            clients: keyBy(selectedClients, 'id'),
            farms: keyBy(selectedFarms, 'id'),
          }),
        )
      }
    }
  }, [
    selectedYear,
    selectedOrg,
    urls,
    integrationId,
    token,
    selectedClients,
    selectedFarms,
    selectedClients,
  ])

  const onSyncAll = useCallback(
    (event) => {
      const orgId = selectedOrg?.customerId
      if (event.target.id != 'syncAll') return
      if (urls != undefined && urls[integrationId] != undefined) {
        const validToken = isTokenValid(token)
        if (validToken) {
          const integrationUrls: IntegrationRef = urls[integrationId]
          let _farmList: FarmItem[] = []
          for (let i = 0; i < farms.length; i++) {
            const farm = farms[i]
            const farmIntegrationData: any = get(
              farm,
              `integrationIds.${name}.${orgId}`,
              undefined,
            )
            const integrationId = farmIntegrationData
              ? farmIntegrationData?.id
              : ''
            const status = farmIntegrationData?.archived || false //check if archived case has to be set to retired to support Trimble
            _farmList.push({
              name: farm.name,
              id: farm.id,
              integrationId: integrationId,
              clientId: farm.clientId,
              archived: status,
            })
          }

          const integration: IntegrationUrls | undefined =
            integrationUrls && integrationUrls[selectedOrg?.customerId]
          let clientUrl,
            farmUrl,
            fieldUrl,
            boundaryUrl,
            isClientUrl = true

          if (integration.clients) {
            clientUrl = integration.clients
            farmUrl = integration.farms
            fieldUrl = integration.fields
            boundaryUrl = integration.boundaries
          } else {
            isClientUrl = false
          }
          if (isClientUrl) {
            dispatch(setAllSyncStatus(c.IN_PROGRESS))
            dispatch(
              integrationSyncAll({
                clientUrl,
                farmUrl,
                fieldUrl,
                boundaryUrl,
                token: token,
                name,
                clients: keyBy(clients, 'id'), //changed this bit from sending only the clients which have only integrationIds set to sending all clients.
                farms: keyBy(_farmList, 'id'),
                orgId: selectedOrg?.customerId,
              }),
            )
          }
        } else {
          dispatch(showModal(<ReAuthenticate name={name} />))
        }
      }
    },
    [urls, integrationId, token, selectedClients, selectedFarms],
  )

  const onSyncFields = useCallback(() => {
    if (urls != undefined && urls[integrationId] != undefined) {
      const orgId = selectedOrg?.customerId
      const integrationUrls: IntegrationRef = urls[integrationId]
      const validToken = isTokenValid(token)
      const pendingFieldSync = jobs.filter(
        (job) =>
          job.jobType === c.FIELD_SYNC_JOB_TYPE && job.status === c.STARTED,
      )
      if (pendingFieldSync.length > 0) {
        //we really should have at most one in this state
        if (pendingFieldSync.length > 1) {
          pendingFieldSync.sort((a, b) => {
            const aDate = new Date(a.createdAt)
            const bDate = new Date(b.createdAt)
            if (aDate > bDate) return 1
            else if (aDate < bDate) return -1
            return 0
          })
        }
        dispatch(setFieldSyncStatus(c.IN_PROGRESS))
        dispatch(integrationSyncFields({ jobId: pendingFieldSync[0].id }))
      } else {
        if (validToken) {
          let _farmList: FarmItem[] = []
          for (let i = 0; i < farms.length; i++) {
            const farm = farms[i]
            const farmIntegrationData: any = get(
              farm,
              `integrationIds.${name}.${orgId}`,
              undefined,
            )
            const integrationId = farmIntegrationData?.id || ''
            const status = farmIntegrationData?.archived || false
            _farmList.push({
              name: farm.name,
              id: farm.id,
              integrationId: integrationId,
              clientId: farm.clientId,
              archived: status,
            })
          }
          const integration: IntegrationUrls | undefined =
            integrationUrls && integrationUrls[selectedOrg?.customerId]
          let fieldUrl,
            boundaryUrl,
            isFieldUrl = true

          if (integration && integration.fields) {
            fieldUrl = integration.fields
            boundaryUrl = integration.boundaries
          } else {
            isFieldUrl = false
          }
          if (isFieldUrl) {
            dispatch(setFieldSyncStatus(c.IN_PROGRESS))
            dispatch(
              integrationSyncFields({
                fieldUrl,
                boundaryUrl,
                token: token,
                name,
                clients: keyBy(clientList, 'id'),
                farms: keyBy(_farmList, 'id'),
                orgId: selectedOrg?.customerId,
              }),
            )
          }
        } else {
          dispatch(showModal(<ReAuthenticate name={name} />))
        }
      }
    }
  }, [urls, integrationId, token, clientList, farms, jobs])

  const defaultYear = new Date().getFullYear()

  /**
   * Client Functions
   */
  const handleClearClientOptions = () => setSelectedClients([])

  const onClientChange = useCallback(
    (e, newValue, reason) => {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (newValue.find((option) => option.id === 0)) {
          handleToggleSelectAllClients()
        } else {
          setSelectedClients(newValue?.length ? newValue : [])
        }
      } else if (reason === 'clear') {
        handleClearClientOptions()
      }
    },
    [selectedFarms, clientList],
  )
  const handleSelectAllClients = (isSelected) => {
    if (isSelected) {
      setSelectedClients(clientList)
    } else {
      handleClearClientOptions()
    }
  }
  const handleToggleSelectAllClients = () => {
    handleSelectAllClients && handleSelectAllClients(!allClientsSelected)
  }

  const closePopup = useCallback(() => {
    dispatch(closeModal())
  }, [])

  const managePermissions = useCallback(() => {
    if (urls != undefined && urls[integrationId] != undefined) {
      const integrationUrls: IntegrationRef = urls[integrationId]

      const integration: IntegrationUrls | undefined =
        integrationUrls && integrationUrls[selectedOrg?.customerId]
      if (integration && integration.managePermissions)
        manageIntegrationPermissions({
          connectionUri: integration.managePermissions,
          token: token,
          integrationId: integrationId,
        })
    }
  }, [selectedOrg, urls, integrationId, token])

  useEffect(() => {
    let _clientList: ClientItem[] = []
    const orgId = selectedOrg?.customerId
    for (let i = 0; i < clients.length; i++) {
      const client = clients[i]
      const clientIntegrationData: any = get(
        client,
        `integrationIds.${name}.${orgId}`,
        undefined,
      )
      if (clientIntegrationData) {
        _clientList.push({
          name: client.name,
          id: client.id,
          integrationId: clientIntegrationData?.id,
          archived: clientIntegrationData?.archived ?? false,
        })
      }
    }
    setClientList(_clientList)
  }, [clients, name, client])

  /**
   * Farm Functions
   */
  const handleClearFarmOptions = () => setSelectedFarms([])

  const handleSelectAllFarms = (isSelected) => {
    if (isSelected) {
      setSelectedFarms(farmList)
    } else {
      handleClearFarmOptions()
    }
  }
  const handleToggleSelectAllFarms = () => {
    handleSelectAllFarms && handleSelectAllFarms(!allFarmsSelected)
  }

  const onFarmChange = useCallback(
    (e, newValue, reason) => {
      if (reason === 'selectOption' || reason === 'removeOption') {
        if (newValue.find((option) => option.id === 0)) {
          handleToggleSelectAllFarms()
        } else {
          setSelectedFarms(newValue?.length ? newValue : [])
        }
      } else if (reason === 'clear') {
        handleClearFarmOptions()
      }
    },
    [selectedFarms, farmList],
  )
  //handle change of client
  useEffect(() => {
    const orgId = selectedOrg?.customerId
    let _farmList: FarmItem[] = []
    let useDefault = false
    let syncedFarm = false
    if (selectedClients.length > 0) {
      for (let i = 0; i < selectedClients.length; i++) {
        for (let j = 0; j < farms.length; j++) {
          const farm = farms[j]
          if (farm.clientId === selectedClients[i].id) {
            const integrationData: any = get(
              farm,
              `integrationIds.${name}.${orgId}`,
              undefined,
            )
            if (integrationData?.id) {
              syncedFarm = true
              _farmList.push({
                name: farm.name,
                id: farm.id,
                integrationId: integrationData?.id || '',
                clientId: farm.clientId,
                archived: integrationData?.archived ?? false,
              })
            }
            if (farm.id === defaultFarm[0]?.id) {
              useDefault = true
            }
          }
        }
      }
    } else {
      for (let i = 0; i < farms.length; i++) {
        const farm = farms[i]
        const integrationData: any = get(
          farm,
          `integrationIds.${name}.${orgId}`,
          undefined,
        )
        if (integrationData?.id) {
          syncedFarm = true
          _farmList.push({
            name: farm.name,
            id: farm.id,
            integrationId: integrationData?.id,
            clientId: farm.clientId,
            archived: integrationData?.archived ?? false,
          })
        }
        if (farm.id === defaultFarm[0]?.id) {
          useDefault = true
        }
      }
    }
    setCanSyncFields(syncedFarm)
    setFarmList(_farmList)
    setSelectedFarms(useDefault ? defaultFarm : [])
  }, [farms, selectedClients, name])
  //Render the component
  return (
    <Box
      px={2}
      py={2}
      sx={{
        height: '40vh',
        margin: 'auto',
        overflowY: 'auto',
        width: '50vw',
      }}
    >
      <Stack>
        <Box sx={{ marginBottom: '10px', fontSize: 'large' }}>
          <Typography
            style={{ display: name === 'trimble_ag' ? 'block' : 'none' }}
          >
            {t('integration_no_sync_no_CFF')}
          </Typography>
          <br style={{ display: name === 'trimble_ag' ? 'block' : 'none' }} />
          <Typography>{t('boundary_sync_background')}</Typography>
        </Box>
        <Typography>{t('manage_data')} </Typography>

        {/*Show Integration Sync Errors, if any*/}
        {integrationSyncErrorsBool ? (
          <Box
            sx={{
              borderLeft: '8px solid #ff00006b !important',
              background: '#ff00001a !important',
              color: 'red !important',
              '& p': {
                margin: '5px',
              },
            }}
          >
            {/* <Stack spacing={1}> */}
            <p>{t('integration_sync_error_exists')}</p>
            {/* {integrationSyncErrors.clientErrors.map((error, index)=>(
              <p key = {index}>{`${error.message} - ${error.name}`}</p>
            ))} */}
            {/* </Stack> */}
          </Box>
        ) : null}
        {/**End */}

        <Box sx={boxCustomStyle}>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <Button variant="contained" onClick={onSyncAll} id="syncAll">
              {t('sync_all')}
            </Button>
            <SyncStatus status={syncAll} />
          </Box>
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <Button
              variant="contained"
              onClick={onSyncFields}
              disabled={!canSyncFields}
              id="syncFields"
            >
              {t('sync_fields')}
            </Button>
            <SyncStatus status={fieldSync} />
          </Box>
        </Box>
      </Stack>

      <Stack style={{ paddingTop: '10px' }}>
        <Typography>{t('upload_harvest')} </Typography>
        <Box sx={boxCustomStyle}>
          <Stack>
            <Box
              style={{
                display: name === 'trimble_ag' ? 'block' : 'none',
                width: '43vw',
              }}
              sx={{ marginBottom: '10px', fontSize: 'large' }}
            >
              <Typography>{t('ptx_trimble_subscription_note')}</Typography>
            </Box>
            <Box
              sx={{
                width: '44vw',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px',
              }}
            >
              {canUploadHarvest ? (
                <Grid2
                  container
                  spacing={2}
                  sx={{ m: 0, width: '44vw', gap: '1px' }}
                >
                  <FormControlLabel
                    control={
                      <Select
                        sx={{ fontSize: '0.75em', height: '28px' }}
                        value={selectedYear}
                        onChange={onYearChange}
                      >
                        {!harvestYears?.length && (
                          <MenuItem key={defaultYear} value={defaultYear}>
                            {defaultYear}
                          </MenuItem>
                        )}
                        {harvestYears?.length &&
                          harvestYears.map((v) => (
                            <MenuItem key={v.harvestYr} value={v.harvestYr}>
                              {v.harvestYr}
                            </MenuItem>
                          ))}
                      </Select>
                    }
                    label={t('year')}
                    labelPlacement="start"
                  />
                  {cffEnabled || clientList.length > 1 ? (
                    <Autocomplete
                      multiple
                      limitTags={2}
                      disabled={clientList.length === 0}
                      id="clients"
                      size="small"
                      options={clientList}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={selectedClients}
                      disableCloseOnSelect
                      onChange={(event, newValue, reason) => {
                        onClientChange(event, newValue, reason)
                      }}
                      filterOptions={(options, params) => {
                        const filtered = clientFilter(options, params)
                        return [
                          {
                            name: t('select_all'),
                            id: 0,
                            integrationId: 'all',
                            archived: false,
                          },
                          ...filtered,
                        ]
                      }}
                      renderOption={(props, option, { selected }) => {
                        const selectAllProps =
                          option.id === 0 // To control the state of 'select-all' checkbox
                            ? { checked: allClientsSelected }
                            : {}
                        return (
                          <li
                            style={{ padding: '0 0 0 5px' }}
                            {...props}
                            key={option.id}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              {...selectAllProps}
                            />
                            {option.name}
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('clients')}
                          placeholder={t('clients')}
                        />
                      )}
                      renderTags={(value) => {
                        const numTags = value.length
                        return (
                          <div>
                            <Typography variant="body2">
                              {value
                                .slice(0, 1)
                                .map((option) => option.name)
                                .join(', ')}

                              {numTags > 1 && ` +${numTags - 1} more`}
                            </Typography>
                          </div>
                        )
                      }}
                    />
                  ) : null}
                  {cffEnabled || farmList.length > 1 ? (
                    <Autocomplete
                      multiple
                      limitTags={2}
                      id="farms"
                      size="small"
                      options={farmList}
                      disabled={farmList.length === 0}
                      getOptionLabel={(option) => option?.name || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      value={selectedFarms}
                      disableCloseOnSelect
                      onChange={(event, newValue, reason) => {
                        onFarmChange(event, newValue, reason)
                      }}
                      filterOptions={(options, params) => {
                        const filtered = farmFilter(options, params)
                        return [
                          {
                            name: t('select_all'),
                            id: 0,
                            integrationId: 'all',
                            clientId: 0,
                            archived: false,
                          },
                          ...filtered,
                        ]
                      }}
                      renderOption={(props, option, { selected }) => {
                        const selectAllProps =
                          option.id === 0 // To control the state of 'select-all' checkbox
                            ? { checked: allFarmsSelected }
                            : {}
                        return (
                          <li
                            style={{ padding: '0 0 0 5px' }}
                            {...props}
                            key={option.id}
                          >
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                              {...selectAllProps}
                            />
                            {option.name}
                          </li>
                        )
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t('farms')}
                          placeholder={t('farms')}
                        />
                      )}
                      renderTags={(value) => {
                        const numTags = value.length
                        return (
                          <div>
                            <Typography variant="body2">
                              {value
                                .slice(0, 1)
                                .map((option) => option.name)
                                .join(', ')}

                              {numTags > 1 && ` +${numTags - 1} more`}
                            </Typography>
                          </div>
                        )
                      }}
                    />
                  ) : null}
                </Grid2>
              ) : (
                <Typography>{t('sync_data_first')}</Typography>
              )}
              <Button
                variant="contained"
                onClick={onUploadHarvests}
                disabled={
                  selectedFarms.length === 0 && selectedClients.length === 0
                }
              >
                {t('upload')}
              </Button>
              <SyncStatus status={harvestSync} />
            </Box>
          </Stack>
        </Box>
      </Stack>
      <Box
        sx={{
          width: '44vw',
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          marginTop: '10px',
        }}
      >
        <Button color="secondary" onClick={closePopup}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          onClick={managePermissions}
          style={{ display: name === 'trimble_ag' ? 'none' : 'block' }}
        >
          {t('manage_permissions')}
        </Button>
      </Box>
    </Box>
  )
}
