import { styled } from '@mui/system'
import { BlankLink } from 'components/foundation'

declare module 'components/layers-group/item-switch.js'
export type ItemSwitchProps = {
  onClick: Function
  className?: string
  on: number
  title: string
  children: React.ReactNode
}

export default function ItemSwitch(props: ItemSwitchProps) {
  const { on, children, onClick, className = '', title } = props

  return (
    <StyledRoot
      on={on ? 1 : 0}
      onClick={onClick}
      className={className}
      title={title}
    >
      {children}
    </StyledRoot>
  )
}

const StyledRoot = styled(BlankLink)<{ on: number }>(({ on }) => ({
  display: 'block',
  paddingLeft: '4px',
  color: '#212121',
  opacity: 0.8,
  fontSize: '1rem',
  fontWeight: on ? 700 : 400,
  background: on ? '#F4F4F4' : 'transparent',
}))
