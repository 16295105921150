import { Box, Grid2, FormControlLabel, Switch, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { editUserPreferences } from 'api/user'
import { fetchNotifications } from 'api/notification'
import { useEffect } from 'react'
import * as c from 'common/c'

export default function UserPreference() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userPrefs = useSelector((state) => state.account.user['prefs'])

  const [prefs, setPrefs] = useState({})

  useEffect(() => {
    setPreferences(
      userPrefs
        ? userPrefs?.notifications.suppression
        : c.DEFAULT_NOTIFICATION_SUPPRESSION,
    )
  }, [userPrefs])

  const setPreferences = (notificationObj) => {
    let otherValue = notificationObj.other
    const prefs = Object.keys(notificationObj)
      .sort()
      .reduce((obj, key) => {
        if (key !== 'other') {
          obj[key] = notificationObj[key]
        }
        return obj
      }, {})
    prefs['other'] = otherValue
    setPrefs(prefs)
  }

  const changeItemKey = (item) => {
    let key = item.split('_').join(' ')
    return key.charAt(0).toUpperCase() + key.slice(1)
  }

  const handleChange = useCallback((e) => {
    const {
      target: { checked },
    } = e

    const categoryUpdated = e.target.name

    const reqBody = {
      path: `notifications.suppression.${categoryUpdated}`,
      value: !checked,
    }

    dispatch(editUserPreferences(reqBody)).then(() => {
      return dispatch(fetchNotifications())
    })
  })

  return (
    <Box sx={{ maxWidth: '600px' }}>
      <Grid2 container alignSelf={'start'} spacing={2}>
        <Grid2 size={12}>
          <Typography align={'left'} variant="h5">
            <b>{t('notification_categories')}</b>
          </Typography>
        </Grid2>

        {Object.keys(prefs).map((category) => (
          <Grid2 size={12} key={category}>
            <FormControlLabel
              value="enable"
              control={
                <Switch
                  color="primary"
                  onChange={handleChange}
                  name={category}
                />
              }
              label={changeItemKey(category)}
              labelPlacement="start"
              checked={!prefs[category]}
            />
          </Grid2>
        ))}
      </Grid2>
    </Box>
  )
}
