import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  ReactElement,
  RefObject,
} from 'react'
import * as PropTypes from 'prop-types'
import { styled } from '@mui/system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { noop } from 'lodash-es'
//@ts-expect-error
import { isNotBlank } from 'common/misc'
//@ts-expect-error
import useOnClickOutside from 'hooks/click-outside'
import { defaultShadow, BlankLink } from 'components/foundation'
//@ts-expect-error
import colors from 'common/colors'

declare module 'components/popup/popup-internal.js' {
  const PopupInternal: React.FunctionComponent<PopupInternalProps>
  //export default PopupInternal
  export type PixelCoords = {
    x: number
    y: number
  }
  export type PopupInternalProps = {
    onClose: Function
    children: ReactElement<any, any>
    title: string
    pixel: PixelCoords
    ref?: RefObject<HTMLDivElement>
  }
}
export default function PopupInternal(
  props: PopupInternalProps,
): ReactElement<any, any> | null {
  const {
    onClose = noop,
    children,
    title,
    pixel = {
      x: 0,
      y: 0,
    },
  } = props

  const ref = useRef<HTMLDivElement>(null)
  const [withTitle, setWithTitle] = useState(false)

  useOnClickOutside(ref, () => {
    onClose()
  })

  const onCloseClicked = useCallback(() => {
    onClose()
  }, [onClose])

  useEffect(() => {
    setWithTitle(isNotBlank(title))
  }, [title])

  return (
    <StyledWrapper ref={ref} $pixel={pixel} $withTitle={withTitle}>
      {withTitle ? <StyledHeader key="title">{title}</StyledHeader> : null}
      <StyledClose key="close-link" onClick={onCloseClicked}>
        <FontAwesomeIcon icon="times" color={colors.grey} />
      </StyledClose>
      {children}
      <Arrow $pixel={pixel} />
    </StyledWrapper>
  )
}

PopupInternal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  $pixel: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
}

//@ts-ignore
const StyledHeader = styled('h1')<StyledHeaderProps>(() => ({
  fontSize: 'large',
  marginTop: '9px',
  paddingBottom: '4px',
  borderBottom: '1px solid #eee',
}))

interface StyledWrapperProps {
  $pixel: PixelCoords
  $withTitle?: boolean
  children: any
  ref: any
}

interface StyledHeaderProps {
  key: string
  children?: string
}

const StyledWrapper = styled('div')<StyledWrapperProps>(
  ({ $pixel, $withTitle }) => ({
    backgroundColor: 'white',
    padding: '8px',
    position: 'fixed',
    top: `${$pixel.y - 20}px`,
    left: `${$pixel.x - 5}px`,
    width: '238px',
    minHeight: '100px',
    paddingTop: $withTitle ? '0px' : '40px',
    zIndex: 1000,
    overflowY: 'auto',
    ...defaultShadow,
    borderRadius: '4px',
  }),
)

StyledWrapper.propTypes = { $withTitle: PropTypes.bool }

StyledWrapper.defaultProps = { $withTitle: false }

const StyledClose = styled(BlankLink)({
  position: 'absolute',
  right: '12px',
  top: '10px',
})

interface ArrowProps {
  $pixel: PixelCoords
}

const Arrow = styled('div')<ArrowProps>(({ $pixel }) => ({
  position: 'fixed',
  top: `${$pixel.y + 38}px`,
  left: `${$pixel.x + 229}px`,
  borderBottom: '10px solid transparent',
  borderTop: '10px solid transparent',
  borderLeft: '10px solid white',
  height: 0,
  width: 0,
}))
