import { FarmtrxIconUploadNew } from '@troo/farmtrx-icons'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { styled } from '@mui/material/styles'

const HiddenInput = styled('input')({
  height: 1,
  width: 1,
})

export type FileUploadInputProps = {
  mt: number | string
  onImport: Function
}

export default function FileUploadInput(props) {
  const { t } = useTranslation()
  const { onImport } = props
  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={
          <FarmtrxIconUploadNew
            style={{
              height: '20px',
              stroke: '#FFF',
            }}
          />
        }
        sx={[{ ml: 2 }, props.mt && { mt: 2 }]}
      >
        {t('import')}
        <HiddenInput
          type="file"
          onChange={(event) => {
            let fileData = event.target.files
            onImport(fileData)
          }}
        />
      </Button>
    </>
  )
}
