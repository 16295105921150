import * as c from 'common/c'
import Feature from 'ol/Feature'
import { Point } from 'ol/geom'
import {
  addSingleFeatureToHarvest,
  updateSingleFeatureOfHarvest,
} from 'api/harvest'
import { toGeoJsonSingle } from 'common/ol/utils'
import { FormTextArea } from 'components/form/foundation'
import { Button } from '@mui/material'

export default function NoteEditor(props) {
  const { coords, feature } = props

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const [note, setNote] = useState()

  const harvest = useSelector((state) => state.harvest.current)

  useEffect(() => {
    setNote(feature ? feature.get('note') : undefined)
  }, [feature])

  const onAddNote = useCallback(() => {
    if (note && coords && harvest) {
      if (feature) {
        feature.set('note', note)
        dispatch(
          updateSingleFeatureOfHarvest(harvest.id, toGeoJsonSingle(feature)),
        )
      } else {
        const geoJsonPoint = new Feature({
          geometry: new Point(coords),
          [c.OL_PROP_TYPE]: c.FTYPE_NOTE,
          note,
        })
        console.log('geoJsonPoint before toGeoJson', geoJsonPoint)
        console.log('geoJsonPoint', toGeoJsonSingle(geoJsonPoint))
        dispatch(
          addSingleFeatureToHarvest(
            harvest.id,
            toGeoJsonSingle(
              new Feature({
                geometry: new Point(coords),
                [c.OL_PROP_TYPE]: c.FTYPE_NOTE,
                note,
              }),
            ),
          ),
        )
      }
    }
  }, [note, coords, harvest, feature])

  const onChange = useCallback((e) => {
    if (e.target.value) {
      setNote(e.target.value.trim())
    }
  })

  return (
    <>
      <FormTextArea
        autoFocus
        rows="5"
        cols="26"
        onChange={onChange}
        defaultValue={note}
      />
      <Button
        sx={{
          width: '100%',
          marginTop: '4px',
        }}
        onClick={onAddNote}
      >
        {feature ? t('update_note') : t('add_note')}
      </Button>
    </>
  )
}
