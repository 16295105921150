import { ReactNode } from 'react'
import { styled } from '@mui/system'
import * as PropTypes from 'prop-types'

declare module 'components/layout/vertical.js' {
  export type VItem = {
    propTypes: {
      style: any
      $space: boolean
      displayName: string
      children: ReactNode
    }
  }
  export interface V {
    children: ReactNode
  }
}

export function V(props: { style: any; children: ReactNode }) {
  const { style, children } = props
  return <StyledContainer style={style}>{children}</StyledContainer>
}

V.displayName = 'VerticalLayout'

export function VItem(props: {
  style: any
  children: ReactNode
  $space?: boolean
}) {
  const { style, children, $space = false } = props
  return (
    <StyledItem style={style} $space={$space}>
      {children}
    </StyledItem>
  )
}

VItem.propTypes = {
  $space: PropTypes.bool,
}

VItem.displayName = 'VerticalLayout.Item'

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
})

const StyledItem = styled('div')<{ $space?: boolean }>(({ $space }) => ({
  '& + &': {
    marginTop: $space ? 0 : '8px',
  },
}))
