import { Lock, LockOpen } from '@mui/icons-material'
import { get } from 'lodash-es'
import { ButtonBase } from '@mui/material'
import colors from 'common/colors'
import useSplitMap from 'hooks/use-split-map'

export default function LockView(props) {
  const { lockViewRef } = props
  const { lock, onSwitchLock } = useSplitMap(lockViewRef)

  const fieldA = useSelector((state) => get(state, 'field.field_a'))
  const fieldB = useSelector((state) => get(state, 'field.field_b'))

  const lockUnlockIcon = lock ? (
    <Lock fontSize="inherit" sx={{ color: colors.brand }} />
  ) : (
    <LockOpen fontSize="inherit" sx={{ color: colors.brand }} />
  )

  return fieldA?.id === fieldB?.id ? (
    lockViewRef === 'lock_a' ? (
      <ButtonBase
        sx={{
          display: 'flex',
          position: 'absolute',
          height: '20px',
          width: '20px',
          padding: '2px',
          top: '10px',
          right: '10px',
          zIndex: 100,
          background: 'white',
          borderRadius: '4px',
        }}
        onClick={() => onSwitchLock()}
      >
        {lockUnlockIcon}
      </ButtonBase>
    ) : (
      <ButtonBase
        sx={{
          display: 'flex',
          position: 'absolute',
          height: '20px',
          width: '20px',
          padding: '2px',
          top: '10px',
          right: '50px',
          zIndex: 100,
          background: 'white',
          borderRadius: '4px',
        }}
        onClick={() => onSwitchLock()}
      >
        {lockUnlockIcon}
      </ButtonBase>
    )
  ) : null
}
