import { styled } from '@mui/system'
import PropTypes from 'prop-types'

export default function H(props) {
  const { style, children } = props

  return (
    <StyledContainer style={style} {...props}>
      {children}
    </StyledContainer>
  )
}

H.propTypes = {
  children: PropTypes.node.isRequired,
  $justifyContent: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'space-between',
    'space-around',
    'space-evenly',
  ]),
}

H.Item = function (props) {
  const { style, children, gutter = '10px' } = props

  return (
    <StyledItem style={style} $gutter={gutter}>
      {children}
    </StyledItem>
  )
}

H.Item.displayName = 'Horizontal Item'

H.Item.propTypes = {
  $gutter: PropTypes.string,
}

const StyledContainer = styled('div')((props) => ({
  display: 'flex',
  justifyContent: props.$justifyContent,
  flexDirection: 'row',
}))

const StyledItem = styled('div')((props) => ({
  '& + &': {
    marginLeft: props.$gutter,
  },
}))
