import { Modify } from 'ol/interaction'
import useMap from 'hooks/use-map'
import { useState } from 'react'
import { boundaryChanged } from 'actions/map'
import {
  toGeoJson,
  convertGeoJsonToOpenLayersFeature,
  unKink,
  to180Degrees,
} from 'common/ol/utils'
import MultiPolygon from 'ol/geom/MultiPolygon'
import { get } from 'lodash-es'
import { saveFeatures } from 'api/field'

import Point from 'ol/geom/Point'
import Feature from 'ol/Feature'
import { platformModifierKeyOnly } from 'ol/events/condition'
import difference from '@turf/difference'
import { polygon, multiPolygon } from '@turf/helpers'

function deletePoint(pixel, features, map) {
  if (!pixel) return
  let f = []
  try {
    f = map.getFeaturesAtPixel(pixel, {
      hitTolerance: 5,
    })
  } catch (err) {
    console.log(err)
  }
  let coordinate
  for (let i = 0; i < f.length; i++) {
    const geom = f[i].getGeometry()
    if (geom instanceof Point) {
      coordinate = geom.getCoordinates()
    }
  }
  if (coordinate) {
    const featuresArray = features.getArray()
    for (let i = 0; i < featuresArray.length; i++) {
      const geometry = featuresArray[i].getGeometry()
      const vertex = geometry.getClosestPoint(coordinate)
      const coordinates = geometry.flatCoordinates
      const ends = geometry.endss_[0]
      let start = 0
      let p = []
      for (let j = 0; j < ends.length; j++) {
        let ring = []
        const polygon = coordinates.slice(start, ends[j])
        const indexOne = polygon.indexOf(vertex[0])
        const indexTwo = polygon.indexOf(vertex[1])
        let endEl = []
        for (let k = 0; k < polygon.length; k += 2) {
          if (k === indexOne) {
            if (indexOne === 0) {
              //This is the first and last point we are removing
              const nextPoint = k + 2
              if (nextPoint + 1 < polygon.length) {
                endEl.push(polygon[nextPoint])
                endEl.push(polygon[nextPoint + 1])
              }
            }
          } else {
            ring.push([polygon[k], polygon[k + 1]])
          }
        }
        if (endEl.length && ring.length) {
          ring[ring.length - 1] = endEl
        }
        if (indexOne > -1 && indexTwo > -1 && indexOne + 1 === indexTwo) {
          if (polygon.length > 8) {
            p.push(ring)
          }
        } else {
          p.push(ring)
        }
        start = ends[j]
      }
      let _feature
      let fixed
      try {
        _feature = new Feature({
          geometry: new MultiPolygon([p]),
        })
        fixed = fixFeature(_feature)
      } catch (err) {
        console.log(err)
      }
      if (fixed) {
        try {
          const olFeature = convertGeoJsonToOpenLayersFeature(fixed)
          featuresArray[i].setGeometry(olFeature.getGeometry())
        } catch (err) {
          console.log(err)
        }
      }
    }
  }
}

function fixFeature(feature) {
  const currentFeature = toGeoJson(feature)
  for (let j = 0; j < currentFeature.features.length; j++) {
    const feature = currentFeature.features[j]
    const geometryType = feature.geometry.type
    const coordinates =
      geometryType === 'MultiPolygon'
        ? feature.geometry.coordinates[0]
        : feature.geometry.coordinates
    let updatedFeature = multiPolygon([[coordinates[0]]])
    for (let k = 1; k < coordinates.length; k++) {
      let holeCoordinates = []
      for (let l = coordinates[k].length - 1; l >= 0; l--) {
        holeCoordinates.push(coordinates[k][l])
      }
      const hole = polygon([holeCoordinates])
      const res = difference(updatedFeature, hole)
      if (res != null) {
        if (res.geometry.type == 'Polygon') {
          res.geometry.type = 'MultiPolygon'
          res.geometry.coordinates = [res.geometry.coordinates]
        }
        updatedFeature = res
      }
    }
    updatedFeature.id = feature.id
    updatedFeature.properties = feature.properties
    currentFeature.features[j] = updatedFeature
  }
  const unkinked = unKink(currentFeature, false, false)
  return unkinked
}

export default function ModifyInteraction({ features }) {
  const map = useMap()

  const dispatch = useDispatch()
  const [modified, setModified] = useState(false)
  const [interaction, setInteraction] = useState()
  const field = useSelector((state) => get(state, 'field.singleton'))

  useEffect(() => {
    if (features) {
      const featuresArray = features.getArray()
      for (let i = 0; i < featuresArray.length; i++) {
        const geometry = toGeoJson(featuresArray[i])
        let updated = false
        for (let j = 0; j < geometry.features.length; j++) {
          const res = to180Degrees(geometry.features[j])
          if (res.updated) {
            updated = res.updated
            geometry.features[j].coordinates = res.coordinates
          }
        }
        if (updated) {
          let coords = []
          dispatch(saveFeatures(field.id, geometry, false))
          if (geometry.features.length > 1) {
            for (let i = 0; i < geometry.features.length; i++) {
              const workingCoords = geometry.features[i].geometry.coordinates
              coords.push(workingCoords)
            }
          } else {
            coords = geometry.features[0].geometry.coordinates
          }
          const geom = new MultiPolygon(coords).transform(
            'EPSG:4326',
            'EPSG:3857',
          )
          featuresArray[i].setGeometry(geom)
        }
      }
      const interaction = new Modify({
        features,
        deleteCondition: platformModifierKeyOnly,
      })

      map.addInteraction(interaction)
      setInteraction(interaction)

      return function () {
        map.removeInteraction(interaction)
      }
    } else {
      console.log('no features found')
    }
  }, [map, features])

  useEffect(() => {
    if (!interaction) return

    function onEdit() {
      if (!modified) {
        setModified(true)
        dispatch(boundaryChanged())
      }
    }
    interaction.on('modifystart', onEdit)
    return () => {
      interaction.un('modifystart', onEdit)
    }
  }, [interaction])

  useEffect(() => {
    if (!interaction) return

    function onEdit(e) {
      const isDelete = e?.target?.lastPointerEvent_?.originalEvent?.metaKey
      const pixel = e?.target?.lastPointerEvent_?.pixel
      if (isDelete) {
        deletePoint(pixel, features, map)
      } else {
        const featuresArray = e.features.getArray()
        for (let i = 0; i < featuresArray.length; i++) {
          const fixedFeature = fixFeature(featuresArray[i])
          featuresArray[i].setGeometry(
            convertGeoJsonToOpenLayersFeature(fixedFeature).getGeometry(),
          )
        }
      }
    }
    interaction.on('modifyend', onEdit)
    return () => {
      interaction.un('modifyend', onEdit)
    }
  }, [interaction, features, map])
  return null
}
