import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
  TextField,
} from '@mui/material'
import { updateCountryExtent } from 'actions/field'
import { fetchDefaultCrops } from 'api/crop'
import { editTenantPreferences, editTenant } from 'api/tenant'
import { sortBy } from 'lodash-es'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { MuiColorInput } from 'mui-color-input'
import * as c from 'common/c'
import PropTypes from 'prop-types'

const textFieldStyle = {
  width: '70%',
}

export default function PreferencesComponent() {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const permissions = useSelector((state) => state.account.permissions)
  const customClaims = useSelector((state) => state.account.customClaims)
  const preferenceOptions = useSelector((state) => state.account.options)
  const initialPrefs = useSelector((state) => state.account.singleton.prefs)
  const tenantObj = useSelector((state) => state.account.singleton)
  const clients = useSelector((state) => state.client.collection)
  const fetchedFarms = useSelector((state) => state.farm.collection)
  const cffEnabled = useSelector(
    (state) => state.account.singleton.prefs?.cff?.enabled ?? false,
  )
  const selectedClient = useSelector(
    (state) => state.account.singleton.prefs?.cff?.client ?? null,
  )
  const autoSyncEnabled = useSelector(
    (state) => state.account.singleton.prefs?.autosync?.enabled ?? false,
  )
  const cropColorPrefs = useSelector(
    (state) => state.account.singleton.prefs?.cropColor ?? c.EMPTY_ARRAY,
  )
  const crops = useSelector((state) => state.crop['cropConstantsCollection'])
  const cropVarieties = useSelector((state) => state.cropVariety['collection'])

  const [farms, setFarms] = useState([...fetchedFarms])
  const [countries, setCountries] = useState([])
  const [canEdit, setCanEdit] = useState(false)
  const [canEditCountry, setEditCountry] = useState(false)
  const [canEditAutoSync, setEditAutoSync] = useState(false)
  const [cropColors, setCropColors] = useState([])

  useEffect(() => {
    if (preferenceOptions?.countries) {
      setCountries(
        sortBy(
          Object.keys(preferenceOptions.countries).map((key) => ({
            ...preferenceOptions.countries[key],
            id: key,
          })),
          ['name'],
        ),
      )
    }
  }, [preferenceOptions])

  useEffect(() => {
    if (clients && clients.length && selectedClient) {
      const client = parseInt(selectedClient)
      const foundClient = clients.find((c) => c.id === client)
      if (foundClient && foundClient.farms) {
        const farmsPerClient = [...foundClient.farms]
        setFarms(farmsPerClient)
        if (farmsPerClient && farmsPerClient.length) {
          dispatch(
            editTenantPreferences({
              path: 'cff.farm',
              value: parseInt(farmsPerClient[0].id),
            }),
          )
        }
      }
    }
  }, [selectedClient, clients])

  useEffect(() => {
    setCanEdit(permissions.includes('farm:write'))
    setEditCountry(
      permissions.includes('farm:write') &&
        permissions.includes('farm:read') &&
        permissions.includes('field:write') &&
        permissions.includes('field:read') &&
        permissions.includes('harvest:write') &&
        permissions.includes('harvest:read') &&
        permissions.includes('harvest:admin') &&
        permissions.includes('reports:read') &&
        permissions.includes('tenant:list') &&
        permissions.includes('users:write'),
    )
    setEditAutoSync(
      permissions.includes('farmtrx:admin') || customClaims?.[c.FT_ADMIN_CLAIM],
    )
  }, [permissions])

  useEffect(() => {
    let cropsToEditColors = []
    if (cropVarieties) {
      cropVarieties.forEach((cropVariety) => {
        let crop = crops.find((crop) => crop.id === cropVariety.cropId)
        if (crop) {
          let c = crop.color
          if (cropColorPrefs) {
            // replace default colours with tenant preferences
            if (cropColorPrefs[crop.id] !== undefined) {
              c = cropColorPrefs[crop.id]
            }
          }
          cropsToEditColors.push({
            cropId: crop.id,
            crop: crop.name,
            // variety: cropVariety.name,
            color: c,
          })
        }
      })
      setCropColors(cropsToEditColors)
    }
  }, [cropVarieties, cropColorPrefs])

  const onPrefChange = (e) => {
    const { name, value } = e.target
    let path = ''
    if (name === 'country') {
      let selectedCountry = countries.find((i) => i.id === value)
      dispatch(editTenant(...tenantObj, { country: selectedCountry })).then(
        () => {
          let extent
          countries.filter((item, index) => {
            if (item.id === value) extent = countries[index].extent
          })
          return dispatch(updateCountryExtent(extent))
        },
      )
    } else {
      path = `units.${name}_in`.toLowerCase()
      dispatch(
        editTenantPreferences({
          path,
          value: value,
        }),
      )
    }
  }

  const onCFFChange = (e) => {
    const { name, value } = e.target
    dispatch(
      editTenantPreferences({
        path: `cff.${name}`,
        value: parseInt(value),
      }),
    )
  }

  const onCFFToggleChange = () => {
    const previousCFFStatus = cffEnabled
    dispatch(
      editTenantPreferences({
        path: 'cff.enabled',
        value: !previousCFFStatus,
      }),
    )

    // If the toggle is switched to enabled
    if (!previousCFFStatus) {
      if (!initialPrefs.cff?.farm) {
        let farm = farms[0]
        dispatch(
          editTenantPreferences({
            path: 'cff.farm',
            value: parseInt(farm.id),
          }),
        )
      }
      if (!initialPrefs.cff?.client) {
        let client = clients[0]
        dispatch(
          editTenantPreferences({
            path: 'cff.client',
            value: parseInt(client.id),
          }),
        )
      }
    }
  }

  const onAutoSyncToggleChange = async () => {
    const previousAutoSyncStatus = autoSyncEnabled
    await dispatch(
      editTenantPreferences({
        path: 'autosync.enabled',
        value: !previousAutoSyncStatus,
      }),
    )

    if (!previousAutoSyncStatus) {
      if (!initialPrefs.autosync?.sync_frequency_min) {
        await dispatch(
          editTenantPreferences({
            path: 'autosync.sync_frequency_min',
            value: 60,
          }),
        )
      }
      if (!initialPrefs.autosync?.layer_processing_start_hour) {
        await dispatch(
          editTenantPreferences({
            path: 'autosync.layer_processing_start_hour',
            value: 0,
          }),
        )
      }
    }
  }

  const onAutoSyncChange = (e) => {
    const { name, value } = e.target
    dispatch(
      editTenantPreferences({
        path: `autosync.${name}`,
        value: parseInt(value),
      }),
    )
  }

  const changeItemKey = (item) => {
    return `${item}_in`.toLowerCase()
  }

  const CropColorPicker = ({ cropId, initialColor }) => {
    const [color, setColor] = React.useState(initialColor)
    const [isColorEdited, setIsColorEdited] = React.useState(false)

    const onColorChange = (newValue) => {
      setIsColorEdited(true)
      setColor(newValue)
    }

    const onApply = () => {
      dispatch(
        editTenantPreferences({
          path: `cropColor.${cropId}`,
          value: color,
        }),
      ).then(() => dispatch(fetchDefaultCrops()))
    }

    const onCancel = () => {
      setColor(initialColor)
      setIsColorEdited(false)
    }

    return (
      <Box sx={{ display: 'flex', width: '70%' }}>
        <MuiColorInput format="hex" value={color} onChange={onColorChange} />
        <Button
          onClick={() => onApply()}
          sx={{ visibility: isColorEdited ? 'visible' : 'hidden' }}
        >
          {t('apply')}
        </Button>
        <Button
          onClick={() => onCancel()}
          sx={{ visibility: isColorEdited ? 'visible' : 'hidden' }}
        >
          {t('cancel')}
        </Button>
      </Box>
    )
  }

  CropColorPicker.propTypes = {
    cropId: PropTypes.number,
    color: PropTypes.string,
  }

  if (initialPrefs) {
    return (
      <Box
        mx={2}
        my={2}
        padding={2}
        width={1}
        sx={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: '#003057',
          borderRadius: '10px',
          height: 'max-content',
          marginTop: '30px',
          overflowY: 'scroll',
          alignItems: 'flex-start',
        }}
      >
        {/* <Typography variant="h5" style={{ textAlign: 'center' }}><b>{t('preferences')}</b></Typography> */}
        <FormControl disabled={!canEdit} component="fieldset" fullWidth>
          <FormGroup aria-label="position">
            <Box my={2}>
              <FormControlLabel
                control={
                  <TextField
                    id="outlined-select-country-native"
                    sx={textFieldStyle}
                    disabled={!canEditCountry}
                    select
                    name="country"
                    value={tenantObj?.country.id}
                    onChange={onPrefChange}
                    variant="outlined"
                    size="small"
                    placeholder={t('select_country')}
                    slotProps={{
                      select: { native: true },
                    }}
                  >
                    {countries.map((country) => (
                      <option value={country.id} key={country.id}>
                        {t(country.name)}
                      </option>
                    ))}
                  </TextField>
                }
                label={t('Country')}
                labelPlacement="start"
              />
            </Box>
            {/* Units Form start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel sx={{ fontWeight: 'bold' }} component="legend">
                  {t('units')}
                </FormLabel>

                {preferenceOptions.units &&
                Object.keys(preferenceOptions.units) ? (
                  Object.keys(preferenceOptions.units).map((item) => (
                    <FormGroup aria-label="position" key={item}>
                      <Box my={1}>
                        <FormControlLabel
                          control={
                            <TextField
                              sx={textFieldStyle}
                              disabled={!canEdit}
                              select
                              name={item}
                              value={initialPrefs.units[changeItemKey(item)]}
                              onChange={onPrefChange}
                              variant="outlined"
                              size="small"
                              slotProps={{
                                select: { native: true },
                              }}
                            >
                              {Object.keys(preferenceOptions.units[item]).map(
                                (innerItem) => (
                                  <option value={innerItem} key={innerItem}>
                                    {t(innerItem)}
                                  </option>
                                ),
                                // <option value={innerItem} key={innerItem}>{t([innerItem])}</option>
                              )}
                            </TextField>
                          }
                          label={t(
                            item
                              .replace(/([a-z0-9])([A-Z])/g, '$1_$2')
                              .toLowerCase(),
                          )}
                          labelPlacement="start"
                        />
                      </Box>
                    </FormGroup>
                  ))
                ) : (
                  <></>
                )}
              </FormControl>
            </Box>
            {/* Units form end */}
            {/* CFF preferences start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel sx={{ fontWeight: 'bold' }} component="legend">
                  {t('cff')}
                </FormLabel>
                <Box my={1}>
                  <FormControlLabel
                    value="enable"
                    control={
                      <Switch
                        color="primary"
                        onChange={onCFFToggleChange}
                        checked={cffEnabled}
                      />
                    }
                    label={t('enable_cff')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <TextField
                        sx={textFieldStyle}
                        disabled={!cffEnabled}
                        select
                        name={'client'}
                        value={initialPrefs.cff?.client ?? clients[0]?.id ?? ''}
                        variant="outlined"
                        size="small"
                        onChange={onCFFChange}
                        slotProps={{
                          select: { native: true },
                        }}
                      >
                        {clients.map((client) => {
                          return (
                            <option value={client.id} key={client.id}>
                              {client.name}
                            </option>
                          )
                        })}
                      </TextField>
                    }
                    label={t('default_client')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <TextField
                        sx={textFieldStyle}
                        disabled={!cffEnabled}
                        select
                        name={'farm'}
                        value={initialPrefs.cff?.farm ?? farms[0]?.id ?? ''}
                        variant="outlined"
                        size="small"
                        onChange={onCFFChange}
                        slotProps={{
                          select: { native: true },
                        }}
                      >
                        {farms.map((farm) => {
                          return (
                            <option value={farm.id} key={farm.id}>
                              {farm.name}
                            </option>
                          )
                        })}
                      </TextField>
                    }
                    label={t('default_farm')}
                    labelPlacement="start"
                  />
                </Box>
              </FormControl>
            </Box>
            {/* Crop Color Preferences start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel sx={{ fontWeight: 'bold' }} component="legend">
                  {t('crop_colors')}
                </FormLabel>
                {cropColors.map((cc) => (
                  <Box my={1} key={cc.crop}>
                    <FormControlLabel
                      control={
                        <CropColorPicker
                          cropId={cc.cropId}
                          initialColor={cc.color}
                        />
                      }
                      label={cc.crop}
                      labelPlacement="start"
                    />
                  </Box>
                ))}
              </FormControl>
            </Box>
            {/* Automatic File Sync preferences start */}
            <Box my={1}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel sx={{ fontWeight: 'bold' }} component="legend">
                  {t('auto_sync')}
                </FormLabel>
                <Box my={1}>
                  <FormControlLabel
                    value="enable"
                    control={
                      <Switch
                        color="primary"
                        disabled={!canEditAutoSync}
                        onChange={onAutoSyncToggleChange}
                        checked={autoSyncEnabled}
                      />
                    }
                    label={t('auto_sync_enabled')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <TextField
                        sx={textFieldStyle}
                        disabled={!autoSyncEnabled || !canEditAutoSync}
                        select
                        name="sync_frequency_min"
                        value={initialPrefs.autosync?.sync_frequency_min ?? 60}
                        variant="outlined"
                        size="small"
                        onChange={onAutoSyncChange}
                        slotProps={{
                          select: { native: true },
                        }}
                      >
                        {[
                          <option value={30} key={30}>
                            30
                          </option>,
                          <option value={60} key={60}>
                            60
                          </option>,
                        ]}
                      </TextField>
                    }
                    label={t('sync_frequency_min')}
                    labelPlacement="start"
                  />
                </Box>
                <Box my={1}>
                  <FormControlLabel
                    control={
                      <TextField
                        sx={textFieldStyle}
                        disabled={!autoSyncEnabled || !canEditAutoSync}
                        name="layer_processing_start_hour"
                        select
                        value={
                          initialPrefs.autosync?.layer_processing_start_hour ??
                          0
                        }
                        variant="outlined"
                        size="small"
                        onChange={onAutoSyncChange}
                        slotProps={{
                          select: { native: true },
                        }}
                      >
                        {Array.from({ length: 24 }, (_, i) => i).map((item) => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))}
                      </TextField>
                    }
                    label={t('layer_processing_start_hour')}
                    labelPlacement="start"
                  />
                </Box>
              </FormControl>
            </Box>
          </FormGroup>
        </FormControl>
      </Box>
    )
  } else {
    return <></>
  }
}
