import { styled } from '@mui/system'
import {
  ArrowDropDown as DropDownIcon,
  ArrowDropUp as DropUpIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material'
import { H } from 'components/layout'
import { getHarvestStatusIcon } from 'common/c'
import { Box } from '@mui/material'

export default function HarvestExpander(props) {
  const { title, onClick, children, open = false, status } = props

  const { t } = useTranslation()

  return (
    <>
      <StyledRoot>
        <StyledItem
          style={{
            overflow: 'hidden',
            flexGrow: 1,
            flexBasis: 0,
            flexDirection: 'row',
            flexShrink: 1,
            textOverflow: 'ellipsis',
            flexWrap: 'wrap',
            whiteSpace: 'nowrap',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <StyledHeader
              onClick={onClick}
              open={open}
              style={{ whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}
            >
              <StyledItem>{getHarvestStatusIcon(status)}</StyledItem>
              {title}
            </StyledHeader>
            {open ? (
              <StyledItem>
                <StyledSettingsIcon
                  id="harvest-settings-button"
                  onClick={(e) => props.onSettingClick(e.currentTarget)}
                  size="small"
                  style={{ height: '0.7em' }}
                  titleAccess={`${t('edit')} ${t('harvest')} `}
                  aria-controls={
                    props.settingsOpen ? 'harvest-settings' : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={props.settingsOpen ? 'true' : undefined}
                />
              </StyledItem>
            ) : (
              <div
                style={{
                  height: 24,
                  width: 24,
                }}
              />
            )}
            {open ? (
              <DropUpIcon style={{ marginTop: '-2px' }} />
            ) : (
              <DropDownIcon style={{ marginTop: '-2px' }} />
            )}
          </Box>
        </StyledItem>
      </StyledRoot>
      {open ? <StyledContainer>{children}</StyledContainer> : null}
    </>
  )
}

const StyledRoot = styled(H)({
  margin: '3px 3px 0 20px',
  alignItems: 'center',
})

const StyledItem = styled(H.Item)({
  '&&': {
    marginLeft: '3px',
  },
})

const StyledHeader = styled('h1')({
  cursor: 'pointer',
  fontSize: '13px',
  fontWeight: 500,
  color: '#212529',
  display: 'flex',
})

const StyledContainer = styled('div')({
  // paddingLeft: '10px'
})

const StyledSettingsIcon = styled(SettingsIcon)({
  cursor: 'pointer',
})
