import { FormControlLabel, TextField, Alert, Box, Button } from '@mui/material'
import { styled } from '@mui/system'
//@ts-expect-error
import ModalContainer from 'components/modal/modal-container'
//@ts-expect-error
import ModalActions from 'components/modal/modal-actions'
//@ts-expect-error
import ModalHeader from 'components/modal/modal-header'
//@ts-expect-error
import { closeModal } from 'actions/app'
//@ts-expect-error
import { createClient } from 'api/client'
import { find } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'lodash-es'

export default function OCFFAddClientComponent() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const clients = useSelector((state) => get(state, 'client.collection', []))

  const [clientName, setClientName] = useState('')
  const [error, setError] = useState(false)

  const closePopup = () => dispatch(closeModal())

  const onChangeClient = (e) => setClientName(e.target.value)

  const firstUpdate = useRef(true)

  useEffect(() => {
    //To fix the error, seeing the item exists message on edit item
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    if (
      clients &&
      find(clients, ['name', clientName]) !== undefined &&
      clientName !== ''
    ) {
      setError(true)
    } else {
      setError(false)
    }
  }, [clientName])

  const addClient = useCallback(() => {
    dispatch(createClient({ name: clientName }))
  }, [clientName])

  return (
    <ModalContainer>
      <ModalHeader>{t('add_client')}</ModalHeader>
      <Box sx={{ my: '10px' }} style={{ display: error ? 'block' : 'none' }}>
        {error ? (
          <Alert severity="warning">
            {t('item_name_exists', {
              item: t('client'),
            })}
          </Alert>
        ) : null}
      </Box>
      <StyledForm>
        <FormControlLabel
          control={
            <TextField
              id="client-name"
              name="clientName"
              variant="outlined"
              size="small"
              placeholder={t('client_name')}
              sx={{ marginLeft: '10px' }}
              value={clientName}
              onChange={onChangeClient}
              helperText={
                clientName.length >= 40
                  ? t('input_character_limit', {
                      character_count: 40,
                      field_name: t('client_name'),
                    })
                  : ''
              }
              error={clientName.length >= 40}
              slotProps={{
                htmlInput: { maxLength: 40 },
              }}
            />
          }
          label={t('client')}
          labelPlacement="start"
        />
      </StyledForm>
      <ModalActions>
        <Button variant="contained" color="secondary" onClick={closePopup}>
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={addClient}
          disabled={!clientName.length || error}
        >
          {t('add')}
        </Button>
      </ModalActions>
    </ModalContainer>
  )
}

const StyledForm = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '& > *:not(:last-child)': {
    marginBottom: '1em',
  },
})
