import { styled } from '@mui/system'
import { colors } from 'components/foundation'

export default function InfoLine(props) {
  const { label, icon, value } = props

  return (
    <StyledRoot>
      <StyledLabel>{label}:</StyledLabel>
      <StyledIcon>{icon}</StyledIcon>
      <StyledValue>{value}</StyledValue>
    </StyledRoot>
  )
}

const StyledRoot = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'clamp(60px, calc((100vw + 100vh)/2 * 0.15), 160px)',
})

const StyledLabel = styled('div')({
  color: colors.brand,
  fontSize: 'clamp(10px, calc((100vw + 100vh)/2 * 0.01), 10px)',
})

const StyledIcon = styled('div')({
  height: '15px',
  cursor: 'pointer',
  verticalAlign: 'middle',
  lineHeight: '15px',
  '& svg': {
    width: '0.6em',
    height: '0.6em',
    color: colors.brand,
  },
})

const StyledValue = styled('div')({
  color: colors.brand,
  fontSize: 'clamp(10px, calc((100vw + 100vh)/2 * 0.01), 10px)',
  fontWeight: 600,
})
