import { styled } from '@mui/system'
import { Box, Button } from '@mui/material'
import SearchField from 'components/search-field'
import { DataGrid } from '@mui/x-data-grid'
import { switchClient } from 'api/user'
import { removeAccessibleAccount } from 'api/tenant'

export default function UserAccount({ farms = [], onUse }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')

  const _onUse = useCallback((tenantId) => {
    dispatch(switchClient(tenantId))
    onUse()
  }, [])

  const onLeave = useCallback((tenantId, userId) => {
    dispatch(removeAccessibleAccount(tenantId, userId))
  }, [])

  const onSearch = useCallback(
    (value) => {
      setSearchValue(value)
    },
    [searchValue],
  )

  const filterFarms = (searchValue, farm) => {
    if (searchValue) {
      return farm.name?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
    }

    return true
  }

  const columns = [
    {
      field: 'farmName',
      headerName: t('farm'),
      minWidth: 400,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <StyledButton
              variant="contained"
              color="neutral"
              onClick={() => onLeave(params.row.tenantId, params.row.userId)}
            >
              {t('leave')}
            </StyledButton>
            <StyledButton
              variant="contained"
              color="neutral"
              onClick={() => _onUse(params.row.tenantId, params.row.userId)}
            >
              {t('visit')}
            </StyledButton>
          </>
        )
      },
    },
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        height: '80%',
      }}
      width={1}
    >
      <h1>{t('accessible_accounts')}</h1>
      <SearchField
        sx={{ width: '100%' }}
        name={searchValue}
        placeholder={t('search')}
        onSearch={onSearch}
      />
      <div
        style={{
          flexGrow: 1,
          height: '100%',
          width: '100%',
          maxHeight: '800px',
          marginTop: '2rem',
        }}
      >
        <DataGrid
          autoPageSize
          rows={farms
            .map((f) => ({ ...f, id: f.tenantId }))
            .filter((f) => filterFarms(searchValue, f))}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f8f8f9',
              textTransform: 'uppercase',
            },
          }}
          pageSizeOptions={[10]}
        />
      </div>
    </Box>
  )
}

const StyledButton = styled(Button)({
  '&:not(:last-child)': {
    marginRight: '0.5em',
  },
})
