import { styled } from '@mui/system'
import PropTypes from 'prop-types'

export default function ContentLeft({ children }) {
  return <StyledContainer>{children}</StyledContainer>
}

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
})

ContentLeft.Content = function ({ children }) {
  return <StyledContent>{children}</StyledContent>
}

ContentLeft.Content.displayName = 'ContentLeft Content'

const StyledContent = styled('div')({
  flexGrow: 4,
})

ContentLeft.Content.propTypes = {
  children: PropTypes.node.isRequired,
}

ContentLeft.SideList = function ({ children }) {
  return <StyledSide>{children}</StyledSide>
}

ContentLeft.SideList.displayName = 'ContentLeft Side List'

const StyledSide = styled('div')({
  flexGrow: 1,
  marginLeft: '4px',
  width: 'calc(10% - 20px)',
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'flex-start',
})

ContentLeft.SideItem = function SideItem({ children }) {
  return <StyledSideItem>{children}</StyledSideItem>
}

ContentLeft.SideItem.propTypes = {
  children: PropTypes.node.isRequired,
}

const StyledSideItem = styled('div')({
  '& + &': {
    marginTop: '4px',
  },
})
