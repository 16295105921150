import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControlLabel,
  Grid2,
  TextField,
  Typography,
} from '@mui/material'
import Gravatar from 'react-gravatar'
import { useSelector, useDispatch } from 'react-redux'
import { editTenant } from 'api/tenant'
import { showModal } from 'actions/app'
import UserPreference from './user-preference'

const typographyStyle = {
  width: '50ch',
  paddingLeft: '10px',
  fontSize: 18,
  fontWeight: 500,
  letterSpacing: '0em',
  textAlign: 'left',
  fontFamily: 'Roboto',
}

export default function UserDetails({ onScroll }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.account['singleton'])
  const [editing, setEditing] = useState(false)
  const [formFields, setFormFields] = useState({
    fullName: user.fullName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    streetAddress: user.streetAddress,
    postalCode: user.postalCode,
  })

  const { t } = useTranslation()

  const fields = [
    { label: t('full_name'), fieldName: 'fullName' },
    { label: t('email'), fieldName: 'email' },
    { label: t('phone_number'), fieldName: 'phoneNumber' },
    { label: t('street_address'), fieldName: 'streetAddress' },
    { label: t('postal_code'), fieldName: 'postalCode' },
  ]

  useEffect(() => {
    const element = document.getElementsByClassName('MuiDialog-paper')[0]
    if (element) {
      element.style.maxWidth = 'none'
    }
  }, [])

  const handleInputChange = (e) => {
    if (e) {
      let { name, value } = e.target
      setFormFields((formFields) => ({
        ...formFields,
        [name]: value,
      }))
    }
  }

  const saveInput = () => {
    dispatch(
      editTenant({
        fullName: formFields.fullName,
        phoneNumber: formFields.phoneNumber,
        streetAddress: formFields.streetAddress,
        postalCode: formFields.postalCode,
      }),
    ).then(() => {
      dispatch(showModal(<UserDetails />))
    })
  }

  const openModifyPrefs = () => {
    dispatch(showModal(<UserPreference />))
  }

  return (
    // </>
    <Box
      px={3}
      py={4}
      sx={{
        display: 'flex',
        maxHeight: '70vh',
        margin: 'auto',
        overflowY: 'auto',
        padding: '2rem',
        maxWidth: '60vw',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      onScroll={onScroll}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}
      >
        <Gravatar
          email={formFields.email}
          size={100}
          default="identicon"
          protocol="https://"
        />
        <br />

        {editing ? (
          <div>
            {fields.map(({ label, fieldName }) => (
              <FormControlLabel
                key={fieldName}
                label={label}
                labelPlacement="start"
                sx={{ width: '100%' }}
                control={
                  <TextField
                    fullWidth
                    disabled={fieldName === 'email'}
                    type={
                      fieldName === 'email'
                        ? 'email'
                        : fieldName === 'phoneNumber'
                          ? 'tel'
                          : 'text'
                    }
                    margin="normal"
                    name={fieldName}
                    variant="outlined"
                    defaultValue={formFields[fieldName]}
                    onChange={handleInputChange}
                    sx={{
                      width: '50ch',
                      paddingLeft: '10px',
                    }}
                  />
                }
              />
            ))}
            <Box textAlign={'center'}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                startIcon={<SaveIcon />}
                onClick={() => {
                  saveInput()
                  setEditing(false)
                }}
                sx={{ marginTop: '1rem' }}
              >
                {t('save_profile')}
              </Button>
            </Box>
          </div>
        ) : (
          <div>
            {fields.map(({ label, fieldName }) => (
              <FormControlLabel
                key={fieldName}
                label={label}
                labelPlacement="start"
                sx={{ width: '100%', padding: '10px' }}
                control={
                  <Typography sx={typographyStyle}>
                    {formFields[fieldName]}
                  </Typography>
                }
              />
            ))}
            <Box textAlign={'center'}>
              <Button
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => setEditing(true)}
                size="large"
              >
                {t('edit_profile')}
              </Button>
            </Box>
          </div>
        )}
        <br />
      </div>
      <Grid2 size={12} align={'center'}>
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => openModifyPrefs(true)}
          size="large"
        >
          {t('modify_notification_preferences')}
        </Button>
      </Grid2>
    </Box>
  )
}
