import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

export default function EUSAScreen({ onScroll }) {
  useEffect(() => {
    const element = document.querySelector('.MuiDialog-paper')
    if (element) {
      element.style.maxWidth = 'none'
    }
  }, [])

  return (
    <Box
      px={3}
      py={4}
      sx={{
        height: '65vh',
        margin: 'auto',
        overflowY: 'auto',
        padding: '2rem',
        width: '55vw',
      }}
      onScroll={onScroll}
    >
      <Typography align="center" component="p" variant="h4">
        <b>End User Service and License Agreement</b>
      </Typography>
      <Typography align="center" component="p" variant="h5">
        <b>
          FarmTRX™ Web and Mobile Application End User Service and License
          Agreement and Warranties
        </b>
      </Typography>
      <Typography align="left" component="p">
        This End User Service and License Agreement (the &quot;<b>Agreement</b>
        &quot;) is a binding agreement between Troo Corporation (&quot;
        <b>Supplier</b>&quot;), and you (either: i) an individual consumer; ii)
        a customer of Supplier or reseller of FarmTRX™ products (&quot;
        <b>Reseller</b>&quot;); or iii) an employee or authorized representative
        of a customer or Reseller of Supplier (&quot;<b>You</b>&quot;). This
        Agreement governs Your use of the internet-delivered online FarmTRX™
        web application, including both the free and premium subscription
        (&quot;
        <b>Web App</b>&quot;), and/or the FarmTRX™ mobile application (&quot;
        <b>Mobile App</b>&quot;), and any of the services or functionality for
        passing data between these applications (the Web App, Mobile App and
        services obtained through them are together defined as the &quot;
        <b>Services</b>&quot;). Please read this Agreement carefully before
        accessing the Web App, via tablet or computer, or before downloading or
        using the Mobile App on Your mobile phone. In consideration for using
        the Services, You agree to comply and ensure that users of Your
        account(s) comply with the terms and conditions of this Agreement.
        <br />
        <br />
        <b>1. License.</b> Supplier grants You a revocable, non-exclusive,
        non-transferable, limited license to download, install, and/or use the
        Services for Your personal and internal business purposes strictly in
        accordance with this Agreement.
        <br />
        <b>2. Your Account.</b> Your use of the Services requires that You have
        an account with Supplier for the Web App and/or Mobile App, as
        applicable, and agree to the terms of this Agreement. Supplier shall
        maintain a separate account for You which shall contain such information
        as is reasonably necessary to use the Services and permit Supplier (or
        Reseller) to invoice You for the Services, if applicable. Supplier may
        use this data to contact You if there is important information with
        respect to the Services.
        <br />
        <b>3. Consent to Use of Data.</b> You agree that Supplier may collect
        and use technical data and related information—including but not limited
        to technical information about Your device, system and application
        software, and peripherals—that is gathered periodically to facilitate
        the provision of software updates, product support, and other assistance
        to You (if any) related to the Services. Supplier may use this
        information, if it is in a form that does not personally identify You,
        to improve its products or to provide services or technologies to You.
        Supplier acknowledges that You are the sole owner of any data generated
        from Your FarmTRX™ devices and such data will not be shared or
        exchanged with any third party without Your prior consent.
        <br />
        <b>4. Privacy and Confidentiality.</b> Your personal data and account
        data given to Supplier are considered confidential. Supplier&squo;s
        privacy policy is subject to its obligation to comply with applicable
        laws and lawful government requests, to operate its business in
        compliance with the requirements of law, and to protect its users or
        itself. Supplier reserves the right at any time to amend this Agreement
        or privacy policy upon giving thirty (30) days&squo; notice to You,
        which notice may be sent by email. Your account administrator, if
        applicable, will be or has been, assigned an account name and password
        for purpose of creating and managing Your account and users. You are
        responsible for maintaining the confidentiality of Your password and
        Your account and are fully responsible for all activities that occur
        under Your account(s), including from any users (individuals or
        enterprises) other than Yourself. You shall immediately notify Supplier
        of any breach of security.
        <br />
        <b>5. Back Up of Data.</b> You acknowledge and agree that Supplier will
        need to regularly back up data files to be able to recover from a system
        failure. Supplier will keep such back-up files in a location that
        Supplier takes commercially reasonable efforts to maintain secure. To
        the extent that any of the data is personal information, You shall be
        responsible for ensuring that You have obtained all consents from the
        owner of such personal information to permit Supplier to provide the
        Services under this Agreement.
        <br />
        <b>6. Intended Purpose.</b> You or any other user shall access and use
        the Services solely for its intended purpose. Any other use is strictly
        prohibited.
        <br />
        <b>7. Changes to Agreement.</b> Supplier reserves the right to modify
        this Agreement at any time and for any reason. Supplier may post the
        most current version of this Agreement on its website. If Supplier makes
        material changes to this Agreement, You will receive notification via
        the Mobile App and/or Web App. Notwithstanding the foregoing, You are
        responsible for complying with the updated terms posted online at
        Supplier&squo;s website even if these updated terms appear online at
        Supplier&squo;s website before being posted on the Mobile App and/or Web
        App. Your continued use of the Services after Supplier publishes notice
        of changes to this Agreement indicates Your consent to the updated
        terms.
        <br />
        <b>8. Termination.</b> Supplier may terminate Your use of the Services:
        <br />
        <b>a.</b> Immediately if You or anyone accessing the Services through
        You is in breach of this Agreement;
        <br />
        <b>b.</b> On thirty (30) days&squo; notice if Your account is inactive,
        meaning that no devices or users are registered to use the Services, for
        a period of 12 months;
        <br />
        <b>c.</b> Upon termination of an agreement that entitles a Reseller or a
        distributor to resell the Services to You. Without prejudice to any
        other remedies which either party may have in respect of any breach of
        this Agreement, the parties hereby agree that, upon termination of this
        Agreement for any reason in accordance with the terms hereof, neither
        party shall be entitled to any damages or other compensation from the
        other as a result of such termination.
        <br />
        <b>9. Your Representations.</b> By using the Services, You agree to be
        bound by the terms of this Agreement. You acknowledge and agree that:
        <br />
        <b>a.</b> In order to use the Services, You or Your employer have
        purchased or leased one or more devices from Supplier or Reseller or
        some other authorized party, have had the devices installed by an
        industry qualified installer, and entered into an agreement with a
        Reseller or other party to have access to a communications network that
        will transmit Your data to Supplier;
        <br />
        <b>b.</b> Neither the device(s) nor the communications network(s) that
        transmit(s) the data are the responsibility of Supplier and any failure
        in the performance of a device or the communications network, including
        the failure to transmit data to Supplier&squo;s servers in a timely and
        reliable manner, may disrupt the delivery of the Services;
        <br />
        <b>c.</b> You may not transfer or resell Your use of or access to the
        Services to any third party;
        <br />
        <b>d.</b> You are responsible for all activities that occur under Your
        account;
        <br />
        <b>e.</b> You will not create an unusually large burden on the Services
        without Supplier&squo;s prior approval, nor will You: engage in posting,
        uploading, transmitting, or otherwise making available information or
        software containing a virus, lock, key, bomb, worm, or other harmful or
        debilitating feature, impersonate any entity, forge or otherwise
        manipulate identifiers, port scan a person&squo;s electronic device
        without that person&squo;s consent, distribute mass or unsolicited
        email, or otherwise generate levels of traffic sufficient to impede
        others&squo; ability to send or retrieve network information;
        <br />
        <b>f.</b> Supplier reserves the right at any time and from time-to-time
        to modify or discontinue, temporarily or permanently, the Services (or
        any part thereof) with or without notice. You agree that Supplier shall
        not be liable to You or to any third party for any modification,
        suspension or discontinuance of the Services;
        <br />
        <b>g.</b> Provided that You have been authorized by Supplier or
        Reseller, You may add devices, users or additional services to Your
        account, all or some of which may require payment of additional fees;
        <br />
        <b>h.</b> Supplier has no obligation whatsoever to furnish maintenance
        and support services to You. Mobile applications are inherently subject
        to bugs and potential incompatibility with other applications, software
        and hardware. You should not use the Services for any applications in
        which failure could cause any significant damage or injury to persons or
        tangible or intangible property;
        <br />
        <b>i.</b> You may not reverse engineer, decompile, disassemble or create
        derivative works of the Mobile App and/or Web App, except and only to
        the extent that the right to do so is mandated under applicable law;
        <br />
        <b>j.</b> You may not rent, lease, sell, resell, redistribute or
        sublicense the Services to any other individual or entity for any
        reason; and
        <br />
        <b>k.</b> The Mobile App is only available for supported devices and
        might not work on every device. Determining whether Your device is a
        supported or compatible device for use of the Mobile App is solely Your
        responsibility, and downloading the Mobile App is done at Your own risk.
        Supplier does not represent or warrant that the Mobile App and Your
        device are compatible or that the Mobile App will work on Your device.
        <br />
        <b>10. Consent to Electronic Communications and Solicitation.</b> By
        downloading the Mobile App and/or using the Services, You authorize
        Supplier to send You (including via email and push notifications)
        information regarding the Services and the Mobile App, such as: (a)
        notices about Your use of the Services and the Mobile App, including
        notices of violations of use; and (b) updates to the Services and Mobile
        App and new features or products.
        <br />
        <b>11. Proprietary Rights & Intellectual Property.</b> All intellectual
        property rights in the Mobile App, Web App, Services, and user
        documentation are owned by Supplier or its suppliers and are protected
        by law, including but not limited to patent, copyright, trade secret,
        and trademark law, as well as other applicable laws and International
        Treaty Provisions. The structure, organization and code of the Mobile
        App, Web App and Services are the valuable trade secrets and
        confidential information of Supplier and its suppliers. You shall not
        remove any product identification, patent information, copyright notices
        or proprietary restrictions from the Mobile App.
        <br />
        <b>12. Warranty.</b> Supplier warrants that the functionality of the
        Services shall perform materially in accordance with Supplier&squo;s
        online help, and (ii) the functionality of the Services will not be
        materially decreased during a subscription term.
        <br />
        <b>13. No Warranty.</b> YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK.
        THE SUPPLIER MAKES COMMERCIALLY REASONABLE EFFORTS TO PROVIDE QUALITY OF
        SERVICES, HOWEVER, EXCEPT FOR THE WARRANTY SPECIFICALLY SET OUT ABOVE,
        THE SERVICES, AND ANY CONTENT OR DATA OBTAINED OR ACCESSED THROUGH THE
        SERVICES, ARE BEING PROVIDED BY SUPPLIER OR RESELLER ON AN &quot;AS
        IS&quot; AND “AS AVAILABLE” BASIS AND WITHOUT SPECIFIC REPRESENTATIONS,
        WARRANTIES, OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED. TO THE
        FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, SUPPLIER, ITS
        OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, SUPPLIERS, ADVERTISERS,
        AGENTS, AND RESELLERS DISCLAIM ALL WARRANTIES, CONDITIONS AND
        REPRESENTATIONS EXPRESS, IMPLIED OR STATUTORY OR THOSE ARISING FROM
        STATUTE OR THE USAGE OF TRADE, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, AND FITNESS FOR
        A PARTICULAR PURPOSE, AND ALL WARRANTIES OF ADEQUACY, ACCURACY OR
        COMPLETENESS OF DATA. SUPPLIER, ITS SUPPLIERS AND RESELLERS DO NOT
        WARRANT THAT (i) SERVICES WILL MEET YOUR OR YOUR USERS&squo;
        REQUIREMENTS, (ii) SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR
        ERROR-FREE, (iii) DATA OBTAINED FROM USE OF SERVICES WILL BE ACCURATE OR
        RELIABLE, (iv) THAT DEFECTS WILL BE CORRECTED, OR THAT (v) SERVER(S) ON
        WHICH SERVICE IS HOSTED ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
        YOU ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR OBTAINING ACCESS TO AND
        MAINTAINING ALL COMMUNICATION NETWORK, TELEPHONE, COMPUTER HARDWARE AND
        OTHER EQUIPMENT NEEDED TO ACCESS AND USE THE SERVICES, AND ALL CHARGES
        RELATED THERETO. YOU ASSUME TOTAL RESPONSIBILITY AND RISK FOR YOUR USE
        OF THE SERVICES AND YOUR RELIANCE THEREON.
        <br />
        <b>14. No Warranties as to Third-Party Components.</b> IN NO EVENT DOES
        SUPPLIER PROVIDE ANY WARRANTY OR REPRESENTATIONS WITH RESPECT TO ANY
        THIRD PARTY HARDWARE OR SOFTWARE WITH WHICH THE MOBILE APP OR SERVICES
        IS DESIGNED TO BE USED, AND SUPPLIER AND ITS SUPPLIERS DISCLAIM ALL
        LIABILITY WITH RESPECT TO ANY FAILURES THEREOF.
        <br />
        <b>15. Limitation of Liability.</b> IN NO EVENT SHALL SUPPLIER, ITS
        SUPPLIERS, EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, OR RESELLERS, BE
        LIABLE IN ANY WAY WHATSOEVER UNDER THIS AGREEMENT OR IN ANY WAY RELATED
        TO THE SERVICES OR DEVICES, FOR ANY INDIRECT, SPECIAL, INCIDENTAL,
        EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES OR FOR ANY LOST PROFITS OR
        BUSINESS REVENUE, LOST BUSINESS, LOSS OF DATA, FAILURE TO REALIZE
        EXPECTED SAVINGS, OR OTHER COMMERCIAL OR ECONOMIC LOSS OF ANY KIND
        WHATSOEVER REGARDLESS OF HOW SUCH COSTS, LOSSES OR DAMAGES WERE CAUSED
        OR AROSE AND WHETHER OR NOT SUCH COSTS, LOSSES OR DAMAGES ARE/WERE
        FORESEEABLE OR SUPPLIER, ITS SUPPLIERS, EMPLOYEES, AGENTS, OFFICERS OR
        DIRECTORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH COSTS, LOSSES OR
        DAMAGES. SUPPLIER SHALL ONLY BE LIABLE FOR DIRECT DAMAGES PROVIDED THAT
        SUPPLIER&squo;S TOTAL AGGREGATE CUMULATIVE LIABILITY TO YOU FOR ALL
        CLAIMS FOR COSTS, LOSSES AND DAMAGES HEREUNDER FOR ANY CAUSE WHATSOEVER
        SHALL NOT EXCEED THE LESSER OF $10,000 OR THE AMOUNTS RECEIVED BY
        SUPPLIER FROM YOU FOR SERVICES PROVIDED TO YOU DURING THE SIX MONTH
        PERIOD PRECEDING THE DATE OF THE INITIAL CLAIM. YOUR SOLE REMEDY FOR
        DISSATISFACTION WITH THE SERVICES IS TO STOP USING THE SERVICES. THE
        SOLE AND EXCLUSIVE MAXIMUM LIABILITY OF SUPPLIER FOR ALL DAMAGES,
        LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT, TORT (INCLUDING,
        WITHOUT LIMITATION, NEGLIGENCE), OR OTHERWISE) SHALL BE THE TOTAL
        AMOUNT, IF ANY, PAID DIRECTLY TO SUPPLIER BY YOU, FOR ACCESS TO THE
        SERVICES. THE FOREGOING LIMITATIONS AND EXCLUSIONS OF LIABILITY SHALL
        APPLY REGARDLESS OF WHETHER THE CLAIM AROSE IN CONTRACT INCLUDING A
        FUNDAMENTAL BREACH, TORT INCLUDING NEGLIGENCE, STRICT LIABILITY OR UNDER
        STATUTE.
        <br />
        <b>16. Indemnification.</b> You agree to indemnify, defend and hold
        Supplier, its suppliers, officers, directors, employees, agents and
        representatives, harmless from and against any and all claims, damages,
        losses, costs (including reasonable attorneys&squo; fees), or other
        expenses that arise directly or indirectly out of or from (a) Your
        violation of this Agreement; (b) Your use of the Services or devices;
        (c) any act or omission of or by You or any of Your employees,
        representatives, agents or end users; or (d) Your violation of the
        rights of any third party.
        <br />
        <b>17. Assignment.</b> You may not assign or otherwise transfer Your
        rights and obligations under this Agreement except with the prior
        written consent of Supplier, such consent not to be unreasonably
        withheld. Any prohibited assignment shall be null and void. Failure to
        comply with any provision of this Agreement shall not constitute a
        waiver of any term hereof.
        <br />
        <b>18. Governing Law and Jurisdiction.</b> This Agreement is governed by
        and interpreted in accordance with the laws of the Province of Ontario,
        Canada. Any dispute, controversy or claim arising from or connected with
        this Agreement, including one regarding the existence, validity or
        termination of this Agreement or the consequences of its nullity or
        relating to any non-contractual or other dispute arising from or
        connected with this Agreement shall be referred to and finally resolved
        by the courts of the Province of Ontario, Canada.
        <br />
        <b>19. Entire Agreement.</b> This Agreement (including any addendum or
        amendment to this Agreement which is included with the Services) is the
        entire agreement between You and Supplier relating to the Services), and
        it supersedes all prior or contemporaneous oral or written
        communications, proposals and representations with respect to the
        Services) or any other subject matter covered by this Agreement. If any
        provision of this Agreement is held by a court of competent jurisdiction
        to be contrary to law, such provision will be changed and interpreted so
        as to best accomplish the objectives of the original provision to the
        fullest extent allowed by law and the remaining provision of the
        Agreement will remain in force and effect.
      </Typography>
    </Box>
  )
}
