import { ReactElement } from 'react'
import { styled } from '@mui/system'

export type LayerGroupProps = {
  children: ReactElement
}
export type LayerGroupHeaderProps = {
  children: ReactElement
}
export type LayerGroupItemProps = {
  children: ReactElement
}

export default function LayerGroup({ children }: LayerGroupProps) {
  return <StyledRoot>{children}</StyledRoot>
}

LayerGroup.Header = function ({ children }: LayerGroupHeaderProps) {
  return <StyledHeader>{children}</StyledHeader>
}

LayerGroup.Items = function ({ children }: LayerGroupItemProps) {
  return <StyledList>{children}</StyledList>
}

LayerGroup.Item = function ({ children, show = true, onClick }) {
  return show ? (
    <StyledListItem onClick={() => onClick()}>{children}</StyledListItem>
  ) : null
}

const StyledRoot = styled('div')({
  margin: '0 0 0 12px',
})

const StyledHeader = styled('h1')({
  fontSize: '11px',
  fontWeight: 700,
  color: '#70787d',
  opacity: 0.8,
  textTransform: 'uppercase',
})

const StyledList = styled('ul')({
  listStyleType: 'none',
  paddingLeft: 0,
  margin: 0,
})

const StyledListItem = styled('li')({
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  '& + &': {
    marginTop: '6px',
  },
})
