import { styled } from '@mui/system'
import loadingAnimation from 'images/loading.gif'

export function Loading(props) {
  return (
    <StyledDiv $fullscreen={props.$fullscreen}>
      <StyledImg src={loadingAnimation} />
      {props.children}
    </StyledDiv>
  )
}

const StyledDiv = styled('div')((props) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  ...(props.$fullscreen && {
    position: 'absolute',
    top: 0,
    left: 0,
  }),
}))

const StyledImg = styled('img')({
  width: '20rem',
})
