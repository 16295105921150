import { styled } from '@mui/system'
import { uniqueId, noop } from 'lodash-es'
import useSplitMap from 'hooks/use-split-map'
import { defaultBackground, defaultShadow } from 'components/foundation'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

/**
 * Create a Map.
 *
 * @param {object}   props
 * @param {string}   props.id      An identifier for the map. If none is provided one will get genereted. In either case
 *                                 the id will be available on props.onReady function.
 * @param {function} props.onReady When component is rendered and ready this function will run with the map's id as an argument.
 */
const FarmtrxMapB = React.forwardRef((props, ref) => {
  const [id, setId] = useState(props.id)

  const { map_b } = useSplitMap()

  useEffect(() => {
    if (map_b) {
      map_b.setTarget(ref.current)
      setId(uniqueId('map-'))
    }
  }, [map_b])

  useEffect(() => {
    if (id) {
      props.onReady(id)
    }
  }, [id])

  return (
    <StyledWrapper>
      <StyledMap id={id} ref={ref}>
        {props.children}
      </StyledMap>
    </StyledWrapper>
  )
})

FarmtrxMapB.displayName = 'OpenLayersMap'

FarmtrxMapB.propTypes = {
  id: PropTypes.string,
  onReady: PropTypes.func,
  children: PropTypes.node,
}

FarmtrxMapB.defaultProps = { onReady: noop }

export default FarmtrxMapB

const StyledWrapper = styled('div')({
  flex: 1,
  padding: '4px',
  width: '100%',
  borderRadius: '4px',
  ...defaultBackground,
  ...defaultShadow,
})

const StyledMap = styled('div')({
  height: '100%',
  width: '100%',
  position: 'relative',
})
