import Button from '@mui/material/Button'
import { deleteField } from 'api/field'

export default function DeleteField(props) {
  const { fieldName, id } = props

  const dispatch = useDispatch()

  const { t } = useTranslation()

  const confirmDeleteField = useCallback(() => {
    dispatch(deleteField(id))
  })

  return (
    <>
      <label style={{ color: 'black' }}>
        {t('delete_field_warning', { field_name: fieldName })}
      </label>
      <Button
        color="primary"
        variant="contained"
        onClick={confirmDeleteField}
        sx={{ width: '100%', marginTop: '4px' }}
      >
        {t('delete')}
      </Button>
    </>
  )
}
