import { styled } from '@mui/system'
import PropTypes from 'prop-types'
import { FormGroup } from './foundation'

const ReadonlyField = ({ defaultValue = '', label }) => {
  const labelEl = label ? <label>{label}</label> : null

  return (
    <StyledFormGroup>
      {labelEl}
      <span>{defaultValue}</span>
    </StyledFormGroup>
  )
}

ReadonlyField.displayName = 'ReadonlyField'

ReadonlyField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
}

const StyledFormGroup = styled(FormGroup)({
  width: '100%',
  '& > span': {
    display: 'block',
  },
})

export default ReadonlyField
