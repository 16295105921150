import { styled } from '@mui/system'
import { switchToSingle } from 'actions/app'
import { SingleScreen } from '@troo/farmtrx-icons'
import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

export default function SwitchSingle() {
  const dispatch = useDispatch()

  const onClick = useCallback(() => {
    dispatch(switchToSingle())
  }, [dispatch])

  return (
    <StyledLink href="#" onClick={onClick}>
      <SingleScreen />
    </StyledLink>
  )
}

const StyledLink = styled('a')({
  position: 'absolute',
  height: '27px',
  top: '10px',
  right: '20px',
  zIndex: 100,
  background: 'white',
  padding: '4px',
  borderRadius: '4px',
})
