import { styled } from '@mui/system'
import logo from 'images/farmtrx-logo.png'
import welcomeScreenImg from 'images/welcome-screen-harvester.png'
import { Box, Button, Checkbox, Grid2, FormControlLabel } from '@mui/material'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import EUSAScreen from 'components/eusa-screen'
import { eusaAccepted } from 'actions/user'
import { editUserAccount } from 'api/user'
import { closeModal, shouldShowDashboard } from 'actions/app'

export default function AcceptEUSAScreen() {
  const { t } = useTranslation()
  let navigate = useNavigate()
  const [acceptedEusa, setAcceptEusa] = useState(false)
  //const [readEusa, setReadEusa] = useState(false);
  const dispatch = useDispatch()
  const userData = useSelector((state) => state.account.user)
  const cropVarieties = useSelector((state) => state.cropVariety['collection'])
  const headers = useSelector((state) => state.headers.collection)
  const equipment = useSelector((state) => state.equipment.collection)
  const permissions = useSelector((state) => state.account.permissions)
  const [showPermissions, setShowPermissions] = useState({})

  useEffect(() => {
    setShowPermissions({
      skipWizard: permissions.includes('tenant:list'),
    })
  }, [permissions])

  const acceptEusa = (event) => {
    setAcceptEusa(event.target.checked)
  }

  const handleSkipWizard = () => {
    dispatch(closeModal())

    dispatch(shouldShowDashboard())
    localStorage.setItem('wizardActiveStep', null)
  }

  const farmId = useSelector((state) => state.farm.id)

  const navigateToRegWizardStepper = useCallback(() => {
    const date = Date.now()
    if (
      cropVarieties.length > 0 &&
      headers.length > 0 &&
      equipment.length > 0 &&
      farmId != undefined
    ) {
      userData.eusaDate = date
      // Clear any existing error modal due to missing data from a failure to fetch the tenant for example.
      dispatch(closeModal())
      dispatch(editUserAccount(userData))
      dispatch(shouldShowDashboard())
    } else if (farmId) {
      if (userData?.id) {
        userData.eusaDate = date
        dispatch(editUserAccount(userData))
      } else {
        dispatch(eusaAccepted(date))
      }
      navigate('/wizardStepper')
    } else {
      dispatch(eusaAccepted(date))
      navigate('/welcomeScreen')
    }
  }, [cropVarieties, userData, farmId])

  useEffect(() => {
    if (farmId && userData?.eusaDate) {
      navigate('/wizardStepper')
    }
  }, [farmId, userData])

  /*const handleScroll = useCallback((e) => {
    const bottom =
      Math.floor(e.target.scrollHeight - e.target.scrollTop) <=
      e.target.clientHeight;
    if (bottom) {
      setReadEusa(true);
    }
  });*/

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid2 container spacing={2}>
        <Grid2 size={{ sm: 7, md: 6, lg: 6 }}>
          <Box mx={2} my={2}>
            <Box mx={10} my={6}>
              <img width="180" height="30" src={logo} />
            </Box>
            <Box mx={3} my={2}>
              <Grid2 container spacing={2}>
                <EUSAScreen />
                <Grid2 size={12}>
                  <Box>
                    <FormControlLabel
                      sx={{ width: '100%' }}
                      control={
                        <Checkbox onChange={acceptEusa} value={acceptedEusa} />
                      }
                      label={t('read_and_accept')}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={!acceptedEusa}
                        sx={{ borderRadius: '25px' }}
                        fullWidth
                        onClick={navigateToRegWizardStepper}
                      >
                        {t('continue_setup')}
                      </Button>
                      {showPermissions.skipWizard ? (
                        <Button color="primary" onClick={handleSkipWizard}>
                          {t('skip')}
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Grid2>
              </Grid2>
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={{ sm: 5, md: 6, lg: 6 }}>
          <Box>
            <StyledImg src={welcomeScreenImg} />
          </Box>
        </Grid2>
      </Grid2>
    </Box>
  )
}

const StyledImg = styled('img')({
  width: '49vw',
  height: '99vh',
})
