import { styled } from '@mui/system'
import { Button, Tooltip } from '@mui/material'

export function ExpandableToolbarButton(props) {
  return (
    <Tooltip title={props.label} placement="right" disableInteractive>
      <StyledContainer expanded={props.expanded} $active={props.$active}>
        <StyledButton
          expanded={props.expanded}
          $active={props.$active}
          disabled={props.disabled}
          onClick={props.onClick}
          startIcon={props.icon}
        >
          <span className="label">{props.label}</span>
        </StyledButton>
      </StyledContainer>
    </Tooltip>
  )
}

const StyledContainer = styled('div')((props) => ({
  backgroundColor: '#f2f2f2',
  width: props.expanded || props.$active ? '100%' : '32px',
  ...(!props.expanded &&
    props.$active && {
      '&&&': {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
      },
    }),
}))

const StyledButton = styled(Button)((props) => ({
  minWidth: 0,
  height: '32px',
  justifyContent: 'start',
  fontSize: '12px',
  fontWeight: 500,
  color: '#4a4a4a',
  width: props.expanded ? '100%' : 'fit-content',
  '& .MuiButton-startIcon': {
    marginLeft: '-2px',
  },
  '& svg path': {
    color: '#4a4a4a',
  },
  '& span.label': {
    display: props.expanded || props.$active ? 'inline' : 'none',
    whiteSpace: 'nowrap',
  },
  ...(props.$active && {
    position: 'relative',
    width: 'fit-content',
    backgroundColor: '#143157',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#004780',
      color: '#ffffff',
    },
    '& svg path': {
      stroke: '#ffffff',
      fill: 'none',
    },
  }),
  ...(props.disabled && {
    '& svg': {
      opacity: 0.26,
    },
  }),
}))
