import { styled } from '@mui/system'
import { noop } from 'lodash-es'
import { BlankLink } from 'components/foundation'
import { Close } from '@troo/farmtrx-icons'
import useOnClickOverlay from 'hooks/click-overlay'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'

export default function ModalInternal(props) {
  const { title, onClose = noop, children } = props

  const ref = useRef()

  const onCloseClicked = useCallback(() => {
    onClose()
  }, [onClose])

  useOnClickOverlay(ref, () => {
    onClose()
  })

  return (
    <Dialog open={true} onClose={onCloseClicked} aria-labelledby={title}>
      <DialogTitle id={title}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <StyledClose key="close-link" onClick={onCloseClicked}>
          <Close />
        </StyledClose>
      </DialogActions>
    </Dialog>
  )
}

ModalInternal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
}

const StyledClose = styled(BlankLink)({
  position: 'absolute',
  right: '12px',
  top: '10px',
})
