import { styled } from '@mui/system'
import { ReactElement } from 'react'

declare module 'components/modal/modal-actions.js' {
  export type ModalActionProps = {
    children: ReactElement<any, any>[] | ReactElement<any, any> | null
  }
}

export default function ModalActions(props: ModalActionProps) {
  return <StyledActions>{props.children}</StyledActions>
}

const StyledActions = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  paddingTop: '1em',
  '& > *': {
    marginLeft: '1em',
  },
})
