import { Alert, Box, Button } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import {
  getSubscriptionDetails,
  createSubscriptionPayment,
} from 'api/subscriptions'
import utils from '@farmtrx/utils'
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import { pick } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import { FarmtrxIconCircleCheck } from '@troo/farmtrx-icons'

export default function SubscriptionComponent() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const userPrefs = useSelector((state) => state.account.singleton.prefs)
  const subscriptions = useSelector((state) => state.account.subscriptions)

  const subscriptionPaymentFetched = useSelector(
    (state) => state.account.subscriptionPaymentFetched,
  )

  const [subscriptionDetails, setSubscriptionDetails] = useState([])
  const [payNowClicked, setPayNowClicked] = useState(false)

  useEffect(() => {
    dispatch(getSubscriptionDetails())
  }, [])

  useEffect(() => {
    if (subscriptions.length) {
      setSubscriptionDetails(subscriptions[0]['subscriptionPeriods'])
    }
  }, [subscriptions])

  const setCurrentStatusMsg = useCallback(() => {
    let status = ''
    //Check the status based on the latest record out of all the subscription periods
    if (subscriptionDetails.length) {
      let maxDate = new Date(
        Math.max(...subscriptionDetails.map((e) => new Date(e.startDate))),
      )
        .toISOString()
        .split('T')[0]
      let sp = subscriptionDetails.find((s) => s.startDate === maxDate)

      //Check if the Subscription Start date is beyond the current date as on when the user loads our application
      if (sp.subscriptionStatus === 'not_paid') {
        status = 'inactive'
      } else if (
        (sp.subscriptionStatus === 'payment_due' ||
          sp.subscriptionStatus === 'payment_due_final') &&
        new Date(Date.now()).toISOString().split('T')[0] > sp.startDate
      ) {
        status = 'inactive'
      }
      //Check if the subscription is due for payment in 30 days or 7 days as on when the user loads our application
      else if (
        (sp && sp.subscriptionStatus === 'payment_due') ||
        sp.subscriptionStatus === 'payment_due_final'
      ) {
        status = 'due'
      }
      //Check if the subscription is paid
      else if (
        sp.subscriptionStatus === 'paid' ||
        sp.subscriptionStatus === 'free_year' ||
        sp.subscriptionStatus === 'comped'
      ) {
        status = 'paid'
      }

      switch (status) {
        case 'due':
          return (
            <Alert severity="info">
              {t('payment_due_premium_status_message', {
                expiryDate: sp.startDate,
              })}
            </Alert>
          )
        case 'inactive':
          return (
            <Alert severity="warning">
              {t('inactive_premium_status_message')}
            </Alert>
          )
        default:
          return (
            <Alert severity="success" icon={<FarmtrxIconCircleCheck />}>
              {t('active_premium_status_message')}
            </Alert>
          )
      }
    }
  })

  const columns = [
    {
      field: 'subscription_period',
      headerName: t('subscription_period'),
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return `${`${params.row.startDate} - ${params.row.endDate}`}`
      },
    },
    {
      field: 'status',
      headerName: t('status'),
      minWidth: 400,
      flex: 1,
      renderCell: (params) => {
        if (
          params.row.subscriptionStatus === 'payment_due' ||
          params.row.subscriptionStatus === 'payment_due_final'
        ) {
          if (params.row.paymentInfo && params.row.paymentInfo.amountToPay) {
            return (
              <Button
                onClick={() => makePayment(params.row)}
                variant="contained"
                color="secondary"
                endIcon={<ArrowForwardIcon />}
                style={{ display: showSubscriptionActivationLink(params.row) }}
              >
                {`${t('pay_now')} (${params.row.paymentInfo.currency}${
                  params.row.paymentInfo.amountToPay
                })`}
              </Button>
            )
          } else {
            return (
              <Button
                color="secondary"
                sx={{
                  padding: '0',
                  textTransform: 'initial !important',
                  textAlign: 'left',
                  display: 'block',
                  whiteSpace: 'break-spaces',
                }}
              >
                {t(params.row.paymentInfo?.message)}
              </Button>
            )
          }
        } else {
          return t(params.row.subscriptionStatus)
        }
      },
    },
    {
      field: 'area_under_management',
      headerName: t('area_under_management'),
      minWidth: 400,
      flex: 1,
      headerClassName: 'data-grid--header',
      renderCell: (params) => {
        return (
          params &&
          userPrefs &&
          `${utils.convertAreaToPref
            .convertAreaToPref(
              params.row.harvestedArea,
              userPrefs?.['units']['area_in'],
            )
            .toFixed(1)} ${userPrefs?.['units']['area_in']}`
        )
      },
    },
  ]

  const showSubscriptionActivationLink = useCallback((row) => {
    if (subscriptionDetails.length) {
      if (row.partnerTenantId) {
        return 'none'
      } else {
        if (
          row.subscriptionStatus === 'free_year' ||
          row.subscriptionStatus === 'paid' ||
          row.subscriptionStatus === 'comped'
        ) {
          return 'none'
        } else if (
          row.subscriptionStatus === 'payment_due' ||
          row.subscriptionStatus === 'payment_due_final'
        ) {
          return 'inline-flex'
        }
      }
    }
  })

  function CustomNoRowsOverlaySubscriptions() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        {t('no_subscriptions_found')}
      </div>
    )
  }

  function makePayment(row) {
    setPayNowClicked(true)
    let finalObj = pick(row, ['id', 'tenantId', 'paymentInfo'])
    finalObj.subscriptionPeriodId = finalObj.id
    finalObj.id = uuidv4()
    dispatch(createSubscriptionPayment(finalObj))
  }

  useEffect(() => {
    if (
      subscriptionPaymentFetched &&
      Object.keys(subscriptionPaymentFetched).length &&
      payNowClicked
    ) {
      let url = `${subscriptionPaymentFetched.paymentLink}?client_reference_id=${encodeURIComponent(subscriptionPaymentFetched['id'])}`

      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url

      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      link.remove()
      setPayNowClicked(false)
    }
  }, [subscriptionPaymentFetched])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
        height: '80%',
      }}
      width={1}
    >
      {subscriptionDetails?.length ? setCurrentStatusMsg() : null}
      <div
        style={{
          flexGrow: 1,
          height: '100%',
          width: '100%',
          maxHeight: '800px',
          marginTop: '2rem',
        }}
      >
        <DataGrid
          autoPageSize
          slots={{
            noRowsOverlay: CustomNoRowsOverlaySubscriptions,
          }}
          rows={subscriptionDetails.map((i) => {
            return i
          })}
          columns={columns}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#f8f8f9',
              textTransform: 'uppercase',
            },
          }}
          pageSizeOptions={[10]}
        />
      </div>
    </Box>
  )
}
