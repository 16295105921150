import logo from 'images/farmtrx-logo.png'
import { Link } from 'react-router'
import { useTheme } from '@mui/material/styles'

export default function Logo() {
  const theme = useTheme()
  return (
    <Link
      style={{
        zIndex: theme.zIndex.drawer + 1,
        width: '250px',
        flex: '0 0 auto',
      }}
      to={{ pathname: '/' }}
      target="_blank"
    >
      <img width="100" height="17" src={logo} style={{ maxWidth: '100px' }} />
    </Link>
  )
}
