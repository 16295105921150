import { styled } from '@mui/system'
import { dirty, destroy as destroyForm } from 'actions/form'
import ReadonlyField from './readonly-field'
import { pick, isEmpty, uniqueId, isEqual, has, uniq } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

export default function Form(props) {
  const {
    children,
    formFocusFirst = false,
    submitClean = false,
    // oneLine = false,
  } = props
  const [model, setModel] = useState()
  const [initModel, setInitModel] = useState()

  const ref = useRef()
  let refFirstEl = useRef()
  const dispatch = useDispatch()

  const id = props.id || uniqueId()

  // init
  useEffect(() => {
    const ini = extractModel(props)
    setInitModel(Object.assign({}, ini))
    setModel(Object.assign({}, ini))

    if (formFocusFirst) {
      refFirstEl.focus()
    }
  }, [])

  // clean up
  useEffect(() => {
    return function cleanup() {
      dispatch(destroyForm(id))
    }
  }, [id])

  // dirty check
  const isDirty = useCallback(() => {
    return (
      !isEqual(model, initModel) ||
      (isEmpty(props.entity) && !isEqual(model, initModel))
    )
  }, [props.entity, model, initModel])

  useEffect(() => {
    if (id) {
      dispatch(dirty(id, isDirty()))
    }
  }, [id, model])

  const updateModel = useCallback(
    (name, value) => {
      setModel({
        ...model,
        [name]: value,
      })
    },
    [model],
  )

  const isOfIterest = useCallback((child) => {
    return child && has(child.props, 'name') && child.type !== ReadonlyField
  }, [])

  const getValueFromTarget = useCallback((target) => {
    let rawValue
    if (target.type === 'checkbox') {
      rawValue = target.checked
    } else if (!target.value || /^\s*$/.test(target.value)) {
      rawValue = ''
    } else if (target.type === 'number') {
      rawValue = +target.value
    } else {
      rawValue = target.value
    }

    return rawValue
  }, [])

  function extractModel({ children, entity, retain }) {
    let fields = retain || []

    React.Children.map(children, (child) => {
      if (isOfIterest(child)) {
        fields.push(child.props.name)
      }
    })

    const uniqFields = uniq(fields)

    let model = {}
    if (isEmpty(entity)) {
      uniqFields.forEach((field) => (model[field] = undefined))
      React.Children.map(children, (child) => {
        if (isOfIterest(child)) {
          model[child.props.name] = child.props.defaultValue
        }
      })
    } else {
      model = pick(entity, uniqFields)
    }

    return model
  }

  const resetForm = useCallback(() => {
    setModel(initModel)
  }, [initModel])

  // build children
  const childrenWithProps = React.Children.map(children, (child, i) => {
    if (child) {
      const { name, onChange, defaultValue } = child.props

      const childProps = {
        onChange: (e) => {
          const rawValue = getValueFromTarget(e.target)
          updateModel(name, rawValue)

          // call childs original onChange
          if (onChange) {
            onChange(e)
          }
        },
      }

      if (i === 0) {
        childProps['autoFocus'] = true
        if (props.formFocusFirst) {
          childProps.ref = (node) => {
            if (node) refFirstEl = node
          }
        }
      }

      if (isEmpty(defaultValue) && has(props.entity, name)) {
        childProps.defaultValue = props.entity[name] || ''
      }

      return React.cloneElement(child, childProps)
    }
  })

  const submitForm = useCallback(
    (e) => {
      e.preventDefault()
      if (isDirty() || submitClean) {
        props.onSubmit(model, id)
      }
    },
    [id, model, props.onSubmit],
  )

  return (
    <StyledForm
      id={'js-form-' + id}
      ref={ref}
      onSubmit={submitForm}
      onReset={resetForm}
    >
      {childrenWithProps}
      <input type="submit" value={props.submitText} />
      {props.onCancel ? (
        <input type="button" value="Cancel" onClick={props.onCancel} />
      ) : null}
      {props.onReset ? <input type="reset" value="Reset" /> : null}
    </StyledForm>
  )
}

Form.displayName = 'Form'

Form.propTypes = {
  children: PropTypes.node.isRequired,
  entity: PropTypes.object,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onCancel: PropTypes.func,
  retain: PropTypes.arrayOf(PropTypes.string.isRequired),
  submitClean: PropTypes.bool,
  formFocusFirst: PropTypes.bool,
  oneLine: PropTypes.bool,
  id: PropTypes.string,
}

const StyledForm = styled('form')({
  '& > input[type="reset"]': {
    marginTop: '15px',
  },
  '& > input[type="submit"]': {
    margin: '16px 8px 0 0',
  },
})
