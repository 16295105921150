import { styled } from '@mui/system'
import { colors } from 'components/foundation'
import React from 'react'
import * as PropTypes from 'prop-types'

type FloatingPanelProps = {
  $position: string
  children: React.ReactNode
  $background?: string
}

export default function FloatingPanel(props: FloatingPanelProps) {
  const { children } = props
  return <StyledRoot {...props}>{React.Children.toArray(children)}</StyledRoot>
}

FloatingPanel.propTypes = {
  $position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']).isRequired,
}

const FloatingPanelHeader = styled('h1')({
  color: colors.brand,
  fontSize: 'clamp(10px, calc((100vw + 100vh)/2 * 0.01), 10px)',
  maxFontSize: '10px',
  textAlign: 'center',
  margin: 0,
  marginBottom: '8px',
  padding: 0,
})

FloatingPanel.Header = FloatingPanelHeader

const StyledRoot = styled('div')<FloatingPanelProps>(({
  $position,
  $background,
}) => {
  const getPositionStyle = () => {
    const positions = {
      top: {
        top: '24px',
        left: '24px',
      },
      right: {
        top: 'calc(25% + 40px)',
        right: '24px',
      },
      left: {
        top: 'calc(25% + 40px)',
        left: 'calc(40% - 13px)',
      },
      bottom: {
        bottom: '15px',
        left: window.innerWidth < 800 ? 'calc(0.5%)' : 'calc(25% - 13px)',
      },
    }
    return positions[$position] || { bottom: '10px', left: 'calc(40% - 13px)' }
  }

  return {
    display: 'block',
    position: 'absolute',
    zIndex: 1,
    padding: '5px',
    borderRadius: '4px',
    ...getPositionStyle(),
    ...($background === 'white' && {
      background: 'white',
    }),
  }
})
