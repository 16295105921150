import { get, isNil } from 'lodash-es'
import * as c from 'common/c'
import { areaInfoClr } from 'actions/map'
import { requestYieldUnit } from 'common/misc'
import utils from '@farmtrx/utils'
import { t } from 'i18next'

export default function AreaInfoView() {
  const harvest = useSelector((state) => state.harvest.current)
  const crop = harvest?.crop
  const yieldUnit =
    useSelector((state) => state.account.singleton.prefs.units.yieldunit_in) ??
    'tonnes_ha'
  const requestYield = requestYieldUnit(yieldUnit)
  const dispatch = useDispatch()

  const data = useSelector((state) => get(state, 'map.areaInfoRes'))

  const [yieldBuPerAc, setYieldBuPerAc] = useState()

  useEffect(() => {
    if (data !== c.UNDEFINED) {
      let totalYlDbuAc = 0
      data?.features?.forEach((feature) => {
        const ylDbuAc = get(feature, `properties.${requestYield}`)
        totalYlDbuAc += ylDbuAc
      })
      if (yieldUnit == 'yldt_ha')
        totalYlDbuAc = utils.unitConvert.convertYieldToPref(
          totalYlDbuAc,
          yieldUnit,
          crop?.buPerTonne,
        )
      if (data?.features?.length >= 1) {
        setYieldBuPerAc((totalYlDbuAc / data.features.length).toFixed(2))
      }
    }

    return () => dispatch(areaInfoClr())
  }, [data, requestYield, yieldUnit])

  return (
    <>
      {isNil(yieldBuPerAc) ? (
        <div>{t('no_data')}</div>
      ) : (
        <div>
          {t('avg_yield')}: {yieldBuPerAc} {t(yieldUnit)}
        </div>
      )}
    </>
  )
}
